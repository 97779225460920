import { AfterViewInit, Component, OnInit, ViewChild } from "@angular/core";
import { FormControl, FormGroup } from "@angular/forms";
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from "@angular/material/legacy-dialog";
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { MatSort, Sort } from "@angular/material/sort";
import { MatLegacyTableDataSource as MatTableDataSource } from "@angular/material/legacy-table";
import { ConfirmDialogComponent } from "src/app/components/confirm-dialog/confirm-dialog.component";
import { ConfirmDialogActions, ConfirmDialogData } from "src/app/components/confirm-dialog/confirm-dialog.model";
import { QueryFilterList, RequestFilter } from "src/app/models/requestFilter.model";
import { Rol } from "src/app/models/rol.model";
import { CommonService } from "src/app/services/common.service";
import { environment } from "src/environments/environment";
import { FormRolComponent } from "./form-rol/form-rol.component";
import { RolsService } from "./rol.service";
import { TranslateService } from '@ngx-translate/core';
import { debounceTime, distinctUntilChanged } from "rxjs";

enum Columns {
  name = 'name',
  description = 'description',
  createdAt = 'createdAt',
  view = 'view'
}

@Component({
  selector: 'app-rol',
  templateUrl: './rol.component.html',
  styleUrls: ['./rol.component.css']
})
export class RolComponent implements OnInit, AfterViewInit {

  displayedColumns =  Object.keys(Columns);
  
  dataSource = new MatTableDataSource<Rol>();
  
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns.name+",desc";//idColumna + "," + direccion



  createdAtFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  nameFilter = new FormControl();
  descFilter = new FormControl();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormRolComponent>;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);


  constructor(public dialog: MatDialog, 
    public services:RolsService, 
    public commonService : CommonService,
    private translate: TranslateService ) {     
  }




  async lanzarLLamada(){

//mostramos solo las activas
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "status", "ACTIVE");   

    let value = await this.services.findService(this.rf,true);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Rol>(value.content);
      this.pageLength=value.totalElements;
    }
}


  ngOnInit(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    this.lanzarLLamada();

    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();    
    });

    this.descFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns.description;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();    
    });
   
    this.createdAtFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.createdAt;    
      console.log(idColumn+"->start="+value.start+"   end="+value.end);        
      this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
      this.lanzarLLamada(); 
    });
  }

  

  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLLamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirOrder(this.rf.filter , this.pageSize,this.sortId);     
    this.lanzarLLamada();
  }




   onNew(){
    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {//vacio al ser nuevo
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onView(row: any) {

    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {
        obj: row,
        edit: false
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormRolComponent, {
      width: '70%', panelClass: 'custom-modalbox',
      data: {
        obj: row,
        edit: true
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada()
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Rol",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Rol"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        console.log('CONFIRM recived from dialog window');
        await this.services.deleteService(row.id);
        this.lanzarLLamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



}






