import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-error404',
  templateUrl: './pages-error404.component.html',
  styleUrls: ['./pages-error404.component.css']
})
export class PagesError404Component implements OnInit {

  //constructor() {   }

  ngOnInit(): void {
  }


}
