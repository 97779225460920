import { Inject, Injectable,Injector } from '@angular/core';
import { Router } from '@angular/router';
import { OAuthErrorEvent, OAuthService } from 'angular-oauth2-oidc';
import { BehaviorSubject, combineLatest, Observable, of, Subject, Subscription } from 'rxjs';
import { delay, filter, map } from 'rxjs/operators';
import { RestService } from '../services/merchant/Rest.service';
import { authSpaConfig } from './auth-spa.config';

@Injectable({providedIn: 'root'})
export class AuthService {
    
  //userProfile: object | undefined;
  
  private isAuthenticatedSubject$ = new BehaviorSubject<boolean>(false);
  public isAuthenticated$ = this.isAuthenticatedSubject$.asObservable();
  
  private isDoneLoadingSubject$ = new BehaviorSubject<boolean>(false);
  public isDoneLoading$ = this.isDoneLoadingSubject$.asObservable();
  
  /**
   * Publishes `true` if and only if (a) all the asynchronous initial
   * login calls have completed or errorred, and (b) the user ended up
   * being authenticated.
   *
   * In essence, it combines:
   *
   * - the latest known state of whether the user is authorized
   * - whether the ajax calls for initial log in have all been done
   */
  public canActivateProtectedRoutes$: Observable<boolean> = combineLatest([
    this.isAuthenticated$,
    this.isDoneLoading$
  ]).pipe(map(values => values.every(b => b)));
  
  
  private navigateToLoginPage() {
    // TODO: Remember current URL
    console.debug('####################### redirect to login', );
    this.logout();
    
    this.router.navigateByUrl('/welcome');// /should-login
  }
  
  public configure() {
    this.oauthService.configure(authSpaConfig);
    this.oauthService.setupAutomaticSilentRefresh();
  }

  private get restService() {
    return this.injector.get(RestService);
  }

  constructor(
    private oauthService: OAuthService,
    @Inject(Injector) private readonly injector: Injector,
    private router: Router) {
      
      this.configure();

      // Useful for debugging:
      this.oauthService.events.subscribe(event => {
        if (event instanceof OAuthErrorEvent) {
          console.error('OAuthErrorEvent Object:', event);
        } else {
          console.warn('OAuthEvent Object:', event);
        }
      });



  
      // This is tricky, as it might cause race conditions (where access_token is set in another
      // tab before everything is said and done there.
      // TODO: Improve this setup. See: https://github.com/jeroenheijmans/sample-angular-oauth2-oidc-with-auth-guards/issues/2
      window.addEventListener('storage', (event) => {
        // The `key` is `null` if the event was caused by `.clear()`
        if (event.key !== 'access_token' && event.key !== null) {
          return;
        }
  
        console.warn('Noticed changes to access_token (most likely from another tab), updating isAuthenticated');
        this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
  
        if (!this.oauthService.hasValidAccessToken()) {
          this.navigateToLoginPage();
        }
      });
  
      this.oauthService.events
        .subscribe(_ => {
          this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
        });
      this.isAuthenticatedSubject$.next(this.oauthService.hasValidAccessToken());
  
      this.oauthService.events
        .pipe(filter(e => ['token_received'].includes(e.type)))
        .subscribe(e => this.oauthService.loadUserProfile());

      this.oauthService.events
        .pipe(filter(e => ['token_timeout'].includes(e.type)))
        .subscribe(e => {
          console.debug('token about to expire');
          alert('token about to expire')
        });
        
      this.oauthService.events
        .pipe(filter(e => ['session_terminated', 'session_error'].includes(e.type)))
        .subscribe(e => {alert('session timeout');this.navigateToLoginPage()});
  
      console.log("performing silent refresh");
      this.oauthService.setupAutomaticSilentRefresh();
      console.log("silent refresh done!!");
    }

  public runInitialLoginSequence(): Promise<void> {
    if (location.hash) {
      console.log('Encountered hash fragment, plotting as table...');
      console.table(location.hash.substr(1).split('&').map(kvp => kvp.split('=')));
    }

    // 0. LOAD CONFIG:
    // First we have to check to see how the IdServer is
    // currently configured:
    return this.oauthService.loadDiscoveryDocument()

      // For demo purposes, we pretend the previous call was very slow
      //.then(() => new Promise<void>(resolve => setTimeout(() => resolve(), 1500)))

      // 1. HASH LOGIN:
      // Try to log in via hash fragment after redirect back
      // from IdServer from initImplicitFlow:
      .then(() => this.oauthService.tryLogin())
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve().then((t)=>{
            console.log(t);
            let expirationAccessToken = this.oauthService.getAccessTokenExpiration();
            let timeout = expirationAccessToken - new Date().valueOf();
            console.log('Expiration token: ' + expirationAccessToken);
            console.log('Timeout: ' + timeout);
            this.expirationCounter(timeout);
          }); 
        }
        return Promise.resolve();
      })


      /*   Se comenta xq no esta funcionando y relentiza MUXISIMO la carga del welcome 21/11/2022
      .then(() => {
        if (this.oauthService.hasValidAccessToken()) {
          return Promise.resolve().then((t)=>{
            console.log(t);
            let expirationAccessToken = this.oauthService.getAccessTokenExpiration();
            let timeout = expirationAccessToken - new Date().valueOf();
            console.log('Expiration token: ' + expirationAccessToken);
            console.log('Timeout: ' + timeout);
            this.expirationCounter(timeout);
          });
        }

        // 2. SILENT LOGIN:
        // Try to log in via a refresh because then we can prevent
        // needing to redirect the user:
        return this.oauthService.silentRefresh()
          .then(() => Promise.resolve())
          .catch(result => {
            // Subset of situations from https://openid.net/specs/openid-connect-core-1_0.html#AuthError
            // Only the ones where it's reasonably sure that sending the
            // user to the IdServer will help.
            const errorResponsesRequiringUserInteraction = [
              'interaction_required',
              'login_required',
              'account_selection_required',
              'consent_required',
            ];

            if (result
              && result.reason
              && errorResponsesRequiringUserInteraction.indexOf(result.reason.error) >= 0) {

              // 3. ASK FOR LOGIN:
              // At this point we know for sure that we have to ask the
              // user to log in, so we redirect them to the IdServer to
              // enter credentials.
              //
              // Enable this to ALWAYS force a user to login.
              // this.login();
              //
              // Instead, we'll now do this:
              console.warn('User interaction is needed to log in, we will wait for the user to manually log in.');
              return Promise.resolve();
            }

            // We can't handle the truth, just pass on the problem to the
            // next handler.
            return Promise.reject(result);
          });
      })*/

      .then(() => {
        this.isDoneLoadingSubject$.next(true);

        // Check for the strings 'undefined' and 'null' just to be sure. Our current
        // login(...) should never have this, but in case someone ever calls
        // initImplicitFlow(undefined | null) this could happen.
        if (this.oauthService.state && this.oauthService.state !== 'undefined' && this.oauthService.state !== 'null') {
          let stateUrl = this.oauthService.state;
          if (stateUrl.startsWith('/') === false) {
            //stateUrl = decodeURIComponent(stateUrl);
            stateUrl = stateUrl.replace("9911", "\/");
          }
          console.log(`There was state of ${this.oauthService.state}, so we are sending you to: ${stateUrl}`);
          this.router.navigateByUrl(stateUrl);
        }
      })
      .catch(() => this.isDoneLoadingSubject$.next(true));
  }

  
  public login(targetUrl?: string) {
    let stateUrl = targetUrl || this.router.url;

    //angular-oauth-oidc is doing a double encode URI of the string passed in initLoginFlow
    //to avoid issues with the WAF, we try to use our own encoding URI with valids characters
    stateUrl = stateUrl.replace("\/", "9911");

    

    // Note: before version 9.1.0 of the library you needed to
    // call encodeURIComponent on the argument to the method.
    this.oauthService.initLoginFlow(stateUrl);//|| this.router.url
    //this.loginCode();
  }

  

  tokenSubscription = new Subscription()

  expirationCounter(timeout: number) {
    this.tokenSubscription.unsubscribe();
    this.tokenSubscription = of(null)
    .pipe(delay(timeout))
    .subscribe((expired) => {
      console.debug('logout on token timeout');
      console.log('EXPIRED!!');

      this.logoutAndClear();
      this.router.navigate(["/welcome"]);
    });
  }

  public clearStorage() { 
    //Keep the language
    let language = localStorage.getItem("language");
    localStorage.clear(); 
    if (language) {
      localStorage.setItem("language", language);
    }
  }
  public logout() { 
    this.tokenSubscription.unsubscribe();
    this.logoutAuthService().then(()=>console.debug("logged out from authserver"));
    this.oauthService.logOut(true);

  }
  public logoutAndClear() { 
    this.logout();
    this.clearStorage();
  }

  async logoutAuthService(_body ?: any){
    let value = await this.restService.commonRestCallAuth(_body, 'logout',true,true)   
      .catch((err: any) => {
        console.error(err);
        return null
      })

    return value;   
  }
  
  public refresh() { this.oauthService.silentRefresh(); }
  public hasValidToken() { return this.oauthService.hasValidAccessToken(); }
  

  

  public get getUserClaims() : object {
    
    return this.oauthService.getIdentityClaims();
  }
  
  public get domain() {
    return this.getUserClaims['domain' as keyof typeof this.getUserClaims] !== undefined ? this.getUserClaims['domain' as keyof typeof this.getUserClaims]:'unknown';
  }

  public get username() {
    return this.getUserClaims['preferred_username' as keyof typeof this.getUserClaims] !== undefined ? this.getUserClaims['preferred_username' as keyof typeof this.getUserClaims]:'unknown';
  }

  public get fullName() {
    const o = this.getUserClaims;
    return o['name' as keyof typeof o ] !== undefined ? o['name' as keyof typeof o]:'unknown';
  }


  // These normally won't be exposed from a service like this, but
  // for debugging it makes sense.
  public get accessToken() { return this.oauthService.getAccessToken(); }
  public get refreshToken() { return this.oauthService.getRefreshToken(); }
  public get identityClaims() { return this.oauthService.getIdentityClaims(); }
  public get idToken() { return this.oauthService.getIdToken(); }
  public get logoutUrl() { return this.oauthService.logoutUrl; }


/*
  public get isAuthenticated() : boolean {
    return this.isAuthenticated$.nex;

  }
*/
  public hasValidIdToken() : boolean {
    return this.oauthService.hasValidIdToken();
  }
  public hasValidAccessToken() : boolean {
    return this.oauthService.hasValidAccessToken();
  }

  private chars: string = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/=';
  private atob(input : string) {
      var str = String(input).replace(/=+$/, '');
      if (str.length % 4 == 1) {
          throw new Error("'atob' failed: The string to be decoded is not correctly encoded.");
      }
      for (
          // initialize result and counters
          var bc = 0, bs = 0, buffer, idx = 0, output = '';
          // get next character
          buffer = str.charAt(idx++);
          // character found in table? initialize bit storage and add its ascii value;
          ~buffer && (bs = bc % 4 ? bs * 64 + buffer : buffer,
              // and if not first of each 4 characters,
              // convert the first 8 bits to one ascii character
              bc++ % 4) ? output += String.fromCharCode(255 & bs >> (-2 * bc & 6)) : 0
      ) {
          // try to find character in table (0-63, not found => -1)
          buffer = this.chars.indexOf(buffer);
      }
      return output;
  };

  parseJwt(token: string) {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(this.atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
  }

  public getUserAccessToken() : any {
    var tk: string = this.oauthService.getAccessToken();
    return this.parseJwt(tk);
  }
  public getAccessToken() : any {
    return this.oauthService.getAccessToken();
  }






/*

  logoutAndRevoke() {
    // this.oauthService.logOut();
    this.oauthService.revokeTokenAndLogout();
  }
  
  async loginCode() {
    // Tweak config for code flow
    this.oauthService.configure(authSpaConfig);
    await this.oauthService.loadDiscoveryDocument();
    sessionStorage.setItem('flow', 'code');

    this.oauthService.initLoginFlow();//'/some-state;p1=1;p2=2?p3=3&p4=4');

    // the parameter here is optional. It's passed around and can be used after logging in
  }

  async loginCodeInPopup() {
    // Tweak config for code flow
    this.oauthService.configure(authSpaConfig);
    await this.oauthService.loadDiscoveryDocument();
    sessionStorage.setItem('flow', 'code');

    this.oauthService.initLoginFlowInPopup().then(() => {
      console.log("this.loadUserProfile()");
      this.loadUserProfile();
    });
  }
*/
/*
  loadUserProfile(): void {
    this.oauthService.loadUserProfile().then((up) => (
      this.userProfile = up
      
    )).catch((err) => ( 
      console.log(err)
    ));
    
  }
*/



jerarquiaSearchClicked = new Subject();
getJerarquiaSearchClicked(){
    return this.jerarquiaSearchClicked.asObservable();
}




}
