import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';
import { Signup } from 'src/app/models/signup.model';
import { Users } from 'src/app/models/users.model';
import { PagesRegisterService } from './pages-register.service';

@Component({
  selector: 'app-pages-register',
  templateUrl: './pages-register.component.html',
  styleUrls: ['./pages-register.component.css']
})
export class PagesRegisterComponent implements OnInit { 
  submitted = false;
  registerForm!: FormGroup;

  orderObj:Signup = new Signup();

  constructor(private route: ActivatedRoute,
    private router: Router,
    private services: PagesRegisterService,
    private authService: AuthService,
    private formBuilder: FormBuilder) { 
    console.log("register page")
   

    this.registerForm = this.formBuilder.group({
      acceptedTermsAndConditions:[false, Validators.compose([Validators.required])],
      yourName: [null, Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])]      ,
      yourLastName: [null, Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])]      ,
      yourEmail: [null, Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")])]      ,
      yourUsername: [null, Validators.compose([Validators.required, 
        Validators.minLength(6),
        Validators.maxLength(255),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      yourPassword:[null, Validators.compose([Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))
        //Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')
        ])],
    });
    //newpass: ["", Validators.compose([Validators.required, Validators.pattern('(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[$@$!%*?&])[A-Za-z\d$@$!%*?&].{8,}')])],
  }

  ngOnInit(): void {
    this.route.queryParams.subscribe(async params => {

        this.orderObj = JSON.parse(JSON.stringify(params));
        console.log(this.orderObj.token); // { token: 'AABBDDDasdfasdasdasdasdasdasdasdasdasdasd', user: 'asdasdasdasdasd' }
  
        let userBBDD: Users;
        let result = await this.services.getInfo(this.orderObj)


        if(result){
          userBBDD =new Users(result)

          this.registerForm = this.formBuilder.group({
            acceptedTermsAndConditions:[false, Validators.compose([Validators.required])],
            yourName: [userBBDD?.firstName, Validators.compose([Validators.required, 
              Validators.maxLength(255),
              Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])]      ,
            yourLastName: [userBBDD?.lastName, Validators.compose([Validators.required, 
              Validators.maxLength(255),
              Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])]      ,
            yourEmail: [userBBDD?.email, Validators.compose([Validators.required, 
              Validators.email, 
              Validators.maxLength(255),
              Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")])]      ,
            yourUsername: [this.orderObj.username, Validators.compose([Validators.required, 
              Validators.minLength(6),
              Validators.maxLength(255),
              Validators.pattern("^(?=[a-zA-Z0-9._-]{6,255}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
            yourPassword:[null, Validators.compose([Validators.required,
              Validators.minLength(8),
              Validators.maxLength(20),
              Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))
              ])],
          });
      }
    });    
  }


  onFormSubmit(): void {
    this.submitted = true;
      console.log(this.registerForm);
    
      if (this.registerForm.invalid) {
        // TODO:
        console.log('Errors')
        return;
      }    

      let userNew:Users = new Users();


      const data = this.applyFormats(this.registerForm);
      userNew.firstName=data['yourName'];
      userNew.lastName=data['yourLastName'];
      userNew.username=data['yourUsername'];
      userNew.email=data['yourEmail'];
      userNew.password=data['yourPassword'];
      userNew.token=this.orderObj.token;
      userNew.acceptedTermsAndConditions=data['acceptedTermsAndConditions'];

      this.services.save(userNew).then(()=>{
        //this.authService.login("/dashboard");
        //this.router.navigateByUrl('/dashboard');
        this.router.navigateByUrl('/welcome');
      });
  }

  get f() {
    return this.registerForm.controls;
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
