import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { Organization, OrganizationTemplate } from '../models/organization.model';
import { ENTORNO, TypeJerarquia } from '../util/constants';
import { QueryFilterList, RequestFilter } from '../models/requestFilter.model';
import { MerchantService } from '../pages/views/merchants/merchant.service';
import { RegionService } from '../pages/views/region/region.service';
import { DivisionService } from '../pages/views/division/division.service';
import { Region } from '../models/region.model';
import { Division } from '../models/division.model';
import { Merchants } from '../models/merchants.model';
import { VIWER_ENTORNO } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';

@Injectable({
  providedIn: 'root',
})
export class UtilService {
  
  entorno = VIWER_ENTORNO;

  constructor(
      private merchantService: MerchantService,
      private regionService: RegionService,
      private divisionService: DivisionService,
      private translateService: TranslateService,
      private orgService: OrganizationsService) {}


  public checkJerarquia(templace:OrganizationTemplate | undefined, nivel:TypeJerarquia): boolean  {
    if (templace && nivel) {     
        let permiso = false;

        if(nivel == TypeJerarquia.REGION && templace.hasRegions) {     
            permiso = templace.hasRegions;
        }else if(nivel == TypeJerarquia.DIVISION && templace.hasDelegations) {     
            permiso = templace.hasDelegations;
        }else if(nivel == TypeJerarquia.SUBSIDIARY && templace.hasSubsidiary) {     
            permiso = templace.hasSubsidiary;
        }     
        return permiso;      
    } 
      
    return false;

  }



  async pedirDivision(domain:String | undefined){

    let mapQuery = new Map();
    let filter = QueryFilterList.construirFilter(mapQuery, "type", "DIVISION");     
    if(domain){
      filter =  QueryFilterList.construirFilter(mapQuery, "domain", domain+".");
    }
    //region
    let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
    return await this.divisionService.find(rfRegi);
}

async pedirRegiones(domain:String | undefined){

  let mapQuery = new Map();
  let filter =  QueryFilterList.construirFilter(mapQuery, "type", "REGION");
  if(domain){
    filter =  QueryFilterList.construirFilter(mapQuery, "domain", domain+".");
  }
  //region
  let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
  return await this.regionService.find(rfRegi); 
}

async pedirMerchant(domain:String | undefined){

    let mapQuery = new Map();
    let filter = QueryFilterList.construirFilter(mapQuery, "", "");
    if(domain){
      filter =  QueryFilterList.construirFilter(mapQuery, "domain", domain+".");
    }
    if(this.entorno==ENTORNO.XIIBERO){
      filter = QueryFilterList.construirFilter(mapQuery, "type", "MERCHANT"); 
    }

    //region
    let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
    return await this.merchantService.find(rfRegi); 
}



cargarCacheDomain() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  let precargaReg = localStorage.getItem('REGION');  
  let precargaDiv= localStorage.getItem('DIVISION');
  let precargaMec = localStorage.getItem('MERCHANT');
  
  let value:string | undefined
  if(precargaMec && precargaMec!="undefined" ){
    let obj:Merchants
    obj= JSON.parse(precargaMec); 
    if(obj){
      value=obj.domain+";"
    }
  }else if(precargaDiv && precargaDiv!="undefined"){
    let obj:Division
    obj= JSON.parse(precargaDiv); 
    if(obj){
      value=obj.domain+"."
    }
  }else if(precargaReg && precargaReg!="undefined"){
    let obj:Region
    obj= JSON.parse(precargaReg); 
    if(obj){
      value=obj.domain+"."
    }
  }else if(precargaOrg && precargaOrg!="undefined"){
    let obj:Organization
    obj= JSON.parse(precargaOrg); 
    if(obj){
      value=obj.domainRoot+"."
    }
  }

 return value;
}



async cargarCacheOrganization() {
      
  let precargaOrg = localStorage.getItem('ORGANIZATION'); 
  if (!precargaOrg) {
    //Check the user has more than 1 organization selectable:
    let rfOrg: RequestFilter = new RequestFilter(null, 0, 2, "");
    let srOrg = await this.orgService.find(rfOrg,true);
    if (srOrg && srOrg.content && srOrg.content.length==1) {      
        // Si solo hay 1 ponemos por defecto valor
        precargaOrg = srOrg.content[0];        
        localStorage.setItem('ORGANIZATION', JSON.stringify(srOrg.content[0]));      
    } 

  }

 return precargaOrg;
}


public translate(text : string) : string {
  if(text && ''!==text){
    return this.translateService.instant(text);
  }
  return text;
}

}