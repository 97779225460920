import { Injectable } from '@angular/core';
import { Division } from 'src/app/models/division.model';
import { Merchants } from 'src/app/models/merchants.model';
import { RequestFilter } from 'src/app/models/requestFilter.model';
import { Subsidiary } from 'src/app/models/subsidiary.model';
import { MerchantInfo } from 'src/app/models/transaction.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO, MerchantRestUrl } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class SubsidiaryService {
  constructor(private merchantRest : RestService) {}

  entorno = VIWER_ENTORNO;

  async find(_body: RequestFilter){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'merchant/',true,true)   
      .catch(err => {
        return null
      })
      }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'merchant/query',true,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

   

  async saveEditService(_body: Subsidiary){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/save',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
  
  async updateEditService(_body: Subsidiary) {
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/update',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'merchant/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

 


}