<main id="main" class="main">
    <div class="pagetitle">
       <h1 i18n>{{"Profile" | translate }}</h1>
    </div>
    <section class="section profile">
       <div class="row">
          <div class="col-xl-4">
             <div class="card">
                <div class="card-body profile-card pt-4 d-flex flex-column align-items-center">
                   <span class="profilelogo big"> </span>
                   <!--<img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle">-->

                   <h2>{{userProfileClaim('name')}}</h2>
                   <!--<h3>Web Designer</h3>-->
                   
                </div>
             </div>
          </div>
          <div class="col-xl-8">
             <div class="card">
                <div class="card-body pt-3">
                   <ul class="nav nav-tabs nav-tabs-bordered">
                      <li class="nav-item"> <button class="nav-link active" data-bs-toggle="tab" data-bs-target="#profile-overview" i18n>{{"Overview" | translate }}</button></li>
                      <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-edit" i18n>{{"Edit Profile" | translate }}</button></li>
                      <!--<li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-settings" i18n>Settings</button></li>-->
                      <li class="nav-item"> <button class="nav-link" data-bs-toggle="tab" data-bs-target="#profile-change-password" i18n>{{"Change Password" | translate }}</button></li>
                   </ul>
                   <div class="tab-content pt-2">
                      <div class="tab-pane fade show active profile-overview" id="profile-overview">
                         
                         <div class="row">
                           <div class="col-lg-3 col-md-4 label " i18n>{{"Username" | translate }}</div>
                           <div class="col-lg-9 col-md-8">{{userProfileClaim('preferred_username')}}</div>
                         </div>
                         <div class="row">
                            <div class="col-lg-3 col-md-4 label " i18n>{{"First Name" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('name')}}</div>
                         </div>
                         <div class="row">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Email" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('email')}}</div>
                         </div>
                         <div class="row ">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Organization" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('company')}}</div>
                         </div>
                         <div class="row ">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Job" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('jobTitle')}}</div>
                         </div>
                         <div class="row ">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Country" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{pais}}</div>
                         </div>
                         <div class="row ">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Address" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('address')}}</div>
                         </div>
                         <div class="row ">
                            <div class="col-lg-3 col-md-4 label" i18n>{{"Phone" | translate }}</div>
                            <div class="col-lg-9 col-md-8">{{userProfileClaim('phone_number')}}</div>
                         </div>
                      </div>
                      <div class="tab-pane fade profile-edit pt-3" id="profile-edit">
                         <form [formGroup]="registerForm">
                            <!--<div class="row mb-3">
                               <label for="profileImage" class="col-md-4 col-lg-3 col-form-label" i18n>Profile Image</label>
                               <div class="col-md-8 col-lg-9">
                                  <img src="assets/img/profile.png" alt="Profile">
                                  <div class="pt-2"> <a href="#" class="btn btn-primary btn-sm" title="Upload new profile image"><i class="bi bi-upload"></i></a> <a href="#" class="btn btn-danger btn-sm" title="Remove my profile image"><i class="bi bi-trash"></i></a></div>
                               </div>
                            </div>-->

                            <div class="row mb-3">
                              <label for="Username" class="col-md-4 col-lg-3 col-form-label" i18n>{{"User Name" | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="username" type="text" readonly class="form-control" id="username" value="{{userProfileClaim('preferred_username')}}"></div>
                           </div>
                            <div class="row mb-3">
                               <label for="firstname" class="col-md-4 col-lg-3 col-form-label" i18n>{{"First Name" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> <input name="firstname" type="text" class="form-control" formControlName="firstname" id="firstname" ></div>
                            </div>
                            <div class="row mb-3">
                              <label for="lastname" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Last Name" | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="lastname" type="text" class="form-control" formControlName="lastname" id="lastname" ></div>
                           </div>
                           <div class="row mb-3">
                              <label for="language" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Language" | translate }}</label>
                              <div class="col-md-8 col-lg-9"> 
                                 <select class="form-control" formControlName="language">                                    
                                    <option *ngFor="let lang of transLang" [value]="lang.value">{{ lang.name }}</option>
                                 </select>
                              </div>
                           </div>
                           <div class="row mb-3">
                              <label for="timezone" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Timezone" | translate }}</label>
                              <div class="col-md-8 col-lg-9"> 
                                 <select class="form-control" formControlName="timezone">
                                    <option *ngFor="let zone of zones" [value]="zone">{{ zone }}</option>
                                 </select>
                              </div>
                           </div>

                           <div class="row mb-3">
                              <label for="Email" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Email" | translate }}</label>
                              <div class="col-md-8 col-lg-9"> <input name="email" type="email" class="form-control" id="Email" formControlName="email"></div>
                           </div>
                            <div class="row mb-3">
                               <label for="company" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Organization" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> <input name="company" type="text" class="form-control" id="company" formControlName="company" value="Therichpost"></div>
                            </div>
                            <div class="row mb-3">
                               <label for="Job" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Job" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> <input name="job" type="text" class="form-control" id="Job" formControlName="job" value="Web Designer"></div>
                            </div>


                            <div class="row mb-3">
                               <label for="Country" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Country" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> 
                                 <select class="form-control" formControlName="country">
                                    <option  *ngFor="let op of listaPaises" [value]="op.code">
                                        {{op.name}}
                                    </option >
                                </select>

                                 <!--<input name="country" type="text" class="form-control" id="Country" formControlName="country" value="USA">-->
                              
                              </div>
                            </div>


                            <div class="row mb-3">
                               <label for="Address" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Address" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> <input name="address" type="text" class="form-control" id="Address" formControlName="address" value="Ludhiana, Punjab, India"></div>
                            </div>
                            <div class="row mb-3">
                               <label for="Phone" class="col-md-4 col-lg-3 col-form-label" i18n>{{"Phone" | translate }}</label>
                               <div class="col-md-8 col-lg-9"> <input name="phone" type="text" class="form-control" id="Phone" formControlName="phone" value="(436) 486-3538 x29071"></div>
                            </div>
                          
                          
                            <div class="text-center"> 
                              <button type="btn btn-aceptar" class="btn btn-primary" (click)="onFormSubmit()" i18n>{{"Save" | translate }}</button>
                           </div>
                         </form>
                      </div>
                      <!--<div class="tab-pane fade pt-3" id="profile-settings">
                         <form>
                            <div class="row mb-3">
                               <label for="fullName" class="col-md-4 col-lg-3 col-form-label">{{"Email Notifications" | translate }}</label>
                               <div class="col-md-8 col-lg-9">
                                  <div class="form-check"> <input class="form-check-input" type="checkbox" id="changesMade" checked> <label class="form-check-label" for="changesMade"> {{"Changes made to your account" | translate }} </label></div>
                                  <div class="form-check"> <input class="form-check-input" type="checkbox" id="newProducts" checked> <label class="form-check-label" for="newProducts"> {{"Information on new products and services" | translate }} </label></div>
                                  <div class="form-check"> <input class="form-check-input" type="checkbox" id="proOffers"> <label class="form-check-label" for="proOffers"> {{"Marketing and promo offers" | translate }} </label></div>
                                  <div class="form-check"> <input class="form-check-input" type="checkbox" id="securityNotify" checked disabled> <label class="form-check-label" for="securityNotify"> {{"Security alerts" | translate }} </label></div>
                               </div>
                            </div>
                            <div class="text-center"> <button type="submit" class="btn btn-primary">Save Changes</button></div>
                         </form>
                      </div>-->
                      <div class="tab-pane fade pt-3" id="profile-change-password">
                       
                        <form id="change-password" class="needs-validation" novalidate [formGroup]="registerFormPass">
                         
                           <div class="row mb-3" ngDefaultControl>
                                 <h5  class="col-md-4 col-lg-3 col-form-label"  i18n>{{"Old password" | translate }} <span class="obligatorio">*</span></h5>
                                 <div class="col-md-8 col-lg-9"> 
                                    <input formControlName="oldpass" type="password"  class="form-control" required maxlength="40">
                                 <mat-error *ngIf="registerFormPass.get('oldpass')?.hasError('required')"  i18n>{{"Please enter your password!" | translate }}</mat-error>
                                 </div>                           
                            </div>
                            <div class="row mb-3" ngDefaultControl>
                                 <h5  class="col-md-4 col-lg-3 col-form-label"  i18n>{{"New password" | translate }} <span class="obligatorio">*</span></h5>
                                 <div class="col-md-8 col-lg-9"> 
                                    <input formControlName="newpass" type="password"  class="form-control" maxlength="40" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                                    <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('required')"  i18n>{{"Please enter your password!" | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('pattern')"  i18n>{{"Password should have at least 8 characters : one lowercase, one uppercase , special characters and one digit." | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('minlength')"  i18n>{{"Minimum length not reached" | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('newpass')?.hasError('maxlength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                                 </div>                           
                           </div>
                           <div class="row mb-3" ngDefaultControl>
                                 <h5  class="col-md-4 col-lg-3 col-form-label"  i18n>{{"Confirm new password" | translate }} <span class="obligatorio">*</span></h5>
                                 <div class="col-md-8 col-lg-9"> 
                                    <input formControlName="repeatpass" type="password"  class="form-control" maxlength="40" title="Must contain at least one number and one uppercase and lowercase letter, and at least 8 or more characters" required>
                                    <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('required')"  i18n>{{"Please enter your password!" | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('pattern')"  i18n>{{"Password should have at least 8 characters : one lowercase, one uppercase , special characters and one digit." | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('minlength')"  i18n>{{"Minimum length not reached" | translate }}</mat-error>
                                    <mat-error *ngIf="registerFormPass.get('repeatpass')?.hasError('maxlength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                                    <mat-error *ngIf="passNocoincide"  i18n>{{"Passwords do not match" | translate }}</mat-error>
                                 </div>                           
                           </div>
                           
                            <div class="text-center"> <button class="btn btn-primary" type="submit" (click)="onFormChangePass()" i18n>{{"Change Password" | translate }}</button></div>
                         </form>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </div>
    </section>
 </main>
