<header id="header" class="header fixed-top d-flex align-items-center">
    <div class="d-flex align-items-center justify-content-between">
        <div class="d-flex align-items-center justify-content-between">
            <a routerLink="/welcome" class="logo d-flex align-items-center">
               <span class="d-none d-lg-block">merchant<b>Portal</b></span> 
               <span class="img d-none"></span> 
            </a> 
         </div>
    </div>
    <nav class="header-nav ms-auto">
      <ul class="d-flex align-items-center nav-pills">
         <li class="nav-item dropdown d-none"> 
            <!--a class="nav-link nav-icon" href="#" data-bs-toggle="dropdown"> <i class="bi bi-chat-left-text"></i> <span class="badge bg-success badge-number">3</span> </a-->
            <a class="nav-link nav-icon" routerLinkActive="active-link" routerLink="signup" i18n>{{"SignUp" | translate }}</a>
         </li>
         <li class="nav-item dropdown"> 
            <!--a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown"> <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle"> <span class="d-none d-md-block dropdown-toggle ps-2">Jassa</span> </a-->
            <a class="nav-link nav-icon active" routerLinkActive="active-link" routerLink="#" (click)="login()" i18n>{{"Login" | translate }}</a>
         </li>
      </ul>
    </nav>
 </header>
