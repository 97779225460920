import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { DivisionService } from '../division.service';
import { Division, DivisionAddress, DivisionInfo, DivisionProcessors } from 'src/app/models/division.model';
import { Processor } from 'src/app/models/processor.model';
import { ProcessorService } from '../../processors/processor.service';
import { Region } from 'src/app/models/region.model';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { RegionService } from '../../region/region.service';
import { CommonService } from 'src/app/services/common.service';
import { UtilService } from 'src/app/services/util.service';

@Component({
  selector: 'app-form-division',
  templateUrl: './form-division.component.html',
  styleUrls: ['./form-division.component.css']
})
export class FormDivisionComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado:boolean = false;  
  verRegion:boolean = false;  

  listaOrg:Organization[] = [];
  listaRegion:Region[] = [];
  
  selectedProc!:String
  selectedOrg:Organization = new Organization();
  selectedRegion:Region = new Region();

  objBBDD:Division = new Division();

  verProcessCBS=false;

  listProcesador: Processor[] = []


  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: DivisionService,
    private orgService: OrganizationsService,
    private regionService: RegionService,
    private commonService: UtilService,
    public dialogRef: MatDialogRef<FormDivisionComponent>,
    public dialog: MatDialog) { 
     
  }


  async cargar(){   
    //organizaciones que permiten la creacion de DIVISION     
     let mapQuery = new Map();
     let filter = QueryFilterList.construirFilterComplet(mapQuery,"beq" ,"template.hasDelegations", "true");
  
     
      let rfOrg: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
      let srOrg = await this.orgService.find(rfOrg,true);     
      if(srOrg){
        this.listaOrg= srOrg.content;
        if(this.listaOrg.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.selectedOrg = this.listaOrg[0];
          this.procesarOrganizacion(this.selectedOrg)  
        }
      }

  }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({      
      name: ["", Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      shortname: ["", Validators.compose([
          Validators.maxLength(5),
          Validators.pattern("^[a-zA-Z0-9]*$")
        ])],   
      orgMarcado:[null, Validators.compose([Validators.required])],  
      regionMarcado:[null],     
      phone:[""],
      email: ["", Validators.compose([Validators.required, 
        Validators.email, 
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]  
    });


   await this.cargar();

    let nombre, email,phone,shortname
    let orgBBDD=null;
    let regionBBDD=null;
    if(this.data && this.data.obj){
      //si entra es EDICION
      this.desactivado=true;
      
      this.objBBDD = this.data.obj;


     // alert(JSON.stringify(this.objBBDD))
      if(this.objBBDD){
        let hierarchy = this.objBBDD.hierarchy

        let dominioOrg = ""
        let dominioRegion=""


        if(hierarchy){
            if(hierarchy.ORGANIZATION){
              dominioOrg = hierarchy.ORGANIZATION.domain
            }

            if(hierarchy.REGION){
              dominioRegion = hierarchy.REGION.domain

              let sr = await this.commonService.pedirRegiones(dominioOrg);     
              if(sr){
                this.listaRegion= sr.content;
              }
            }
         
        }
    
       
     

        this.listaOrg.forEach(element => {
          if (element.domainRoot == dominioOrg) {
            orgBBDD = element
          };
        });

        this.listaRegion.forEach(element => {
          if(element.domain==dominioRegion){
            this.verRegion=true              
              regionBBDD=element  
            };
        });

      
      

      if(this.objBBDD.info){
        email=this.objBBDD.info.email;
        phone=this.objBBDD.info.phone;
      }
      nombre=this.objBBDD.name;
      shortname=this.objBBDD.merchantId;
       }// if (this.objBBDD) {
    }//if (this.data && this.data.obj) {

    
    this.registerForm = this.formBuilder.group({       
        name: [nombre, Validators.compose([Validators.required])],    
        shortname  : [{value: shortname, disabled: true}],
        email: [email],
        phone: [phone],
        regionMarcado: [regionBBDD],
        orgMarcado:[orgBBDD, Validators.compose([Validators.required])],
     });
  }



  async procesarOrganizacion(org:Organization){
    let mostrar=false
    
    if(org.template){
      if(this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)){
          mostrar=true

          let value:string | undefined          
          const idColumn = "domain";
          if(org){
            value=org.domainRoot+"."
          }
            
          let mapQuery = new Map();
          let filter = QueryFilterList.construirFilter(mapQuery, idColumn, value);
          filter = QueryFilterList.construirFilter(mapQuery, "type", "REGION");     
        
          //region
          let rfRegi: RequestFilter= new RequestFilter(filter,0, 100,"name,desc");       
          let srRegi = await this.regionService.find(rfRegi);     
          if(srRegi){
            this.listaRegion= srRegi.content;
          }
      }
    }

    this.verRegion=mostrar
  }

  async organizationFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 

    this.procesarOrganizacion(org)   
  }




  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);



    let objNew : Division = new Division();
    if(this.objBBDD.id){
      objNew=this.objBBDD
    }

    objNew.name=data['name']  
    objNew.merchantId=data['shortname'] 
    
    let orgNew:Organization
    orgNew=data['orgMarcado']

    let regionNew:Region
    regionNew=data['regionMarcado']


    let domainRoot= orgNew.domainRoot
    if(regionNew){
      domainRoot=regionNew.domain
    }

    objNew.domain=domainRoot
    
    let tempEmail=data['email']  
    let tempphonP=data['phone']  
    

      
    let properties: DivisionInfo = new DivisionInfo(); 
    if(tempEmail || tempphonP){
      properties.email=tempEmail;
      properties.phone=tempphonP;
    }
       

    objNew.info=properties;
    objNew.type="DIVISION";

//alert("NUEVO="+JSON.stringify(objNew))
//alert("BBDD="+JSON.stringify(this.objBBDD))


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Create Division`;
    dialogData.textI18n = $localize `Are you sure you want to create it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });
    

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
        let val:any;
        if (objNew.id) {
          val = await this.services.updateEditService(objNew)
        } else {
          val = await this.services.saveEditService(objNew)
        }

        if(val){
          this.dialogRef.close(FormCreateActions.SAVED);
        }

      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




