import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder,  FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Groups } from 'src/app/models/groups.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Rol } from 'src/app/models/rol.model';
import { Users } from 'src/app/models/users.model';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions } from 'src/app/util/constants';
import { RolsService } from '../../rol/rol.service';
import { UsersService } from '../../users/users.service';
import { GroupsService } from '../groups.service';
import { NgxSpinnerService } from 'ngx-spinner';
import { TranslateService } from '@ngx-translate/core';
import { DualListComponent } from 'angular-dual-listbox';

@Component({
  selector: 'app-form-groups',
  templateUrl: './form-groups.component.html',
  styleUrls: ['./form-groups.component.css']
})
export class FormGroupsComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado:boolean = false;
  

  listaRol:Rol[] = [];
  listaOrg:Organization[] = [];
  listaMerchant:Merchants[] = [];
	listaTotalUser:Users[] = [];

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: GroupsService,
    private rolsService: RolsService,
    private translateService: TranslateService,
    private spinnerService: NgxSpinnerService,
    private usersService: UsersService,
    private orgService: OrganizationsService,
    private merchService: MerchantService,
    public dialogRef: MatDialogRef<FormGroupsComponent>,
    public dialog: MatDialog) { 
     
  }


  async cargar(){

    
    this.spinnerService.show();

    //roles
      let rfRol: RequestFilter= new RequestFilter(null,0, 100,"name,desc");
      let mapQuery = new Map();
      rfRol.filter = QueryFilterList.construirFilter(mapQuery, "status", "ACTIVE");   
      let srRoles = await this.rolsService.findService(rfRol,false);
      if(srRoles){
        //console.log(JSON.stringify(srRoles.content))
        this.listaRol= srRoles.content;
      }

    //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srOrg = await this.orgService.find(rfOrg,false);     
      if(srOrg){
        this.listaOrg= srOrg.content;
      }


      if(this.data && this.data.obj){
          //usuarios
          let rfUser: RequestFilter= new RequestFilter(null,0, 100,"name,desc");   
          mapQuery = new Map();
          rfUser.filter = QueryFilterList.construirFilter(mapQuery, "auth.groups", this.data.obj.name);       
          let srUser = await this.usersService.findService(rfUser);     
          if(srUser){
            this.listaTotalUser= srUser.content;
          }

          //merchant
          if(this.data.obj.domains){

            let dominio = this.data.obj.domains[0]
            let dominioOrg=dominio

            if(dominio.includes(".")){
              dominioOrg= dominio.substring(0, dominio.indexOf("."));
            }

            let rfMerc: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
            mapQuery = new Map();
            rfMerc.filter = QueryFilterList.construirFilter(mapQuery, "domain", dominioOrg);            
            let srOrg = await this.merchService.find(rfMerc);     

            this.listaMerchant= srOrg?.content   
          }

      }

      
      this.spinnerService.hide();

    }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({
      id:[""],
      name: ["", Validators.compose([Validators.required,
        Validators.minLength(4),
        Validators.maxLength(50),
        Validators.pattern("^(?=[a-zA-Z0-9._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      description: ["",Validators.compose([
        Validators.maxLength(255), 
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      rolMarcado:[null, Validators.compose([Validators.required])],
      orgMarcado:[null, Validators.compose([Validators.required])],
      merchMarcado:[null],
    });


   await this.cargar();

    let nombre, id , description;
    let rolBBDD=null;
    let orgBBDD=null;
    let merchBBDD=null;
    if(this.data && this.data.obj){
      //si entra es EDICION
      this.desactivado=true;
      
      let groupBBDD:Groups
      groupBBDD = this.data.obj;

      if(groupBBDD.roles && this.listaRol){
        let rol = groupBBDD.roles[0]
        this.listaRol.forEach(element => {
              if(element.name==rol){
                rolBBDD=element
              };
          });
      }

      if(groupBBDD.domains){
        let dominio = groupBBDD.domains[0]

        let dominioOrg=""
        let dominioMerch=""
        if(dominio.includes(".")){
          dominioOrg= dominio.substring(0, dominio.indexOf("."));
          dominioMerch= dominio;
        }else{
          dominioOrg=dominio
        }

        this.listaOrg.forEach(element => {
              if(element.domainRoot==dominioOrg){
                orgBBDD=element                
              };
          });


          this.listaMerchant.forEach(element => {
            if(element.domain==dominioMerch){
              merchBBDD=element
            };
        });
      }

      id= groupBBDD.id
      nombre=groupBBDD.name;
      description=groupBBDD.description;
    }
    
    this.registerForm = this.formBuilder.group({
      id:[id],
      name: [nombre, Validators.compose([Validators.required])]      ,
      description: [description],
      rolMarcado:[rolBBDD, Validators.compose([Validators.required])]      ,
      orgMarcado:[orgBBDD, Validators.compose([Validators.required])]      ,
      merchMarcado:[merchBBDD]      ,

     });


  }


  async selectOrg(orgSelect:Organization){
    //merchant
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let mapQuery = new Map();
      rfOrg.filter = QueryFilterList.construirFilter(mapQuery, "domain", orgSelect.domainRoot);            
      let srOrg = await this.merchService.find(rfOrg);     
     
      this.listaMerchant= srOrg?.content        
      
  }



  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let groupsNew : Groups = new Groups();

    groupsNew.name=data['name']  
    groupsNew.name=data['name']  
    groupsNew.description=data['description']  
    
    let rolNew:Rol
    let orgNew:Organization
    let merchNew:Merchants

    let rolArray=[]
    let orgArray=[]
    let merchArray=[]

    rolNew=data['rolMarcado']
    orgNew=data['orgMarcado']
    merchNew=data['merchMarcado']

    rolArray[0]=rolNew.name
    orgArray[0]=orgNew.domainRoot
    merchArray[0]=merchNew?.domain

    groupsNew.roles=rolArray 
    if(merchArray[0]){
      groupsNew.domains=merchArray
    }else{
      groupsNew.domains=orgArray
    }

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Create Group`;
    dialogData.textI18n = $localize `Are you sure you want to create it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          this.services.saveEditService(groupsNew)
          this.dialogRef.close(FormCreateActions.SAVED);

      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




