<main id="main" class="main">

<div class="pagetitle">
    <h1  i18n>Mail Settings</h1>      
 </div>
 <section class="section">
    <form [formGroup]="registerForm">


     <div class="row">
         <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>Server Name <span class="obligatorio">*</span></h5>
                    <input formControlName="host" matInput type="text" matInput required >
                    <mat-error *ngIf="registerForm.get('host')?.hasError('required')"  i18n>{{"required" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('host')?.hasError('pattern')"  i18n>{{"pattern" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('host')?.hasError('maxLength')"  i18n>{{"maxLength" | translate }}</mat-error>
                </mat-form-field>
             </div>
        </div>
        <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>Mail Sender <span class="obligatorio">*</span></h5>
                     <input formControlName="from" matInput type="text"  matInput required >
                     <mat-error *ngIf="registerForm.get('from')?.hasError('required')"  i18n>{{"Please enter an from!" | translate }}</mat-error>
                     <mat-error *ngIf="registerForm.get('from')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                     <mat-error *ngIf="registerForm.get('from')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                     <mat-error *ngIf="registerForm.get('from')?.hasError('email')"  i18n>{{"email format error" | translate }}</mat-error>
                    </mat-form-field>
                </div>
            </div>
        </div>
 <div class="row">
        <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>UserName<span class="obligatorio">*</span></h5>
                    <input formControlName="userName" matInput type="text"  matInput required >
                    <mat-error *ngIf="registerForm.get('userName')?.hasError('required')"  i18n>{{"required" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('userName')?.hasError('pattern')"  i18n>{{"pattern" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('userName')?.hasError('maxLength')"  i18n>{{"maxLength" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('userName')?.hasError('email')"  i18n>{{"email format error" | translate }}</mat-error>
                </mat-form-field>
            </div>
         </div>
        <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>Password<span class="obligatorio">*</span></h5>
                    <input formControlName="password" matInput type="text"  matInput required >
                    <mat-error *ngIf="registerForm.get('password')?.hasError('required')"  i18n>{{"required" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('password')?.hasError('pattern')"  i18n>{{"pattern" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('password')?.hasError('maxLength')"  i18n>{{"maxLength" | translate }}</mat-error>
                </mat-form-field>
            </div>
         </div>
      
     </div>

     <div class="row">
        <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>Transport Protocol <span class="obligatorio">*</span></h5>
                    <input formControlName="transportProtocol" matInput type="text" matInput required >
                    <mat-error *ngIf="registerForm.get('transportProtocol')?.hasError('required')"  i18n> {{"Please enter a Transport Protocol!" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('transportProtocol')?.hasError('minLength')"  i18n>{{"Minimum length not reached" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('transportProtocol')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                    <mat-error *ngIf="registerForm.get('transportProtocol')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                </mat-form-field>
            </div>
         </div>
        <div class="col-lg-6">
            <div class="card cardCustos">
                <mat-form-field ngDefaultControl>
                    <h5 i18n>Server Port <span class="obligatorio">*</span></h5>
                    <input formControlName="port" matInput type="number"  matInput required >
                    <mat-error *ngIf="registerForm.get('from')?.hasError('required')"  i18n>Please enter an port!</mat-error>
                </mat-form-field>
             </div>
        </div>
       

     </div>
     <div class="row">
        <div class="col-lg-4">
            <div class="card cardCustos">
                <section class="example-section">
                    <mat-checkbox class="example-margin" formControlName="auth"  i18n>User SSL</mat-checkbox>                    
                </section>
            </div>
         </div>
        <div class="col-lg-4">
            <div class="card cardCustos">
                        <section class="example-section">
                            <mat-checkbox class="example-margin" formControlName="starttlsEnable"  i18n>Start TLS Enable</mat-checkbox>                    
                        </section>  
             </div>
        </div>
        <div class="col-lg-4">
            <div class="card cardCustos"> 
                <section class="example-section">
                    <mat-checkbox class="example-margin" formControlName="starttlsRequired"  i18n>Start TLS Required</mat-checkbox>                    
                 </section>                  
            </div>
         </div>
     </div>

   



    <div class="row botonera" >
         <div class="col-lg-12">
                 <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>Submit</button> 
         </div>
     </div>


   
 </form>
 </section>

             
             
             
             
             
             

</main>