import { DOCUMENT } from '@angular/common';
import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ConfirmReferencedTransaction } from 'src/app/models/confirmReferencedTransaction.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
@Component({
  selector: 'app-trx-referenced',
  templateUrl: './trx-referenced.component.html',
  styleUrls: ['./trx-referenced.component.css']
})
export class TrxReferencedComponent implements OnInit {
  
  error : boolean = false;
  success: boolean = false;
  resp: any;


  constructor(@Inject(DOCUMENT) document: any,
      private restService : RestService,
      private route: ActivatedRoute) {
      
  }


  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      let confirmReferencedTransaction = {
        id : params["id"],
        username : params["username"],
        uuid : params["uuid"],
        type: params["type"]
      }
      this.processConfirmation(confirmReferencedTransaction)

    });

  }

  private async processConfirmation(req : ConfirmReferencedTransaction){
    
    let trx = await this.restService.commonRestCallXiiberoPlatform(req, "referencedTransaction",true, true);    
    if (trx && (!trx.errorDescription || trx.errorDescription.length ===0)) {      
      this.resp = trx;
      this.success = true;
    } else {
      this.error = true;
    }
  }

}
