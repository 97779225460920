import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Filter, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';


@Injectable({
  providedIn: 'root',
})
export class AlertsService {
  constructor(private merchantRest : RestService) {}
  
  async sendService(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'notifications/query',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }
 
}