import { AfterViewInit, Component, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Merchants } from 'src/app/models/merchants.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { CommonService } from 'src/app/services/common.service';
import {  VIWER_ENTORNO, environment } from 'src/environments/environment';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { OrganizationsService } from '../organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { Division } from 'src/app/models/division.model';
import { DivisionService } from './division.service';
import { FormDivisionComponent } from './form-new/form-division.component';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { TranslateService } from '@ngx-translate/core';

import * as XLSX from "xlsx";
import { DataExport_Division_Xiibero } from 'src/app/models/dataExport/dataExport_division_Xiibero.model';
import { ENTORNO } from 'src/app/util/constants';
declare var require: any


enum Columns_Xiibero {
  name = 'name', 
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  info_email = 'info.email', 
  view = 'view'
}




@Component({
  selector: 'app-division',
  templateUrl: './division.component.html',
  styleUrls: ['./division.component.css']
})
export class DivisionComponent implements OnInit,AfterViewInit {

  entorno = VIWER_ENTORNO

  displayedColumns =  Object.keys(Columns_Xiibero);
  dataSource = new MatTableDataSource<Division>();
  
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns_Xiibero.name+",desc";//idColumna + "," + direccion


  merchantEmailFilter = new FormControl();
  nameFilter = new FormControl();
  locationNameFilter = new FormControl();
  categoryCodeFilter = new FormControl();
  organizationFilter = new FormControl();
  cityFilter = new FormControl();
  stateFilter = new FormControl();
  countryFilter = new FormControl();
  organizationXiiberoSelect!: Organization;
  organizationXiiberoList:Organization[] = [];


  jerarquiaSearchSbscription!: Subscription;

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRef!: MatDialogRef<FormDivisionComponent>;



  constructor(
      private servicios : DivisionService,
      public dialog: MatDialog,
      public authServer: AuthService,
      public utilServer: UtilService,
      private orgService: OrganizationsService,
      public commonService : CommonService,
      private translate: TranslateService) {  
  }
  
  categoryCodeDesc(cod:any){
    var mcc = require('merchant-category-code');  
    let val = mcc(cod);
    if(val){
      return val.usda_description;
    }
    return $localize `not yet defined`;
  }

  async lanzarLLamada(){
   
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "type", "DIVISION"); 
    
    let value = await this.servicios.find(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Division>(value.content);
      this.pageLength=value.totalElements;
    }
}



cargarCache() {

  let value = this.utilServer.cargarCacheDomain();

  if(value && value!="undefined"){
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
  }else{
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
  }


  this.lanzarLLamada();  
}






async ngOnInit(): Promise<void> {


  
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());



    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
   
    this.displayedColumns =  Object.keys(Columns_Xiibero);
    

    await this.cargar();

   
    this.merchantEmailFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      const idColumn = Columns_Xiibero.info_email;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();     
    });
    this.nameFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns_Xiibero.name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });    


    await this.cargarCache()

  }



  async cargar(){   
    //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srOrg = await this.orgService.find(rfOrg,true);     
      if(srOrg){
        this.organizationXiiberoList= srOrg.content;
        if(this.organizationXiiberoList.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.organizationXiiberoSelect = this.organizationXiiberoList[0];
        }
      }

    }



  organizationXiiberoFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 

    let value:string | undefined
    
    const idColumn = "domain";
    if(org){
      value=org.domainRoot+"."
    }

    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();  
  }


 
  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLLamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirOrder(this.rf.filter , this.pageSize,this.sortId);     
    this.lanzarLLamada();
  }

  refrescar() {
    this.lanzarLLamada();
  }








  
  onNew(){
    this.dialogRef = this.dialog.open(FormDivisionComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   onEdit(row: any) {

    this.dialogRef = this.dialog.open(FormDivisionComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {
        obj: row
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }



   onDelete(row: any) {

    console.log('Delete pressed: ', row);
 
      const dialogData = new ConfirmDialogData();
      this.translate
      .get([
        "Delete Division",        
        "Are you sure you want to delete it?"
      ])
      .subscribe(translation => {
          dialogData.titleI18n = translation["Delete Division"];
          dialogData.textI18n = translation["Are you sure you want to delete it?"];
          dialogData.textValue = row.name;
      
        this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
          width: '25%', panelClass: 'custom-modalbox',
          data: dialogData
        });
      });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        await this.servicios.deleteService(row.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();
      } else if (result === ConfirmDialogActions.CANCEL) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }





  
  exportIt() {
    let newDate = new Date();
    let nombre = "division-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
    const fileName = nombre+".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading
    if(this.entorno==ENTORNO.XIIBERO){
      Heading = [['Organization','Name']];
    }else{
      //GETNET
      Heading=[['']]
    }
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "division");

    XLSX.writeFile(wb, fileName);
}




private procesarDataExport(data:Division[]): any[]  {
  
  if(this.entorno==ENTORNO.XIIBERO){
    
    let result : DataExport_Division_Xiibero[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Division_Xiibero = new DataExport_Division_Xiibero(row);
      result.push(tempRow);
    });
    return result;
  }else{   
    //GETNET 
    return [];
  }
}

}


