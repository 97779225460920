import { Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';
import { OrganizationTemplate } from '../models/organization.model';
import { TypeJerarquia } from '../util/constants';
import { QueryFilterList, RequestFilter } from '../models/requestFilter.model';
import { MerchantService } from '../pages/views/merchants/merchant.service';
import { RegionService } from '../pages/views/region/region.service';
import { DivisionService } from '../pages/views/division/division.service';

enum TransactionTypeTotype  {
  PAYMENT = 'SALE',
  PREAUTH = 'PRE_AUTH',
  TOPUP = 'MOD_PRE_AUTH',
  CONFIRM = 'CONFIRMATION',
  REFUND= 'REFUND',
  MANUAL_VOID= 'MANUAL_VOID',
  DUPLICATE= 'DUPLICATE',
  VOID= 'VOID',
  DIGITIZED_SIGNATURE= 'DIGITIZED_SIGNATURE',
  TOTALS= 'TOTALS',		
  SETTLEMENT= 'SETTLEMENT',
  TRANSACTIONS_DETAIL= 'DETAIL',
  COMMUNICATION_TEST= 'COMM_TEST',
  OTHERS= 'OTHERS',
}	

export interface toastPayload {
  message: string;
  title: string;
  ic: IndividualConfig;
  type: string;
}

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  constructor(
      private toastr: ToastrService,
      private authService: AuthService) {}

  showSuccess(toast: toastPayload) {
    this.toastr.success(
      toast.message,
      toast.title,
      toast.ic);
  }

  showWarning(toast: toastPayload) {
    this.toastr.warning(
      toast.message,
      toast.title,
      toast.ic);
  }

  isOrganizationlevel():boolean {
    try {
      var accesslvl = 100;
      var tk = this.authService.getUserAccessToken();
      var domains = tk!.auth!.domains as Array<string>;
      domains.forEach(element => {
        var lvl = element.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 1;
    } catch(err) {
      return false;
    }
    return false;
  }

  isMerchantlevel():boolean {
    try {
      var accesslvl = 100;
      var tk = this.authService.getUserAccessToken();
      var domains = tk!.auth!.domains as Array<string>;
      domains.forEach(element => {
        var lvl = element.split('.');
        if (lvl != undefined) {
          if (lvl.length < accesslvl) 
            accesslvl = lvl.length;
        }
      });
      return accesslvl == 2;
    } catch(err) {
      return false;
    }
    return false;
  }




  public checkItemRights(item:string, perm: string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        var tk = this.authService.getUserAccessToken();

        var permissions = tk!.auth!.permissions!['merchantsApi'];        
        var permissions_Xiibero = tk!.auth!.permissions!['adminXiibero'];

        var permission : string = permissions![item];
        var permissionXi : string = permissions_Xiibero![item];

        var result = false;
        if( ((permission != undefined) && (permission.length > 0) && (permission.includes(perm)))){
          result=true;
        }else if( ((permissionXi != undefined) && (permissionXi.length > 0) && (permissionXi.includes(perm)))){
          result=true;
        }
        
        return result;
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  
  public checkAuthServerItemRights(item:string, perm: string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        var tk = this.authService.getUserAccessToken();
        var permissions = tk!.auth!.permissions!['authserver'];
        var permission : string = permissions![item];
        return ((permission != undefined) && (permission.length > 0) && (permission.includes(perm)));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  phoneticLookup(val:string):string {
    var result = "";
  
    // Only change code below this line
    enum lookup  {
      "alpha"= "Adams",
      "bravo"= "Boston",
    };
    
    result = <lookup>val;
    
    // Only change code above this line
    return result;
  }

  public getClientType(tp : string): string {

    let typedString = tp as keyof typeof TransactionTypeTotype;

    return TransactionTypeTotype[typedString];
  }





  public checkJerarquia(templace:OrganizationTemplate, nivel:TypeJerarquia): boolean  {
    if (templace && nivel) {     
        let permiso = false;

        if(nivel == TypeJerarquia.REGION && templace.hasRegions) {     
            permiso = templace.hasRegions;
        }else if(nivel == TypeJerarquia.DIVISION && templace.hasDelegations) {     
            permiso = templace.hasDelegations;
        }else if(nivel == TypeJerarquia.SUBSIDIARY && templace.hasSubsidiary) {     
            permiso = templace.hasSubsidiary;
        }
     
        return permiso;
      
    } 
      
    return false;

  }







}