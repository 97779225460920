
import { Division } from "../division.model";

export class DataExport_Division_Xiibero {

  organizationName: string | undefined; 
  name: string | undefined;


  constructor(values: Division = new Division) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.name= values.name
  }


}


