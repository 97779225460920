import { AfterViewInit, Component, Inject, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent } from "@angular/material/legacy-paginator";
import { DualListComponent } from 'src/app/components/dual-listbox/dual-list.component';
import { FormGroupsComponent } from '../form-groups/form-groups.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

import { TranslateService } from '@ngx-translate/core';
import { Users } from 'src/app/models/users.model';
import { NgxSpinnerService } from 'ngx-spinner';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { UsersService } from '../../users/users.service';
import { FormCreateActions } from 'src/app/util/constants';


@Component({
  selector: 'app-members-groups',
  templateUrl: './members-groups.component.html',
  styleUrls: ['./members-groups.component.css']
})
export class MembersGroupsComponent implements OnInit{
  
  formartDualList = { add: 'Add', remove: 'Remove', all: 'All', none: 'None',
            direction: DualListComponent.LTR, draggable: true, locale: undefined };
 
  
  listaTotalUser:Users[] = [];
  listaResultSelect:Users[] = [];  
  keyDualList: any = 'username';
  displayDualList: any = 'username';
  
  ngOnInit(): void {
    throw new Error('Method not implemented.');
  }


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private spinnerService: NgxSpinnerService,
    private usersService: UsersService,
    public dialogRef: MatDialogRef<FormGroupsComponent>,
    public dialog: MatDialog) { 
     
  }


  async cargar(){

    
    this.spinnerService.show();

      if(this.data?.obj){
          //usuarios
          let rfUser: RequestFilter= new RequestFilter(null,0, 100,"name,desc");   
          let mapQuery = new Map();
          rfUser.filter = QueryFilterList.construirFilter(mapQuery, "auth.groups", this.data.obj.name);       
          let srUser = await this.usersService.findService(rfUser);     
          if(srUser){
            this.listaTotalUser= srUser.content;
          }

      }

      
      this.spinnerService.hide();

    }



    onFormSubmit(): void {


      this.listaResultSelect
     /* console.log(this.registerForm);
      
      if (this.registerForm.invalid) {
        return;
      }    
      const data = this.applyFormats(this.registerForm);
  
      let groupsNew : Groups = new Groups();
  
      groupsNew.name=data['name']  
      groupsNew.name=data['name']  
      groupsNew.description=data['description']  
      
      let rolNew:Rol
      let orgNew:Organization
      let merchNew:Merchants
  
      let rolArray=[]
      let orgArray=[]
      let merchArray=[]
  
      rolNew=data['rolMarcado']
      orgNew=data['orgMarcado']
      merchNew=data['merchMarcado']
  
      rolArray[0]=rolNew.name
      orgArray[0]=orgNew.domainRoot
      merchArray[0]=merchNew?.domain
  
      groupsNew.roles=rolArray 
      if(merchArray[0]){
        groupsNew.domains=merchArray
      }else{
        groupsNew.domains=orgArray
      }
  
      const dialogData = new ConfirmDialogData(); 
      dialogData.titleI18n = $localize `Create Group`;
      dialogData.textI18n = $localize `Are you sure you want to create it?`;
  
  
      this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
        width: '25%', panelClass: 'custom-modalbox',
        data: dialogData
      });
  
      this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
        if (result === ConfirmDialogActions.CONFIRM) {
          
            console.log('Form SAVE action');
            this.services.saveEditService(groupsNew)
            this.dialogRef.close(FormCreateActions.SAVED);
  
        } else if (result === ConfirmDialogActions.CANCEL) {
          
        }
      });*/
  
    }
  
  
    formExit(): void {
      console.log('Form exit action');
      this.dialogRef.close(FormCreateActions.EXIT);
    }

}

