
import { Region } from "../region.model";

export class DataExport_Region_Xiibero {

  organizationName: string | undefined; 
  name: string | undefined;


  constructor(values: Region = new Region) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.name= values.name
  }


}


