import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_LOCALE, LOCALES } from '../../util/chartsLocales';

import {
  ChartComponent,
  ApexChart,
  ApexAxisChartSeries,
  ApexTitleSubtitle,
  ApexDataLabels,
  ApexFill,
  ApexYAxis,
  ApexXAxis,
  ApexTooltip,
  ApexMarkers,
  ApexAnnotations,
  ApexLegend,
  ApexStroke,
  ApexResponsive
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  markers: ApexMarkers;
  title: ApexTitleSubtitle;
  fill: ApexFill;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  annotations: ApexAnnotations;
  legend: ApexLegend;
  colors: any;
  toolbar: any;
  responsive: ApexResponsive[];
};

@Component({
  selector: 'app-chart-tx-daily-amount',
  templateUrl: './chart-tx-amount.component.html',
  styleUrls: ['./chart-tx-amount.component.css']
})
export class ChartTxDailyAmountComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle !: string;
  @Input() dates!: Date[];
  @Input() values!: Map<string, number[]>;

  public chartOptions!: Partial<ChartOptions>;

  constructor(private translate: TranslateService) { }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartDAM', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.dates == undefined)) {
      this.chartOptions.series!.length = 0;
      if (this.dates.length > 0) {
        for (let entry of this.values.entries()) {
          var v : number[][] = new Array<Array<number>>();
          for (let i=0; i<this.dates.length; i++) {
            var item : number[] = new Array<number>();
            item.push(new Date(this.dates[i]).getTime());
            item.push(entry[1][i]);
            v.push(item);
          }
          this.chartOptions.series!.push({ name : entry[0], data : v});
        }
        this.chart.updateSeries(this.chartOptions.series!);
        this.chart.setLocale(this.translate.currentLang);
      }
    }
  }

  data = [
    [0,0]
  ];
  
  createChart(){
    this.chartOptions = {
      series: [
        {
          data: this.data
        }
      ],
      chart: {
        defaultLocale : DEFAULT_LOCALE,
        locales: LOCALES,
        type: "area",
        //height: 350
      },
      annotations: {
      },
      dataLabels: {
        enabled: false
      },
      markers: {
        size: 0
      },
      xaxis: {
        type: "datetime",
        tickAmount: 6
      },
      yaxis : {
        labels: {
          formatter: function(val) {
            return val.toFixed(2);
          }
        }
      },
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy"
        }
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.7,
          opacityTo: 0.9,
          stops: [0, 100]
        }
      },
      responsive: [ 
        {
        }
      ]
    };

  }
}
