<div>
   <apx-chart #chartDAM
   [series]="chartOptions.series!"
   [chart]="chartOptions.chart!"
   [colors]="chartOptions.colors!"
   [yaxis]="chartOptions.yaxis!"
   [dataLabels]="chartOptions.dataLabels!"
   [markers]="chartOptions.markers!"
   [stroke]="chartOptions.stroke!"
   [xaxis]="chartOptions.xaxis!"
   [legend]="chartOptions.legend!"
   [tooltip]="chartOptions.tooltip!"
   [annotations]="chartOptions.annotations!"
   [responsive]="chartOptions.responsive!"
   ></apx-chart>
 </div>
