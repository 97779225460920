import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AuthService } from 'src/app/auth/auth.service';
import { ChangePassword} from 'src/app/models/changePassword.model';
import { UserProfile } from 'src/app/models/users.model';
import { countryListAllIsoData, countryListAlpha2 } from 'src/app/util/country';
import { UsersProfileService } from './users-profile.service';
import {TranslateService} from "@ngx-translate/core";
import * as moment from 'moment-timezone';
import { NotifierService } from 'angular-notifier';

@Component({
  selector: 'app-users-profile',
  templateUrl: './users-profile.component.html',
  styleUrls: ['./users-profile.component.css']
})
export class UsersProfileComponent implements OnInit {
  registerForm!: FormGroup;
  registerFormPass!: FormGroup;
  private oidcUserClaims: object;
  listaPaises:any=countryListAllIsoData
  pais!:string;
  passNocoincide:boolean = false;
  selectLang:string="";
  transLang=[
    {name: "English", value: "en"},
    {name: "Español", value: "es"}
  ];    

  zones;
  defaultTimeZone = moment.tz.guess();

  private readonly notifier: NotifierService;

  constructor(private authService: AuthService,  
              private formBuilder: FormBuilder,notifierService: NotifierService,
              private usersProfileService: UsersProfileService,
              public translate: TranslateService
  ) { 
    this.oidcUserClaims = this.authService.getUserClaims;
    this.notifier = notifierService;
    this.zones = moment.tz.names();    
  }
  

  ngOnInit(): void {    

    this.registerForm = this.formBuilder.group({
      firstname: [this.userProfileClaim('given_name'), Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])],
      lastname: [this.userProfileClaim('family_name'), Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern("^[A-ZÀ-ÿa-z\\s\\-.]*$")])],
      language: [this.translate.currentLang],
      timezone: [this.defaultTimeZone],
      email: [this.userProfileClaim('email'), Validators.compose([Validators.required,
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")])],
      company: [this.userProfileClaim('company')],
      job: [this.userProfileClaim('jobTitle')],
      country: [this.userProfileClaim('country')],
      address: [this.userProfileClaim('address')],
      phone: [this.userProfileClaim('phone_number')]
    });    


    //this.registerForm.patchValue({language : this.TransLang[1]});


    this.registerFormPass = this.formBuilder.group({      
      oldpass: ["", Validators.compose([Validators.required])],
      newpass: ["", Validators.compose([Validators.required,
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))])],
      repeatpass: ["", Validators.compose([Validators.required, 
        Validators.minLength(8),
        Validators.maxLength(20),
        Validators.pattern(new RegExp(/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#&()\-\[\{\}\]\:\;\'\,\?\/\*\~\$\^\+\=\<\>]).{8,20}$/))])]
    });

  }

  public userProfileClaim(key:string) {
    return this.oidcUserClaims[key as keyof typeof this.oidcUserClaims] !== undefined ? this.oidcUserClaims[key as keyof typeof this.oidcUserClaims]:'';
  }



  async onFormChangePass(){
    this.passNocoincide=false
    console.log(this.registerFormPass);
    
    if (this.registerFormPass.invalid) {
      return;
    }
   
    const data = this.applyFormats(this.registerFormPass);
    let oldpass=data['oldpass']
    let newpass=data['newpass']
    let repeatpass=data['repeatpass']

      if(newpass!=repeatpass){
        this.passNocoincide=true
        return;
      }


    let changePassword: ChangePassword = new ChangePassword();
    changePassword.newPassword=newpass
    changePassword.oldPassword=oldpass

    let valor = await this.usersProfileService.changePassword(changePassword)
    if(valor){
      this.notifier.notify('success', 'Save Successfull');
    }
  }





  onFormSubmit(){

    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }   

    const data = this.applyFormats(this.registerForm);
    //let firstname=data['firstname']
    //let lastname=data['lastname']
   // let email=data['email']
    let company=data['company']
    let job=data['job']
    let country=data['country']
    let address=data['address']
    let phone=data['phone']

    let obj: UserProfile = new UserProfile();
    obj.company=company
    obj.jobTitle=job
    obj.country=country
    obj.address=address
    obj.phone=phone    

    //TODO store in storage or db user or where we want
    if (data['language']) {
      this.translate.use(data['language']);
      localStorage.setItem("language", data['language']);
      obj.locale = data['language'];
    }

    if (data['timezone']) {
      localStorage.setItem("timezone", data['timezone']);
      obj.zoneinfo = data['timezone'];
    }


    //TODO store in storage or db user or where we want
    if (data['language']) {
      this.translate.use(data['language']);
      localStorage.setItem("language", data['language']);
      obj.locale = data['language'];
    }


    this.usersProfileService.editProfile(obj)



    //this.authService.login()

  }


  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}
