import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { QueriesService } from 'src/app/services/queries.service';
import { Subscription,  distinctUntilChanged,  timer } from 'rxjs';
import { Feedbacks } from 'src/app/util/constants';
import { Log } from 'src/app/util/log';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import CurrencyList from 'currency-list';
import { DashboardService } from './dashboard.service';
import { MatExpansionPanel } from '@angular/material/expansion';
import { __values } from 'tslib';
import { CommonService } from 'src/app/services/common.service';
import { AuthService } from 'src/app/auth/auth.service';
import { UtilService } from 'src/app/services/util.service';
import { TranslateService } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import moment from 'moment';
import { NotifierService } from 'angular-notifier';
import { formatDate } from '@angular/common';
import { DateRange } from 'src/app/models/date-range.interface';

interface dashboardPayload {
  totalOps: number,
  paymentOps: number,
  payments: number,
  voidOps: number,
  voids: number
  refundOps: number,
  refunds: number,
  preauthOps: number,
  preauth: number,
  modauthOps: number,
  modauth: number,
  confirmOps: number,
  confirm: number,
  
}

interface dashboardTipsPayload {
  tipsOps: number,
  tips: number,
}

interface dashboardSplitPayload {
  splitOps: number,
  split: number,
}

interface currencyInfo {
  name: string,
  isoname: string,
}


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent implements OnInit, OnDestroy {
  domainCache:string | undefined;
  salesSelectedRange: string = '';
  salesSelectedIndex: number = 2;
  public items: Array<string> = [];
  
  localDateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  lastStartDate: Date | null = null;

  graph1Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph2Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph3Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph4Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph5Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph6Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph7Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph8Data : ChartPayload = {
    labels :   [],
    values : []
  }

//  numTxByHour : number[] = [1,2,3,4,5,6,7,8,9,0,1,2,3,4,5,6,7,8,9,0,1,2,3,4];
  aggTxByHour : any = {
    monday :  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    tuesday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    wednesday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    thursday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    friday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    saturday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
    sunday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
  }

  aggTxDaily : any = {
    dates :  [],
    values : [],
    prevValues : [],
  }
  
  aggTxDailyAmount : any = {
    dates :  [],
    values : [],
    prevValues : [],
  }
  aggSplitTxDailyAmount : any = {
    dates :  [],
    values : [],
    prevValues : [],
  }

  aggCmpTxDaily : any = {
    dates :  [],
    values : [],
    prevValues : [],
  }
  
  aggCmpTxDailyAmount : any = {
    dates :  [],
    values : [],
    prevValues : [],
  }

  aggCmpTypeDaily: any = {
    labels :  [],
    values : [],
    prevValues : [],
  }

  dashboardCurrency : currencyInfo = {
    name: "euro",
    isoname: "EUR"
  }

  dashboardCurrencies = new Array<currencyInfo>();
  shownCurrencies = new Array<string>();

  dashboardIndicators : dashboardPayload = {
    totalOps: 0,
    paymentOps: 0,
    payments: 0.0,
    voidOps: 0,
    voids: 0.0,
    refundOps: 0,
    refunds: 0.0,
    preauthOps: 0,
    preauth: 0.0,
    modauthOps: 0,
    modauth: 0.0,
    confirmOps: 0,
    confirm: 0.0
    };

  dashboardTipsIndicators : dashboardTipsPayload = {
    tipsOps: 0,
    tips: 0.0,
  };

  dashboardSplitIndicators : dashboardSplitPayload = {
    splitOps: 0,
    split: 0.0
  };


  mapDashboardIndicators = new Map<string, dashboardPayload>([
    ["EUR", this.dashboardIndicators]
  ]);

  mapDashboardTipsIndicators = new Map<string, dashboardTipsPayload>([]);
  mapDashboardSplitIndicators = new Map<string, dashboardSplitPayload>([]);

  mapDashboardIndex : number = 0;
  isOrganization : boolean = true;
  dashboardTimer : any;

  @ViewChild('matexpansionpanel1', {static: false}) matExpansionPanelElement1!: MatExpansionPanel;
  @ViewChild('matexpansionpanel2', {static: false}) matExpansionPanelElement2!: MatExpansionPanel;
  @ViewChild('matexpansionpanel3', {static: false}) matExpansionPanelElement3!: MatExpansionPanel;
  @ViewChild('matexpansionpanel4', {static: false}) matExpansionPanelElement4!: MatExpansionPanel;

  
  jerarquiaSearchSbscription!: Subscription;

  constructor(private elementRef: ElementRef, 
              private queries : QueriesService,
              private servicios : DashboardService,
              private fb: FormBuilder,
              private merchantRest : RestService,
              private authService: AuthService,
              public utilServer: UtilService,
              private commonService : CommonService,
              private translate: TranslateService,
              private notifier: NotifierService) 
  { 


      this.isOrganization = this.commonService.isOrganizationlevel();



    
  }

  
  expand(id:string, clase:string) {
    
    let  cards = document.getElementsByClassName(clase) as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < cards.length; i ++) {
      cards[i].style.display = 'none';
    }
    

    let cardsSelect = document.getElementById(id);
    if(cardsSelect) {
      cardsSelect.style.display = '';
      cardsSelect.classList.add("full-screem");
    }  
    
    let  butonExpand = document.getElementById(id+"_angle-expand");
    let  butonContract = document.getElementById(id+"_angle-contract");
    butonExpand!.style.display = 'none';
    butonContract!.style.display = '';

    this.update()
  }

  contrand(id:string,clase:string) {
    
    let  cards = document.getElementsByClassName(clase) as HTMLCollectionOf<HTMLElement>;
    for (var i = 0; i < cards.length; i ++) {
      cards[i].style.display = '';
      cards[i].classList.remove("full-screem");
    }


    let  butonExpand = document.getElementById(id+"_angle-expand");
    let  butonContract = document.getElementById(id+"_angle-contract");
    butonExpand!.style.display = '';
    butonContract!.style.display = 'none';

    this.update()
  }
   
  
  ngOnInit(): void {

    this.initializeDateFilter();
  this.localDateFilter.valueChanges.subscribe(values => {

   
    const end = this.localDateFilter.get('end')?.value;
      if (!this.dateRangeValidator() && end && !this.sameDate(this.lastStartDate!,end)) {
        const start = this.localDateFilter.get('start')!.value;
        this.salesSelectedRange = formatDate(start!, 'MM/dd/yyyy', 'en') + ' to ' + formatDate(end, 'MM/dd/yyyy' , 'en'); 
        this.selectChangeHandler();
        this.lastStartDate = end;
      } else if (this.dateRangeValidator()){
          this.notifier.notify('error', 'Please choose a date range of no more than one month.');
        }
    });

    this.jerarquiaSearchSbscription = this.authService.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());

    this.setDataRefresh();

    
  }

  private sameDate(date1: Date, date2: Date): boolean {
    return date1 && date2 && date1.getTime() === date2.getTime();
  }

  private dateRangeValidator() {
    const start = this.localDateFilter.get('start')?.value;
    const end = this.localDateFilter.get('end')?.value;
    if (start && end) {
      const startMoment = moment(start);
      const endMoment = moment(end);
      const monthsDiff = endMoment.diff(startMoment, 'months', true);
      return monthsDiff <= 1 ? null : { dateRangeInvalid: true };
    }
    return null;
  }
  private initializeDateFilter() {
    const startOfWeek = moment().startOf('isoWeek').toDate(); 
    const endOfWeek = moment().endOf('isoWeek').toDate(); 

    this.localDateFilter = this.fb.group({
      start: startOfWeek,
      end: endOfWeek
    });
    this.lastStartDate = endOfWeek;
    this.salesSelectedRange = formatDate(startOfWeek!, 'MM/dd/yyyy', 'en') + ' to ' + formatDate(endOfWeek, 'MM/dd/yyyy' , 'en'); 

  }
  onPickerClosed(): void {
    const startControl = this.localDateFilter.get('start');
    const endControl = this.localDateFilter.get('end');
  
    if (startControl && endControl) {
      const startValue = startControl.value;
      const endValue = endControl.value;
  
      if (startValue && !endValue) {
        const maxEndDate = moment(startValue).add(1, 'months').subtract(1, 'days').endOf('day').toDate();
        endControl.setValue(maxEndDate);
      }
      }
  }
  
  cargarCache() { 
    this.update();
  }


  ngOnDestroy(): void {
    if (this.dashboardTimer != undefined)
      this.dashboardTimer.unsubscribe();
  }

  selectChangeHandler () {
    this.aggCmpTxDaily.prevValues = [];
    this.aggCmpTxDailyAmount.prevValues = [];
    this.aggCmpTxDaily.values = [];
    this.aggCmpTxDailyAmount.values = [];
    this.updateIndicators();
    this.updateTransactionsByType();
    this.updateTopMerchants();
    this.updateModels();
    this.updateAggByHour();
    this.updateAggComparationByType();
  }

  selectChangeCurrency (event: any, item: currencyInfo) {
    //update the ui

    this.aggCmpTxDaily.prevValues = [];
    this.aggCmpTxDailyAmount.prevValues = [];
    this.dashboardCurrency = item;
    this.mapDashboardIndicators.clear();
    this.mapDashboardTipsIndicators.clear();
    this.mapDashboardSplitIndicators.clear();
    this.updateIndicators();
  }

    // Transaction by type
  updateTransactionsByType() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
    return;
    
    let dateRange = {
      from: moment(this.localDateFilter.get('start')?.value).toDate(), 
      to: moment(this.localDateFilter.get('end')?.value).toDate()
  };

  


      this.servicios.sendAggrTxService(this.queries.createCurrentTypeBreakout(dateRange,this.domainCache)).then(
        value => {
          this.graph1Data = {...value};
          for (let i=0; i< this.graph1Data.labels.length; i++) {
            this.graph1Data.labels[i] = this.commonService.getClientType(this.graph1Data.labels[i]);
          }
        });
      this.servicios.sendAggrTxService(this.queries.createCurrentBrandBreakout(dateRange,this.domainCache)).then(
        value => this.graph2Data = {...value});
      this.servicios.sendAggrTxService(this.queries.createCurrentTechBreakout(dateRange,this.domainCache)).then(
        value => this.graph3Data = {...value});
      this.servicios.sendAggrTxService(this.queries.createCurrentCurrencyBreakout(dateRange,this.domainCache)).then(
        value => this.graph4Data = {...value});
      this.servicios.sendAggrTxService(this.queries.createCurrentResultsBreakout(dateRange,this.domainCache)).then(
        value => this.graph5Data = {...value});
      this.servicios.sendAggrTxService(this.queries.createCurrentAuthModeBreakout(dateRange,this.domainCache)).then(
          value => this.graph8Data = {...value});
  
  }
  
  updateModels() {
    if(!this.hasAccess("terminal") || this.dateRangeValidator() )
      return;

    this.servicios.sendAggrTermService(this.queries.createCurrentModelBreakout()).then(
      value => this.graph6Data = {...value}); 
  }

  updateTopMerchants() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
      return;

    if (!this.isOrganization) 
      return;
    try {
      let dateRange = {
        from: moment(this.localDateFilter.get('start')?.value).toDate(), 
        to: moment(this.localDateFilter.get('end')?.value).toDate()
    };
      var merchants : Map<string, number> = new Map<string, number>();
      var topMerchants : Map<string, number> = new Map<string, number>();
      let value =  this.merchantRest.commonRestCallMerchant(
                this.queries.createTop5Merchants(dateRange, this.dashboardCurrency.isoname,this.domainCache),
                'transactions/aggregation',false,false).then(
          value => {
            if(value){
              value.buckets.forEach((element: { key: string; number: number; subAggregation: any;  }) => {
                merchants.set(element.key, element.subAggregation.value);
              });
            }

            const merchantsSort = new Map([...merchants.entries()].sort((a, b) => b[1] - a[1]));
            console.log(merchantsSort);
            let maxMer =  (merchantsSort.size>5) ? 5 : merchantsSort.size;
            let merkeys =[ ...merchantsSort.keys() ];
            for (let i = 0; i < maxMer; i++) {
              let item = merchantsSort.get(merkeys[i]);
              topMerchants.set(merkeys[i], merchantsSort.get(merkeys[i])!);
            }
            var test : ChartPayload = {
              labels : [...topMerchants.keys()],
              values : [...topMerchants.values()]
            };
            if (test.labels.length > 0)
            {
              this.merchantRest.commonRestCallMerchant(this.queries.createMerchantNamesQuery(test.labels), 
                'merchant/query',false,false).then(
                value => {
                  if(value){
                  value.content.forEach((element: { id: string; name: string;  }) => {
                    for (let k=0; k<test.labels.length;k++)
                    {
                      if (test.labels[k] == element.id) {
                        test.labels[k] = element.name;
                      }
                    }
                  });
                }
                  this.graph7Data = {...test};    
                }
              );              
            }
          })
      .catch(
          err => { Log.error(err) 
          });              
    } catch (error) {
      console.log('transactions error ' + JSON.stringify(error));
    }
  }

  updateAggByHour() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
    return;


    // Transaction by type
    try {
      let dateRange = {
        from: moment(this.localDateFilter.get('start')?.value).toDate(), 
        to: moment(this.localDateFilter.get('end')?.value).toDate()
    };
      let value =  this.merchantRest.commonRestCallMerchant(
              this.queries.createAggregationByHour(dateRange,this.domainCache), 
              'transactions/aggregation', false, false)
              .then(
          value => {
    
            var aggTxByHour = {
              monday :  [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              tuesday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              wednesday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              thursday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              friday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              saturday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              sunday : [0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
              }
              if(value){       
            value.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
              let recdate: Date = new Date(element.key); 
              let numday = recdate.getDay();
              // Read dashboard indicators                                      
              element.subAggregation.buckets.forEach((subitem: { key: string; number: number; subAggregation: any}) => {
                switch (numday) {
                  case 0:
                    aggTxByHour.sunday[+subitem.key] += subitem.number;
                    break;
                  case 1:
                    aggTxByHour.monday[+subitem.key] += subitem.number;
                    break;
                  case 2:
                    aggTxByHour.tuesday[+subitem.key] += subitem.number;
                    break;
                  case 3:
                    aggTxByHour.wednesday[+subitem.key] += subitem.number;
                    break;
                  case 4:
                    aggTxByHour.thursday[+subitem.key] += subitem.number;
                    break;
                  case 5:
                    aggTxByHour.friday[+subitem.key] += subitem.number;
                    break;
                  case 6:
                    aggTxByHour.saturday[+subitem.key] += subitem.number;
                    break;
                  }
                });
            });
          }
            this.aggTxByHour = {...aggTxByHour};
          })
      .catch(
          err => { Log.error(err) 
          });              
    } catch (error) {
      console.log('transactions error ' + JSON.stringify(error));
    }
    
  }

  updateAggDailyAmount() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
      return;
      let dateRange = {
        from: moment(this.localDateFilter.get('start')?.value).toDate(), 
        to: moment(this.localDateFilter.get('end')?.value).toDate()
    };
      this.servicios.sendAggrTxAmountService(this.queries.createAggregationByDailyAmount(dateRange,this.domainCache), this.shownCurrencies, 
      dateRange).then(
        value => 
            {
              this.aggTxDailyAmount = {...{dates:value.dates, values:value.amounts}};
              this.aggTxDaily = {...{dates:value.dates, values:value.operations}};
              this.aggCmpTxDailyAmount = {...{dates:value.dates, values:value.amounts, prevValues:this.aggCmpTxDailyAmount.prevValues}};
              this.aggCmpTxDaily = {...{dates:value.dates, values:value.operations, prevValues:this.aggCmpTxDaily.prevValues}};

            }
        );

      this.servicios.sendAggrTxAmountService(this.queries.createAggregationByDailyAmountSplitted(dateRange,this.domainCache), this.shownCurrencies, 
      dateRange).then(
        value => 
            {
              this.aggSplitTxDailyAmount = {...{dates:value.dates, values:value.amounts}};
            }
        );
        
     }
  

  updateAggDailyAmountComparation() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
      return;


    if (this.shownCurrencies?.length >= 1) 
    {
      let dateRange = {
        from: moment(this.localDateFilter.get('start')?.value).toDate(), 
        to: moment(this.localDateFilter.get('end')?.value).toDate()
    };
    let dateRangePrevious = this.queries.getPreviousDateRange(dateRange);
      this.servicios.sendAggrTxAmountService(this.queries.createAggregationByDailyAmountPrev(dateRange,this.domainCache), this.shownCurrencies, 
      dateRangePrevious).then(
        value => 
            {
              this.aggCmpTxDailyAmount.prevValues = value.amounts;
              this.aggCmpTxDaily = {...{dates:this.aggCmpTxDaily.dates, values: this.aggCmpTxDaily.values, prevValues:value.operations}};
              
            }
        );
    }
  }

  updateIndicators() {
    if(!this.hasAccess("transaction") || this.dateRangeValidator() )
    return;
    let dateRange = {
      from: moment(this.localDateFilter.get('start')?.value).toDate(), 
      to: moment(this.localDateFilter.get('end')?.value).toDate()
  };
    try{
    
      let value =  this.merchantRest.commonRestCallMerchant(
        this.queries.createCurrentSales(dateRange,this.domainCache), 
        'transactions/aggregation', false, false).then(
      value => {
        var keys : Array<string> = new Array<string>();
        var values : Array<number> = new Array<number>();
        var selectedCurrencyFound = false;
        this.mapDashboardIndicators.clear();
        this.dashboardCurrencies = [];
        this.shownCurrencies = [];
        if(value)
        {
          if (value.buckets.length == 0) {
            var valueZero : dashboardPayload = {
              totalOps: 0,
              paymentOps: 0,
              payments: 0.0,
              voidOps: 0,
              voids: 0.0,
              refundOps: 0,
              refunds: 0.0,
              preauthOps: 0,
              preauth: 0.0,
              modauthOps: 0,
              modauth: 0.0,
              confirmOps: 0,
              confirm: 0.0};
              this.dashboardIndicators = valueZero;
          } 
          else 
          {
            value.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
              var value : dashboardPayload = {
                totalOps: element.number,
                paymentOps: 0,
                payments: 0.0,
                voidOps: 0,
                voids: 0.0,
                refundOps: 0,
                refunds: 0.0,
                preauthOps: 0,
                preauth: 0.0,
                modauthOps: 0,
                modauth: 0.0,
                confirmOps: 0,
                confirm: 0.0};
            
              // Read dashboard indicators                                      
              element.subAggregation.buckets.forEach((subitem: { key: string; number: number; subAggregation: any}) => {
                switch (subitem.key) {
                  case 'PAYMENT':
                    value.paymentOps = subitem.number;
                    value.payments = subitem.subAggregation.value;
                    break;
                  case 'REFUND':
                    value.refundOps = subitem.number;
                    value.refunds = subitem.subAggregation.value;
                    break;
                  case 'VOID':
                    value.voidOps = subitem.number;
                    value.voids = subitem.subAggregation.value;
                    break;
                  case 'PREAUTH':
                    value.preauthOps = subitem.number;
                    value.preauth = subitem.subAggregation.value;
                    break;
                  case 'TOPUP':
                    value.modauthOps = subitem.number;
                    value.modauth = subitem.subAggregation.value;
                    break;
                  case 'CONFIRM':
                    value.confirmOps = subitem.number;
                    value.confirm = subitem.subAggregation.value;
                    break;
                }
                });
              
              //
              this.mapDashboardIndicators.set(element.key, value);
              // If is selected currency, save in current values
              if (element.key == this.dashboardCurrency.isoname) {
                this.dashboardIndicators = value;
                selectedCurrencyFound = true;
              }
              // Updates currencies supported
              var cc : currencyInfo;
              try {
                cc = {
                  name:  CurrencyList.get(element.key).name_plural,
                  isoname: element.key,
                }
              } catch(error) {
                cc = {
                  name:  element.key,
                  isoname: 'default',
                }
              }

              this.dashboardCurrencies.push(cc);
              this.shownCurrencies.push(element.key);
            });
          }
          if (selectedCurrencyFound == false)
          {
            if (this.dashboardCurrencies.length > 0) 
            { 
              this.dashboardCurrency.isoname = this.dashboardCurrencies[0]?.isoname;
              this.dashboardIndicators = this.mapDashboardIndicators?.get(this.dashboardCurrency.isoname)!;
            }
          }
        }
        if (((this.matExpansionPanelElement1 != undefined) && (this.matExpansionPanelElement1.expanded)) ||
            ((this.matExpansionPanelElement4 != undefined) && (this.matExpansionPanelElement4.expanded)))
        {
          this.updateAggDailyAmount();
        }
        if ((this.matExpansionPanelElement4 != undefined) && (this.matExpansionPanelElement4.expanded)) 
        {
          this.updateAggDailyAmountComparation();
        }
      })
      .catch(
          err => { Log.error(err) 
          });              
    } catch (error) {
      console.log('transactions error ' + JSON.stringify(error));
    }

    try{
      let value =  this.merchantRest.commonRestCallMerchant(
                   this.queries.createCurrentSalesWithTips(dateRange,this.domainCache), 
                   'transactions/aggregation', 
                   false, 
                   false).then(
      value => {
        if(value){
          this.mapDashboardTipsIndicators.clear();
          this.dashboardTipsIndicators = {tipsOps : 0, tips : 0};
          value.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
            this.mapDashboardTipsIndicators.set(element.key, {tipsOps : element.number, tips : element.subAggregation.value});
            if (element.key == this.dashboardCurrency.isoname) {
              this.dashboardTipsIndicators = {tipsOps : element.number, tips : element.subAggregation.value};
            }
          });
      }
      })
      .catch(
          err => { Log.error(err) 
       });              
    } catch (error) {
      console.log('transactions error ' + JSON.stringify(error));
    }


    try{
      let value =  this.merchantRest.commonRestCallMerchant(
                this.queries.createCurrentSplitSales(dateRange,this.domainCache), 
                  'transactions/aggregation', 
                   false, 
                   false).then(
      value => {
        this.mapDashboardSplitIndicators.clear();
        this.dashboardSplitIndicators = {splitOps : 0, split : 0};
        if(value){
        value.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
          element.subAggregation.buckets.forEach((subitem: { key: string; number: number; subAggregation: any}) => {
            var entry : dashboardSplitPayload = this.mapDashboardSplitIndicators.get(subitem.key)!;
            if (entry == undefined) {
              entry =  {splitOps : 0, split : 0};
              this.mapDashboardSplitIndicators.set(subitem.key, entry);

            }
            entry.split += subitem.subAggregation.value;
            entry.splitOps += subitem.number;
            this.mapDashboardSplitIndicators.set(subitem.key, entry);
            if (subitem.key == this.dashboardCurrency.isoname) {
              this.dashboardSplitIndicators = entry;
            }
          });

        });
      }
      })
      .catch(
          err => { Log.error(err) 
       });              
    } catch (error) {
      console.log('transactions error ' + JSON.stringify(error));
    }

  }

  
  updateAggComparationByType() {
    if(!this.hasAccess("transaction") || !this.localDateFilter.valid )
    return;
let dateRange = {
      from: moment(this.localDateFilter.get('start')?.value).toDate(), 
      to: moment(this.localDateFilter.get('end')?.value).toDate()
  };
  let previousDateTo = this.queries.getPreviousDateRange(dateRange).to!
    this.servicios.sendAggrTypeByDay(this.queries.createCompareTypeBreakout(dateRange,this.domainCache), 
    previousDateTo).then(
      value => 
          {
            this.aggCmpTypeDaily = {...value};
            for (let i=0; i< this.aggCmpTypeDaily.labels.length; i++) {
              this.aggCmpTypeDaily.labels[i] = this.commonService.getClientType(this.aggCmpTypeDaily.labels[i]);
            }
          }
      );
  }

  public async setDataRefresh() {   
     
    this.dashboardTimer = 
          timer(1000, Feedbacks.dashboardUpdate).subscribe((t) => {
            this.update()
      });

      this.update();
  }


update(){
  
  this.domainCache = this.utilServer.cargarCacheDomain();

  this.updateIndicators();
   

    if ((this.matExpansionPanelElement2 != undefined) && (this.matExpansionPanelElement2.expanded)) {
      this.openGroup2 ()      
    }
    if ((this.matExpansionPanelElement3 != undefined) && (this.matExpansionPanelElement3.expanded)) {
      this.openGroup3();
    }
    if ((this.matExpansionPanelElement1 != undefined) && (this.matExpansionPanelElement1.expanded)) {
      this.openGroup1 ()
    } 
    if ((this.matExpansionPanelElement4 != undefined) && (this.matExpansionPanelElement4.expanded)) {
       this.openGroup4 ()
    }
}


  public openGroup1 () {
    this.updateAggByHour();
    this.updateAggDailyAmount();
  }

  public openGroup2 () {
    this.updateTransactionsByType();
  }

  public openGroup3 () {
    this.updateModels();
    this.updateTopMerchants();
    this.updateAggByHour();

  }

  public openGroup4 () {
    this.updateAggComparationByType();
  }

  public hasAccess(item:string): boolean  {
    if (this.authService.hasValidAccessToken() && this.authService.hasValidIdToken()) {
      try {
        var tk = this.authService.getUserAccessToken();
        var permissions = tk!.auth!.permissions!['merchantsApi'];
        var permission : string = permissions[item];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

}
