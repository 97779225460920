<main id="main" class="main">

  
  <div class="pagetitle">
    <h1 i18n>{{"Organizations" | translate }}</h1>

    <div>
      <button *ngIf="commonService.checkItemRights('organization','W')" mat-mini-fab (click)="onNew()">
        <i class="bi bi-plus-lg"></i>
      </button>

      <button *ngIf="!commonService.checkItemRights('organization','W')" mat-mini-fab (click)="refrescar()">
        <i class="bi bi-arrow-clockwise"></i>
      </button>

    </div>
  </div>



  <div class="mat-elevation-z8 tablaPersonal">
    <table mat-table [dataSource]="dataSource" matSort multiTemplateDataRows (matSortChange)="announceSortChange($event)" 
    matTableExporter  #exporter="matTableExporter">

      <!-- name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header i18n>{{"Name" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.name}} </td>
      </ng-container>


      <!-- domainRoot Column -->
      <ng-container matColumnDef="domainRoot">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header i18n>{{"Domain" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <input matInput class="form-field" [formControl]="domainRootFilter" placeholder="">
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.domainRoot}} </td>
      </ng-container>


            <!-- routing Column -->
            <ng-container matColumnDef="properties_routing">
              <th mat-header-cell *matHeaderCellDef>
                <div mat-sort-header i18n>{{"Routing" | translate }}</div>
                <div>
                  <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="routingFilter" placeholder="">
                  </mat-form-field>
                </div>
              </th>
              <td mat-cell *matCellDef="let element"> {{element?.properties?.routing}} </td>
            </ng-container>


      <!-- status Column -->
      <ng-container matColumnDef="status">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header i18n>{{"Status" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <input matInput class="form-field" [formControl]="statusFilter" placeholder="">
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.status}} </td>
      </ng-container>


      <!-- createdAt Column -->
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef>
          <div mat-sort-header i18n>{{"Created" | translate }}</div>
          <div>
            <mat-form-field>
              <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
              <mat-label></mat-label>
              <mat-date-range-input [formGroup]="createdAtFilter" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
                <input matEndDate formControlName="end" placeholder="{{'End Date' | translate }}">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'MM/dd/yy, HH:mm:ss'}}</td>
      </ng-container>




      <ng-container matColumnDef="view">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" class="button">
          <div>
            <button *ngIf="commonService.checkItemRights('organization','W')" matTooltip="{{'edit' | translate }}"
              (click)="onEdit(element)" mat-icon-button color="primary">
              <i class="bi bi-pencil"></i>
            </button>

            <button *ngIf="commonService.checkItemRights('organization','D')" matTooltip="{{'delete' | translate }}"
              (click)="onDelete(element)" mat-icon-button color="primary">
              <i class="bi bi-trash"></i>
            </button>
          </div>
        </td>
      </ng-container>





      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
     
    <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i class="bi bi-file-earmark-arrow-down"></i> </button>
     
    <!--<button id="export" class="icoDownload" mat-icon-button (click)="exporter.exportTable('xlsx', {fileName:'Organizations'})"><i class="bi bi-file-earmark-arrow-down"></i>
    </button>-->

    <mat-paginator [length]="pageLength" [pageSize]="pageSize" [pageSizeOptions]="pageSizeOptions"
      (page)="pageEvent($event)" showFirstLastButtons aria-label="Select page of periodic elements">
    </mat-paginator>
  </div>
</main>