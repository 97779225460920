<mat-form-field
	[appearance]="appearance"
	[floatLabel]="floatLabel"
	class="numeric-range-field"
>
	<mat-label>{{ label }}</mat-label>
	<app-ngx-numeric-range-form-field-control
		id="numeric-range-form-control"
		[formControl]="control"
		[minPlaceholder]="minPlaceholder"
		[maxPlaceholder]="maxPlaceholder"
		[readonly]="readonly"
		[minReadonly]="minReadonly"
		[maxReadonly]="maxReadonly"
		[required]="required"
		(blurred)="onBlur()"
		(enterPressed)="onEnterPressed()"
		(numericRangeChanged)="onRangeValuesChanged($event)"
		[dynamicSyncValidators]="dynamicSyncValidators"
	></app-ngx-numeric-range-form-field-control>

	<mat-icon
		(click)="onReset()"
		*ngIf="
			resettable &&
			!readonly &&
			!minReadonly &&
			!maxReadonly &&
			(minimumControl.value !== null || maximumControl.value !== null) &&
			!formGroup.disabled
		"
		color="primary"
		class="pointer"
		matSuffix
		>close
	</mat-icon>

	<mat-error
		*ngIf="
			minimumControl.hasError('required') || maximumControl.hasError('required')
		"
	>
		{{ requiredErrorMessage }}
	</mat-error>

	<mat-error
		*ngIf="minimumControl.hasError('min') || maximumControl.hasError('min')"
	>
		{{ minimumErrorMessage }}
	</mat-error>

	<mat-error
		*ngIf="minimumControl.hasError('max') || maximumControl.hasError('max')"
	>
		{{ maximumErrorMessage }}
	</mat-error>

	<mat-error
		*ngIf="
			formGroup.hasError('notValidRange') &&
			!minimumControl.errors &&
			!maximumControl.errors
		"
	>
		{{ invalidRangeErrorMessage }}
	</mat-error>
</mat-form-field>
