import { REFRESH_DASHBOARD, SERVER_URL_AUTH, SERVER_URL_MERCHANT, SERVER_URL_XIIBERO, SERVER_URL_XIIBERO_PLATFORM } from 'src/environments/environment';


export const RegexpValidators = {
  number: /^\d*$/,
  // Passwords should be at least 8 characters long and should contain one number, one character and one special character.
  password: /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/,
};

export const Feedbacks = {
  position: 'toast-bottom-full-width' as const,
  timeoutShort: 2500 as const,
  timeoutLong: 8000 as const,
  dashboardUpdate: REFRESH_DASHBOARD
};


export const HttpTimeouts = {
  default: 180000,
  short: 20000,
  long: 600000,
};

export const Separator = ","


export const MerchantRestUrl = SERVER_URL_MERCHANT;
export const XiiberoRestUrl = SERVER_URL_XIIBERO;
export const XiiberoPlatformRestUrl = SERVER_URL_XIIBERO_PLATFORM;
export const AuthRestUrl = SERVER_URL_AUTH;




export enum FormCreateActions {
  ERROR = 2,
  SAVED = 1,
  EXIT = 0
}


export const enum ENTORNO {
  XIIBERO=0,
  GETNET=1
}



export enum TypeJerarquia {   ORGANIZATION , REGION , DIVISION, MERCHANT, SUBSIDIARY }
