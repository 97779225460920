import { formatDate } from '@angular/common';
import { Component, OnInit, ElementRef, Input, AfterViewInit, OnChanges } from '@angular/core';
import { Chart } from 'chart.js';
import { NAMED_COLORS, CHART_COLORS } from '../../util/chartsutils';
import { TranslateService } from '@ngx-translate/core';

export interface chartPayload {
  labels: string[];
  values: number[];
}

@Component({
  selector: 'app-chart-tx-compare',
  templateUrl: './chart-tx-compare.component.html',
  styleUrls: ['./chart-tx-compare.component.css']
})
export class ChartTxDailyCompareComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() sequence!: string;
  @Input() graphCurrencies!: string[];
  @Input() chartTitle !: string;
  @Input() dates!: Date[];
  @Input() values!: Map<string, number[]>;
  @Input() values2!: Map<string, number[]>;

  chart: any;
  data : any;
  salesSelectedCurrency : string = 'EUR';

  constructor(private translate: TranslateService ) { }

  ngOnInit(): void {
  }
 
  ngAfterViewInit(): void {
    this.data = {
      labels: [], //this.chartDataset.labels,
      datasets: [
        {
          label: this.chartTitle,
          data: [], //this.chartDataset.values,
          backgroundColor: Object.values(CHART_COLORS),
        }
      ],
    };
    this.createChart();
  }

  public selectChangeCurrency (event: any, item: string) {
    this.salesSelectedCurrency  = item;    
    this.ngOnChanges();      
  }

  ngOnChanges() {
    this.translate
      .get([
        'Previous',
        'Current'
      ])      
      .subscribe(translation => {
        this.changeChart(translation)
      })
    }

  changeChart(translation :any) {
    if (!(this.chart == undefined)) {
      this.chart.data.labels!.length = 0; // this.chartDataset.labels;
      this.chart.data.datasets!.length = 0;
      for (let i=0; i<this.dates.length; i++) {
        this.chart.data.labels.push( formatDate(this.dates[i], 'yyyy-MM-dd', 'en'));
      }

      if (this.dates.length > 0) {
        var index = 0;
        if ((this.values2 != undefined) && (this.values2!.size > 0)) {
          for (let entry of this.values2.entries()) {
            if (entry[0]==this.salesSelectedCurrency) {
              var v : number[] = new Array<number>();
              for (let i=0; i<this.dates.length; i++) {
                v.push(entry[1][i]);
              }
              this.chart.data.datasets!.push({ label : translation.Previous + " " + entry[0], data : v, stack: 'Stack 1', backgroundColor: NAMED_COLORS[index]});
            }
            index = index + 1;
          }
        }
        index = 0;
        for (let entry of this.values.entries()) {
          if (entry[0]==this.salesSelectedCurrency) {
            var v : number[] = new Array<number>();
            for (let i=0; i<this.dates.length; i++) {
              v.push(entry[1][i]);
            }
            this.chart.data.datasets!.push({ label : translation.Current + " " + entry[0], data : v, stack: 'Stack 0', backgroundColor: NAMED_COLORS[index+4]});
          }
          index = index + 1;
        }
      }
    }
    this.chart?.update();
  }

  createChart(){
    var str : string = 'ChartCmpTx-'+this.sequence;
    this.chart = new Chart(str, {
      type: 'bar',
      data: this.data,
      options: {
        plugins: {
          title: {
            display: true,
            text: this.chartTitle
          },
        },
        responsive: true,
        interaction: {
          intersect: false,
        },
        scales: {
          x: {
            stacked: true,
          },
          y: {
            stacked: true
          }
        }
      }    
    });
  }
}
