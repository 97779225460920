import { OAuthModuleConfig } from 'angular-oauth2-oidc';
import { AuthRestUrl, MerchantRestUrl, XiiberoRestUrl } from '../util/constants';

export const authModuleConfig: OAuthModuleConfig = {
  resourceServer: {
    allowedUrls: [
      AuthRestUrl,
      MerchantRestUrl,
      XiiberoRestUrl,
    ],
    sendAccessToken: true,
  }
};
