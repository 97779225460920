import { Component, OnInit, ElementRef, Input, AfterViewInit, ViewChild, OnChanges } from '@angular/core';
import { NAMED_COLORS, CHART_COLORS } from '../../util/chartsutils';
import { TranslateService } from '@ngx-translate/core';

import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexPlotOptions,
  ApexYAxis,
  ApexLegend,
  ApexStroke,
  ApexXAxis,
  ApexFill,
  ApexTooltip
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  yaxis: ApexYAxis;
  xaxis: ApexXAxis;
  fill: ApexFill;
  tooltip: ApexTooltip;
  stroke: ApexStroke;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-compare-values',
  templateUrl: './chart-compare-values.component.html',
  styleUrls: ['./chart-compare-values.component.css']
})
export class ChartCompareValuesComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle !: string;
  @Input() labels!: string[];
  @Input() values!: number[];
  @Input() values2!: number[];

  public chartOptions!: Partial<ChartOptions> ;

  constructor(private translate: TranslateService ) { }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartCV', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
    this.chartOptions = {
      series: [
        { data: [] },
        { data: [] }
      ],
      chart: {
        type: "bar",
      },
      xaxis: {
        categories: []
      },
    };

    this.translate
      .get([
        'Previous',
        'Current',
        'operations'
      ])      
      .subscribe(translation => {
        this.createChart(translation);
      });
  }

  ngOnChanges() {
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.labels == undefined)) {
      this.chartOptions.series![0].data.length = 0;
      this.chartOptions.series![1].data.length = 0;
      this.chartOptions.xaxis!.categories!.length = 0;

      if (this.labels.length > 0) {
        if ((this.labels.length == this.values.length)&&(this.labels.length == this.values.length)) 
        {
          this.chartOptions.series![0].data = this.values2;
          this.chartOptions.series![1].data = this.values;
          this.chartOptions.xaxis!.categories = this.labels;
        }
        this.chart.updateOptions(this.chartOptions);
      }
    }
  }

  createChart(translation : any){    
    this.chartOptions = {
      series: [
        {
          name: translation.Previous,
          data: []
        },
        {
          name: translation.Current,
          data: []
        }
      ],
      chart: {
        type: "bar",
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "55%"
        }
      },
      dataLabels: {
        enabled: false
      },
      stroke: {
        show: true,
        width: 2,
        colors: ["transparent"]
      },
      xaxis: {
        categories: []
      },
      yaxis: {
        title: {
          text: translation.operations
        }
      },
      fill: {
        opacity: 1
      },
      tooltip: {
        y: {
          formatter: function(val) {
            return val +" " + translation.operations;
          }
        }
      }
    };    
  }

}
