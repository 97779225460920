import { Injectable } from '@angular/core';
import {  Properties } from 'src/app/models/emailProperties.model';
import { Organization } from 'src/app/models/organization.model';
import {  RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class ProcessorService {


  constructor(private merchantRest : RestService) {}
  
  async find(_body: RequestFilter){
    let value = null;

    value = await this.merchantRest.commonRestCallXiibero(_body,'processor/',true,true)   
    .catch(err => {
      return null
    })
     
    return value;   
  }

  


/*
  async saveEditService(_body: Organization){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/save',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async updateEditService(_body: Organization) {
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/update',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
  
  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }*/

 
}