import { formatDate } from '@angular/common';RequestFilter
import { Injectable } from '@angular/core';
import { weekdays } from 'moment';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { Observable, Subject } from 'rxjs';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { Filter, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ServerResponse } from 'src/app/services/merchant/ServerResponse';
import { MerchantRestUrl } from 'src/app/util/constants';


@Injectable({
  providedIn: 'root',
})
export class DashboardService {
  constructor(private merchantRest : RestService) {}
  
  async sendQueryService(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'transactions/query',false,false)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async sendAggrTermService(_body: RequestFilter) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'terminal/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
               values.push(element.number);
          });
          var test = {
            labels :  new Array<string>(),
            values : new Array<number>()
          }          
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
      return retValue.toPromise();
  }

  async sendAggrTxService(_body: RequestFilter) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'transactions/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
               values.push(element.number);
          });
          var test = {
            labels :  new Array<string>(),
            values : new Array<number>()
          }          
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
      return retValue.toPromise();
  }
 
  
  
  async sendAggrTxAmountService(_body: RequestFilter, currencies: string[], interval:any) :  Promise<any> {
    var retValue = new Subject<any>();

    this.merchantRest.commonRestCallMerchant(_body,'transactions/aggregation',false,false).then(
      value => {

        let aggTxDaily = {
              dates : new Array<string>(),
              amounts : new Map<string,number[]>(),
              operations : new Map<string,number[]>(),
            }
          currencies.forEach(element => {
            aggTxDaily.amounts.set(element, new Array<number>());
            aggTxDaily.operations.set(element, new Array<number>());
          });
          let currentDate = new Date(interval.from);
          for (; currentDate <= interval.to; currentDate.setDate(currentDate.getDate() + 1)) {
            let key = formatDate(currentDate, 'yyyy-MM-dd', 'en');
            aggTxDaily.dates.push(key);
            currencies.forEach(element => {
              aggTxDaily.amounts.get(element)!.push(0);
              aggTxDaily.operations.get(element)!.push(0);
            });
          }

          value!.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
            let index = 0;
            if(aggTxDaily.dates.length>0){
              index = -1;
            }
            for (let ix=0; ix<aggTxDaily.dates.length; ix++)
            {
              if (aggTxDaily.dates[ix] == element.key) {
                index = ix;
              }

            }
            element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any}) => {
              if (currencies.includes(subitemCurrency.key)) {
                aggTxDaily.amounts.get(subitemCurrency.key)![index] = subitemCurrency.subAggregation.value;
                aggTxDaily.operations.get(subitemCurrency.key)![index] = subitemCurrency.number;
              }
            });
          });
          retValue.next(aggTxDaily);
          retValue.complete();
        })
      .catch(
          err => { console.log(err) 
      });              
      return retValue.toPromise();   
  }

  async sendAggrTypeByDay(_body: RequestFilter, limit: Date) :  Promise<any> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'transactions/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          var prevValues : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; subAggregation: any}) => {
            var prev : number = 0;
            var next : number = 0;
            element.subAggregation.buckets.forEach((subitemCurrency: { key: string; number: number; subAggregation: any}) => {
              if (new Date(subitemCurrency.key) >= limit){
                next += subitemCurrency.number;
              } else {
                prev += subitemCurrency.number;
              }
            });
            keys.push(element.key);
            values.push(next);
            prevValues.push(prev);
          });
          var ret = {
            labels :  keys,
            values : values,
            prevValues : prevValues
          }          
          retValue.next(ret);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
      return retValue.toPromise();
  }

}
