import { Component, Inject } from '@angular/core';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';

@Component({
  selector: 'app-tabs-groups',
  templateUrl: './tabs-groups.component.html',
  styleUrls: ['./tabs-groups.component.css']
})
export class TabsGroupsComponent {


  disableAddMember = false;
  group: any;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

  ngOnInit() {
    this.group = this.data;
    if(this.group === null || this.group === undefined || Object.keys(this.group).length === 0){
      this.disableAddMember = true;
    }

  }

}
