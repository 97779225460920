import { Component, Inject, OnInit } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder,  FormControl,  FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Organization,  OrganizationProcessors,  OrganizationProperties, OrganizationTemplate } from 'src/app/models/organization.model';
import { Processor } from 'src/app/models/processor.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions } from 'src/app/util/constants';
import { ProcessorService } from '../../processors/processor.service';
import { ObjHasMap } from 'src/app/models/objHasMap.model';
import { MatSlideToggleChange } from '@angular/material/slide-toggle';
import { MatLegacySlideToggleChange } from '@angular/material/legacy-slide-toggle';

@Component({
  selector: 'app-form-org',
  templateUrl: './form-org.component.html',
  styleUrls: ['./form-org.component.css']
})
export class FormOrgComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado:boolean = false;
  verProcessor:boolean = false;
  
  displayedColumns = ['userId', 'title']

  objBBDD:Organization | undefined;
  
  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  
  listEnrutado = ['DIRECT_ORG', 'DIRECT_MERCHANT']  
  listprocessor: Processor[] = []
  listPropiedadesSession: any[] | undefined = []

  selectedEnrutado="";
  selectedProcessor="";

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: OrganizationsService,
    private processorServices: ProcessorService,
    public dialogRef: MatDialogRef<FormOrgComponent>,
    public dialog: MatDialog) { 
     
  }

  async cargar(){
    let rfProces: RequestFilter = new RequestFilter(null, 0, 100, "name,desc");
    let srProces = await this.processorServices.find(rfProces);
    if (srProces) {
      this.listprocessor = srProces.content;    
    }

  }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({
      name: ["", Validators.compose([
          Validators.required,
          Validators.minLength(4),
          Validators.maxLength(50),
          Validators.pattern("^(?=[a-zA-Z0-9._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])]      ,
      enrutado: ["", Validators.compose([Validators.required])]      ,
      arreglo:  this.formBuilder.array([]),
      processor: [""],    
      org_Reg: [false], 
      org_Div: [false], 
      org_Sub: [false],
      domainRoot: ["",Validators.compose([
          Validators.required,
          Validators.maxLength(255), 
          //Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")
        ])]
     
    });

    await this.cargar();

console.log(JSON.stringify(this.data))
    let nombre,  domainRoot,  enrutado,  processor;
    let isRegion,isDivision, isSubsidiary;
    if(this.data && this.data.obj){
      //si entra es EDICION
      this.desactivado=true;
      
      
      this.objBBDD = this.data.obj;
      if(this.objBBDD){
        nombre=this.objBBDD.name;
        domainRoot=this.objBBDD.domainRoot;
        enrutado=this.objBBDD.properties?.routing;
       // alert(JSON.stringify(this.objBBDD))

       this.listPropiedadesSession=this.objBBDD.processors
       
        if (this.objBBDD.processors && this.objBBDD.processors.length > 0) {
          let tempShortName = this.objBBDD.processors[0].short
          this.listprocessor.forEach(element => {
            if (element.shortName == tempShortName) {
              processor = element
            };
          });
        }

        if (this.objBBDD.template) {
            isRegion= this.objBBDD.template.hasRegions
            isDivision= this.objBBDD.template.hasDelegations
            isSubsidiary= this.objBBDD.template.hasSubsidiary
        }


        if(enrutado && (enrutado =='DIRECT_ORG' || enrutado =='DIRECT_MERCHANT')) {
          this.verProcessor=true;
        } else {
          this.verProcessor=false;
        }


      
        this.registerForm = this.formBuilder.group({
          name: [nombre, Validators.compose([Validators.required])]      ,
          domainRoot: [domainRoot, Validators.compose([Validators.required])]   ,
          enrutado: [enrutado, Validators.compose([Validators.required])]  ,
          processor: [processor, Validators.compose([Validators.required])],         
          org_Reg: [isRegion], 
          org_Div: [isDivision], 
          org_Sub: [isSubsidiary],
          arreglo:  this.formBuilder.array([]),
        });

      }
    }


    this.registerForm.get('enrutado')?.valueChanges.subscribe(value => {
          if(value =='DIRECT_ORG' || value =='DIRECT_MERCHANT' ) {
            this.verProcessor=true;
          } else {
            this.verProcessor=false;
          }
        }
    );

    this.inicialializarLista();

  }


  inicialializarLista(): void {
    const arreglo = this.registerForm.get('arreglo') as FormArray;
console.log(">>>>>>>>>>>>>"+JSON.stringify(this.listPropiedadesSession))
    let fb = this.formBuilder
    if(this.listPropiedadesSession){

      this.listPropiedadesSession.forEach(elementProper => { 
        let short = elementProper.short
        let estado = elementProper.active

        Object.keys(elementProper).forEach(function(key) {
            var value = elementProper[key];
            if(key!=="short" && key!=="active"){

              const grupo = fb.group({
                shortName:[short],
                llave: [key],
                valor: [value]
              })        
              arreglo.push(grupo);
            }
        });

        this.listprocessor.forEach(elementBBDD => {  
          if(elementBBDD.shortName===short){
            elementBBDD.active=estado;
          }
        });

       
        
      });
      
    }

   

  }



  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

  
    let objNew : Organization = new Organization();
    let objPropertiNew : OrganizationProperties = new OrganizationProperties();

    objNew.name=data['name']  
    objNew.domainRoot=data['domainRoot'] 
    
    objNew.organizationId = data['organizationID']  
    objNew.processors=this.procesarHasMap()

    
    let tempEnrutado = data['enrutado']  
    objPropertiNew.routing= tempEnrutado    
    objPropertiNew.partialAuthorization= true
    objNew.properties= objPropertiNew

    let template: OrganizationTemplate = new OrganizationTemplate
    let hasRegions = data['org_Reg'] 
    let hasDelegations = data['org_Div']  
    let hasSubsidiary = data['org_Sub']   

    template.hasMerchants=true
    template.hasRegions=hasRegions
    template.hasDelegations=hasDelegations
    template.hasSubsidiary=hasSubsidiary

    objNew.template=template;

   // alert(JSON.stringify(objNew))
    //alert("tempEnrutado:"+tempEnrutado+ "    tempProcessor:"+tempProcessor)

   
    

    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Create Organization`;
    dialogData.textI18n = $localize `Are you sure you want to create it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');

          if(this.objBBDD){
            //actualizamos los campos editables
            this.objBBDD.name= objNew.name;
            this.objBBDD.domainRoot= objNew.domainRoot;
            this.objBBDD.properties= objPropertiNew;
            this.objBBDD.processors= objNew.processors;
            this.objBBDD.template= template;
            
            await this.services.updateEditService(this.objBBDD)
          }else{
            await this.services.saveEditService(objNew)
          }
          
          
          this.dialogRef.close(FormCreateActions.SAVED);

      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }



  
  procesarHasMap(): any[] {
    let listProcesor: OrganizationProcessors[] = []; 
    let i=0;
    this.listprocessor.forEach(element => {
      let procesor: OrganizationProcessors = new OrganizationProcessors();
      procesor.short=element.shortName
      procesor.active=element.active
      listProcesor[i]=procesor
      i++;
    });
    
    const lines = this.registerForm.get('arreglo') as FormArray;   
    lines.controls.forEach((element, index) => {
        let llave = element.get('llave')?.value;
        let valor = element.get('valor')?.value;      
        let shortName = element.get('shortName')?.value;
        listProcesor.forEach(temp => {
          if(temp.short===shortName){
            let obj:ObjHasMap=new ObjHasMap
            obj.llave=llave
            obj.valor=valor
            if(valor && llave){
              temp.listaValores.push(obj)              
            }
          }
        });
       // console.log("llave:"+llave+" valor:"+valor+" short:"+shortName)
    });

    let lista:any[]=[]
    i=0;
    listProcesor.forEach(obj => {
      let myObj: {[index: string]:any} = {}  
      if(obj.active){
        myObj['active']=obj.active;
      }else{
        myObj['active']=false;
      }
      myObj['short']=obj.short;

      if(obj.listaValores && obj.listaValores.length>0){                        
          obj.listaValores.forEach(objT => {
            if(objT.llave){
                myObj[objT.llave]=objT.valor;
            }
          });
          lista[i]=myObj
          i++
        }
        
    });
    console.log("lista:"+JSON.stringify(lista))

    return lista;
  }

  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  activeProcesor(item:Processor,event: MatLegacySlideToggleChange): void {

    const data = this.applyFormats(this.registerForm);
    let tempEnrutado = data['enrutado']

    let onlyOne=false
    if(tempEnrutado && tempEnrutado =='DIRECT_ORG' ) {
      onlyOne=true
    }
         


    this.listprocessor.forEach(element => {
      if(onlyOne){
        element.active=false
      }

      if(element.shortName===item.shortName){
        item.active=event.checked
      }
    }); 

  }



  onAddLista(item:Processor): void {
    const arreglo = this.registerForm.get('arreglo') as FormArray;

    const grupo = this.formBuilder.group({
      shortName:[item.shortName],
      llave: [null],
      valor: [null]
    })

    arreglo.push(grupo);
  }

  borrarGrupo(i: number) {
    const arreglo = this.registerForm.get('arreglo') as FormArray;
    arreglo.removeAt(i);
 }

 get arregloFormGroups () {
  return this.registerForm.get('arreglo') as FormArray
}
 getshortNameArregloFormGroups (i: number) {
    const lines = this.registerForm.get('arreglo') as FormArray;   
    return lines.controls[i].get('shortName')?.value;
}



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

}




