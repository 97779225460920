<div>
   <apx-chart #chartDN
   [series]="chartOptions.series!"
   [chart]="chartOptions.chart!"
   [dataLabels]="chartOptions.dataLabels!"
   [plotOptions]="chartOptions.plotOptions!"
   [xaxis]="chartOptions.xaxis!"
   [stroke]="chartOptions.stroke!"
   [fill]="chartOptions.fill!"
   [yaxis]="chartOptions.yaxis!"
   [title]="chartOptions.title!"
   [tooltip]="chartOptions.tooltip!"
   [legend]="chartOptions.legend!"
  ></apx-chart>
 </div>
