
        <form [formGroup]="registerForm">
 
 
         <div class="row">
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                         <h5 i18n>{{"Name" | translate}} <span class="obligatorio">*</span></h5>
                         <input formControlName="name" matInput type="text" [readonly]="desactivado" required >
                         <mat-error *ngIf="registerForm.get('name')?.hasError('required')"  i18n>{{"Please enter a value!" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                         <mat-error *ngIf="registerForm.get('name')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                     </mat-form-field>
                 </div>
             </div>
             <div class="col-lg-6">
                <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Rol" | translate}} <span class="obligatorio">*</span></h5>                   
                        <mat-select formControlName="rolMarcado">
                            <mat-option *ngFor="let op of listaRol" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                        <mat-error *ngIf="registerForm.get('rolMarcado')?.hasError('required')"  i18n>{{"Field required!" | translate }}</mat-error>

                    </mat-form-field>
                 </div>
             </div>
         </div>
         <div class="row">
            <div class="col-lg-12">
               <div class="card cardCustos">
                        <h5 i18n>{{"Description" | translate}}</h5>
                        <input formControlName="description" matInput type="text">
                        <mat-error *ngIf="registerForm.get('description')?.hasError('pattern')"  i18n>{{"Invalid input format" | translate }}</mat-error>
                        <mat-error *ngIf="registerForm.get('description')?.hasError('maxLength')"  i18n>{{"Maximum length exceeded" | translate }}</mat-error>
                </div>
            </div>
        </div>
 
        <div class="row">
            <div class="col-lg-6">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Organization" | translate}}  <span class="obligatorio">*</span></h5>                   
                        <mat-select formControlName="orgMarcado" (selectionChange)="selectOrg($event.value)">
                            <mat-option *ngFor="let op of listaOrg" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>  
                        <mat-error *ngIf="registerForm.get('orgMarcado')?.hasError('required')"  i18n>{{"Field required!" | translate }}</mat-error>
                    </mat-form-field>                  
                </div>
            </div>
            <div class="col-lg-6">
               <div class="card cardCustos">
                    <mat-form-field ngDefaultControl>
                        <h5 i18n>{{"Merchant" | translate}}</h5>                   
                        <mat-select formControlName="merchMarcado">
                            <mat-option *ngFor="let op of listaMerchant" [value]="op">
                                {{op.name}}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </div>
        </div>


        <div class="row" *ngIf="desactivado">
            <div class="col-lg-12">
               <div class="card cardCustos">
                        <h5 i18n>{{"Usuarios" | translate}}</h5>
                        <ul>
                            <li *ngFor="let usu of listaTotalUser">
                            <!-- <li *ngFor="let hero of heroes" [class.selected]="hero === selectedHero" (click)="onSelect(hero)">-->                                   
                              {{ usu.firstName }}  {{ usu.lastName }}
                            </li>
                        </ul>
                </div>
            </div>
        </div>


       
       


 
        <div class="row botonera">
             <div class="col-lg-12">
                     <button  class="btn btn-aceptar" (click)="onFormSubmit()" i18n>{{"Submit" | translate}}</button> 
                     <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate}}</button>
             </div>
         </div>
 
 
       
     </form>
 
                 
                 
                 
                 
                 
                 
 
 