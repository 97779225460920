import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { Organization } from 'src/app/models/organization.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { SubsidiaryService } from '../subsidiary.service';
import { Subsidiary, SubsidiaryAddress, SubsidiaryInfo, SubsidiaryProcessors } from 'src/app/models/subsidiary.model';
import { Processor } from 'src/app/models/processor.model';
import { ProcessorService } from '../../processors/processor.service';
import { Region } from 'src/app/models/region.model';
import { Division } from 'src/app/models/division.model';
import { MerchantService } from '../../merchants/merchant.service';
import { RegionService } from '../../region/region.service';
import { DivisionService } from '../../division/division.service';
import { CommonService } from 'src/app/services/common.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import { Merchants } from 'src/app/models/merchants.model';
import { UtilService } from 'src/app/services/util.service';


@Component({
  selector: 'app-form-subsidiary',
  templateUrl: './form-subsidiary.component.html',
  styleUrls: ['./form-subsidiary.component.css']
})
export class FormSubsidiaryComponent implements OnInit {
  registerForm!: FormGroup;
  desactivado: boolean = false;
  verRegion:boolean = false;  
  verDivision:boolean = false;  

  listaOrg: Organization[] = [];
  listaRegion:Region[] = [];
  listaDivision:Division[] = [];
  listaMerchant:Merchants[] = [];

  selectedProc!: String
  selectedOrg: Organization = new Organization();
  selectedRegion: Region = new Region();
  selectedDivision: Division = new Division();
  selectedMerchant: Merchants = new Merchants();

  objBBDD: Subsidiary = new Subsidiary();


  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: SubsidiaryService,
    private orgService: OrganizationsService,
    private commonService: UtilService,
    public dialogRef: MatDialogRef<FormSubsidiaryComponent>,
    public dialog: MatDialog) {

  }


  async cargar() {

      //organizaciones que permiten la creacion de SUBSIDIARY     
      let mapQuery = new Map();
      let filter = QueryFilterList.construirFilterComplet(mapQuery,"beq" ,"template.hasSubsidiary", "true");
   
    
    let rfOrg: RequestFilter = new RequestFilter(filter, 0, 100, "name,desc");
    let srOrg = await this.orgService.find(rfOrg,true);
    if (srOrg) {
      this.listaOrg = srOrg.content;
      if (this.listaOrg.length == 1) {
        // Si solo hay 1 ponemos por defecto valor
        this.selectedOrg = this.listaOrg[0];
      }
    } 

  }


  async ngOnInit(): Promise<void> {

    this.registerForm = this.formBuilder.group({
      name: ["", Validators.compose([Validators.required,
      Validators.minLength(4),
      Validators.maxLength(50),
      Validators.pattern("^(?=[a-zA-Z0-9\\s._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")])],
      location: ["", Validators.compose([
        Validators.maxLength(255),
        Validators.pattern("^[0-9a-zA-ZÀ-ÿ\\s\\Q.@[]{}()+_-,=:!?^$|/\\E]*$")])],
      orgMarcado: [null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchantMarcado: [null, Validators.compose([Validators.required])], 
      shortname: ["", Validators.compose([
        Validators.maxLength(5),
        Validators.pattern("^[a-zA-Z0-9]*$")
      ])], 
      country: [""],
      state: [""],
      city: [""],
      address: [""],
      postalCode: [""],
      phone: [""],
      email: ["", Validators.compose([
        Validators.email,
        Validators.maxLength(255),
        Validators.pattern("^[a-zA-Z0-9._%+\-]+@[a-zA-Z0-9.\-]+\.[a-zA-Z]{2,4}$")
      ])]
    });


    await this.cargar();

    let nombre, email, phone, merchantId, proces,shortname;
    let country, state, city, address, postalCode;
    let orgBBDD = null; 
    let regionBBDD=null;
    let divisionBBDD=null;
    let merchantBBDD=null;
    if (this.data && this.data.obj) {
      //si entra es EDICION
      this.desactivado = true;

      this.objBBDD = this.data.obj;


      // alert(JSON.stringify(this.objBBDD))
      if (this.objBBDD) {
        let hierarchy = this.objBBDD.hierarchy

        let dominioOrg = ""
        let dominioRegion=""
        let dominioDivision=""
        let dominioMerchant=""


        if(hierarchy){
          if(hierarchy.ORGANIZATION){
            dominioOrg = hierarchy.ORGANIZATION.domain
          }

          if(hierarchy.REGION){
            dominioRegion = hierarchy.REGION.domain

            let sr = await this.commonService.pedirRegiones(dominioOrg);     
            if(sr){
              this.listaRegion= sr.content;
            }
          }

          if(hierarchy.DIVISION){
            dominioDivision = hierarchy.DIVISION.domain

            let dominioBuscar = dominioOrg;
            if(dominioRegion){dominioBuscar = dominioRegion}
            let sr = await this.commonService.pedirDivision(dominioBuscar);   
            if(sr){
              this.listaDivision= sr.content;
            }
          }

          if(hierarchy.MERCHANT){
            dominioMerchant = hierarchy.MERCHANT.domain

            let dominioBuscar = dominioOrg;
            if(dominioRegion){dominioBuscar = dominioRegion}
            if(dominioDivision){dominioBuscar = dominioDivision}

             //traemos las  MECHARNT
             let sr = await this.commonService.pedirMerchant(dominioBuscar);   
             if(sr){
               this.listaMerchant= sr.content;
             }
          }
        }
    
       
     

        this.listaOrg.forEach(element => {
          if (element.domainRoot == dominioOrg) {
            orgBBDD = element
          };
        });

        this.listaRegion.forEach(element => {
          if(element.domain==dominioRegion){
              regionBBDD=element  
              this.verRegion=true              
            };
        });

        this.listaDivision.forEach(element => {
          if(element.domain==dominioDivision){
              divisionBBDD=element   
              this.verDivision=true             
            };
        });

        this.listaMerchant.forEach(element => {
          if(element.domain==dominioMerchant){
              merchantBBDD=element                
            };
        });

      

        if (this.objBBDD.info) {
          email = this.objBBDD.info.email;
          phone = this.objBBDD.info.phone;

          if (this.objBBDD.info.address) {
            country = this.objBBDD.info.address.country
            state = this.objBBDD.info.address.state
            city = this.objBBDD.info.address.city
            address = this.objBBDD.info.address.addressLine1
            postalCode = this.objBBDD.info.address.postalOrZipCode
          }
        }
        nombre = this.objBBDD.name;
        shortname=this.objBBDD.merchantId;
      }

      this.registerForm = this.formBuilder.group({
        name: [nombre, Validators.compose([Validators.required])],
        country: [country],  
        shortname  : [{value: shortname, disabled: true}],
        state: [state],
        city: [city],
        address: [address],
        postalCode: [postalCode],
        email: [email],
        phone: [phone],
        regionMarcado: [regionBBDD],
        divisionMarcado: [divisionBBDD],
        merchantMarcado: [merchantBBDD, Validators.compose([Validators.required])], 
        orgMarcado: [orgBBDD, Validators.compose([Validators.required])],

      });

    }// if (this.objBBDD) {
  }//if (this.data && this.data.obj) {




  async organizationFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 
    this.selectedOrg = org;

    let mostrarRegion=false
    let mostrarDivision=false

    if(org && org.template){

      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)
      

      if(tieneRegion){    mostrarRegion=true  }
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneRegion){
        //traemos las REGIONES
              let sr = await this.commonService.pedirRegiones(org.domainRoot);     
              if(sr){
                this.listaRegion= sr.content;
              }
      }else if(tieneDivision){
         //traemos las DIVISIONES
            let sr = await this.commonService.pedirDivision(org.domainRoot);   
            if(sr){
              this.listaDivision= sr.content;
            }
      } else{
          //traemos las  MECHARNT
            let sr = await this.commonService.pedirMerchant(org.domainRoot);   
            if(sr){
              this.listaMerchant= sr.content;
            }
      } 
    }// if(org.template){

    this.verDivision=mostrarDivision
    this.verRegion=mostrarRegion

  }

 

  async regionFilter(ob:MatSelectChange) {
    let reg:Region
    reg=ob.value; 

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain);   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain);   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }


  async divisionFilter(ob:MatSelectChange) {
    let reg:Division
    reg=ob.value; 

    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain);   
         if(sr){
           this.listaMerchant= sr.content;
         }      
    }

  }


  onFormSubmit(): void {
    console.log(this.registerForm);

    if (this.registerForm.invalid) {
      return;
    }
    const data = this.applyFormats(this.registerForm);



    let objNew: Subsidiary = new Subsidiary();
    if (this.objBBDD.id) {
      objNew = this.objBBDD
    }

    objNew.name = data['name']
    objNew.merchantId=data['shortname'] 

    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot
    
    let regionNew:Region
    regionNew=data['regionMarcado']
    if(regionNew){
      domainRoot=regionNew.domain
    }
    
    let divisionNew:Division
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchantMarcado']
    if(merchantNew){
      domainRoot=merchantNew.domain
    }

    objNew.domain=domainRoot

    let tempCountry = data['country']
    let tempState = data['state']
    let tempCity = data['city']
    let tempAddress = data['address']
    let tempPostalCode = data['postalCode']
    let tempEmail = data['email']
    let tempphonP = data['phone']



    let properties: SubsidiaryInfo = new SubsidiaryInfo();
    if (tempEmail || tempphonP) {
      properties.email = tempEmail;
      properties.phone = tempphonP;
    }

    if (tempCountry || tempState || tempCity || tempAddress) {
      let address: SubsidiaryAddress = new SubsidiaryAddress();
      address.country = tempCountry;
      address.state = tempState;
      address.city = tempCity;
      address.addressLine1 = tempAddress;
      address.postalOrZipCode = tempPostalCode;
      properties.address = address;
    }

    objNew.info = properties;
    objNew.type = "SUBSIDIARY";

    //alert("NUEVO="+JSON.stringify(objNew))
    //alert("BBDD="+JSON.stringify(this.objBBDD))


    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize`Create Subsidiary`;
    dialogData.textI18n = $localize`Are you sure you want to create it?`;


    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {

        console.log('Form SAVE action');
        let val:any;
        if (objNew.id) {
          val = await this.services.updateEditService(objNew)
        } else {
          val = await this.services.saveEditService(objNew)
        }

        if(val){
          this.dialogRef.close(FormCreateActions.SAVED);
        }
      } else if (result === ConfirmDialogActions.CANCEL) {

      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body: any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }









}




