import { Organization } from "../organization.model";

export class DataExport_Organization_Xiibero {

  name: string | undefined; 
  domainRoot: string | undefined;
  routing: string | undefined;
  status: string | undefined;
  created: string | undefined;


  constructor(values: Organization = new Organization) {
    this.name= values.name
    this.domainRoot= values.domainRoot
    this.routing= values.properties?.routing
    this.status= values.status
    this.created= values.createdAt
  }


}


