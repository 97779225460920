
import { AfterViewInit, Component, Inject, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup,  } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { QueryAggregationList, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Transaction } from 'src/app/models/transaction.model';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import { TransactionsService } from './transactions.service';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';
import {MatLegacyRadioModule as MatRadioModule, MatLegacyRadioChange as MatRadioChange} from '@angular/material/legacy-radio';
import { INumericRange } from 'src/app/components/numeric-range/form/model/numeric-range-field.model';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { Ticks } from 'chart.js';
import * as XLSX from "xlsx";
import { Ticket } from 'src/app/models/ticket.model';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { ENTORNO, FormCreateActions } from 'src/app/util/constants';
import { DataExport_Transaction } from 'src/app/models/dataExport/dataExport_transaction.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';
import { ReferencedDialogComponent } from 'src/app/pages/views/transaction/referenced-dialog/referenced-dialog.component';
import { ConfirmDialogActions } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import * as moment from 'moment-timezone';
import { TranslateService } from '@ngx-translate/core';

enum Columns_xiibero {  
  expand = 'expand',
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  terminal_terminalId = 'terminal.terminalId', 
  date = 'date',
  trx_amount = 'trx.amount', 
  trx_currency = 'trx.currency', 
  type = 'type',
  status = 'status',
  card_cardType = 'card.cardType',
  card_entry = 'card.entry',
  card_cardInfoObfuscated = 'card.cardInfoObfuscated',
  //trx_transactionId = 'trx.transactionId',
  trx_ref = 'trx.ref',
  trx_authcode = 'trx.authcode',
  button='button',
}
enum Columns_getnet {
  expand = 'expand',
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  terminal_terminalId = 'terminal.terminalId', 
  date = 'date',
  trx_amount = 'trx.amount', 
  trx_currency = 'trx.currency', 
  type = 'type',
  status = 'status',
  card_cardType = 'card.cardType',
  card_entry = 'card.entry',
  card_cardInfoObfuscated = 'card.cardInfoObfuscated',
  trx_transactionId = 'trx.transactionId',
  //trx_ref = 'trx.ref',
  trx_authcode = 'trx.authcode',
  button='button',
}
let Columns: any

@Component({
  selector: 'app-transaction',
  templateUrl: './transaction.component.html',
  styleUrls: ['./transaction.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})
export class TransactionComponent implements OnInit, AfterViewInit {

  entorno = VIWER_ENTORNO;
  displayedColumns: any;
  
  expandedElement: Transaction | null = new Transaction;
  dataSource = new MatTableDataSource<Transaction>();
  
  isEntornoXiibero: boolean = false;
  isEntornoGetnet: boolean = false;

  graficaTransaction: boolean = false;
  tablaTransaction: boolean = true;
  isJustTransactions: boolean = false;
  
  verBotones: boolean = false;
  
  timeZoneOffset;
  timezones;
  allTimeZones:string[];
  customTimezoneEnabled: boolean = false;
///filtros
serialFilter = new FormControl();
modelFilter = new FormControl();




  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId=Columns_xiibero.date+",desc";//idColumna + "," + direccion

  localDateFilter = new FormGroup({
    start: new FormControl<Date | null>(null),
    end: new FormControl<Date | null>(null),
  });
  amountFilter= new FormControl(//{ minimum: 10,maximum: 20},  [ //Validators.required, //optional  Validators.min(10), //optional// Validators.max(100), //optional]
  )

  resultCodeSelect: string[] = ['',  'SUCCESS', 'DECLINED', 'ERROR'];
  resultEntrySelect: string[] = ['',  'CTLESS', 'ICC', 'KEYED', 'MOBILE_CTLESS', 'MSD', 'SWIPED'];
  cardBrandSelect: string[] = ['', 'VISA', 'MASTERCARD', 'AMEX', 'DISCOVER', 'DISCOVER_ZIP', 'DINERS',
  'CARTE_BLANCHE', 'JCB', 'ENROUTE', 'JAL', 'MAESTRO',  'DELTA', 'VISA_ELECTRON', 'DANKORT', 'CARTES_BANCAIERES',  'CARTA_SI', 'UATP', 'MAESTRO_INT', 'HIPERCARD', 
  'AURA', 'ELO', 'CHINA_UNION_PAY', 'SERVIRED', 'VISA_PAY', 'MAESTRO_UK', 'UNION_PAY_DEBIT', 'UNION_PAY_CREDIT', 'UNION_PAY_QUASYCREDIT',  'MULTIBANCO', 'UNKNOWN'];
  transactionTypeSelect: string[] = ['',  'PAYMENT', 'REFUND', 'CREDIT', 'REVERSAL', 
  'PREAUTH', 'CONFIRM', 'TOPUP',  'MANUAL_VOID', 'DUPLICATE', 'VOID', 'DIGITIZED_SIGNATURE', 'TOTALS', 'SETTLEMENT', 'TRANSACTIONS_DETAIL',  'COMMUNICATION_TEST', 'OTHERS'];
  

  authorizationIdFilter = new FormControl();
  transactionIdFilter = new FormControl();
  terminalIdFilter = new FormControl();
  merchantIdFilter = new FormControl();
  merchantFilter = new FormControl();
  panFilter = new FormControl();
  currencyFilter= new FormControl();
  refFilter= new FormControl();

  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild('selectZones') selectZones!:HTMLSelectElement; 

  mapQuery = new Map();
  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  graph1Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph2Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph3Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph4Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph5Data : ChartPayload = {
    labels :   [],
    values : []
  }
    
  jerarquiaSearchSbscription!: Subscription;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any | null,
  public dialog: MatDialog,
  public authServer: AuthService,
  public utilService: UtilService,
  public dialogRef: MatDialogRef<TransactionComponent>,
  public dialogInput: MatDialogRef<ReferencedDialogComponent>,    
  public radioButton: MatRadioModule,
  private servicios : TransactionsService,
  private translate : TranslateService) {  

    

    if(this.entorno==ENTORNO.XIIBERO){
      this.isEntornoXiibero= true;
      Columns=Columns_xiibero
    }else{
      this.isEntornoGetnet = true;
      Columns=Columns_getnet
    }
    this.displayedColumns  =  Object.keys(Columns);

    this.allTimeZones=moment.tz.names();
    this.timeZoneOffset= this.calculateZoneOffset();    
    let userTimeZone = this.userTimeZone();
    let selectedTimeZone = this.calculateZoneOffset(false);
    this.timezones = [{name : "UTC", value: "UTC", checked : this.timeZoneOffset === "UTC"}];    
    this.timezones.push({name : userTimeZone, value: userTimeZone, checked : selectedTimeZone === userTimeZone});
    this.customTimezoneEnabled = selectedTimeZone !== userTimeZone && this.timeZoneOffset !== "UTC";
    this.timezones.push({name: translate.instant("Custom"), value : this.timeZoneOffset === "UTC"? userTimeZone:this.timeZoneOffset, checked : this.customTimezoneEnabled});  
    
    
    this.isJustTransactions = false;
    if(data && data.obj && data.obj.serialnumber){
          //solo mostramos las transacciones del Terminal concreto que viene
          this.verBotones=true

          const idColumn_serialnumber = "terminal.serialnumber";
          let value_serialnumber = data.obj.serialnumber
          const idColumn_model = "terminal.model";
          let value_model = data.obj.model
          /*const idColumn_manufacturer = "terminal.manufacturer";
          let value_manufacturer = data.obj.manufacturer
          console.log(idColumn_serialnumber+"="+value_serialnumber+" ; " + idColumn_model+"="+value_model+" ; "+idColumn_manufacturer+"="+value_manufacturer+" ; ");   */
                   

          this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn_serialnumber, value_serialnumber);
          this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn_model, value_model);  
          //this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn_manufacturer, value_manufacturer);  
          
          
          this.lanzarLLamada(); 
    }
    if(data && data.split && data.split.terminal){
      //solo mostramos las transacciones del Terminal concreto que viene
      this.isJustTransactions = true;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "terminal.serialnumber", data.split.terminal.serialnumber);        
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "trx.splitPaymentId", data.split.trx.splitPaymentId);        
      this.lanzarLLamada();   
    }

  }


  verGrafica(){
      this.tablaTransaction=false
      this.graficaTransaction=true

      document.getElementById('icoVerGrafica')?.classList.add("botonMarcado");
      document.getElementById('icoVerTabla')?.classList.remove("botonMarcado");
      
      this.lanzarLLamada();
  }
  verTabla(){
      this.tablaTransaction=true
      this.graficaTransaction=false
      
      document.getElementById('icoVerTabla')?.classList.add("botonMarcado");
      document.getElementById('icoVerGrafica')?.classList.remove("botonMarcado");
  }

  cargarCache() {

    let value = this.utilService.cargarCacheDomain();
  
    if(value && value!="undefined"){
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
    }else{
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
    }
  
  
    this.lanzarLLamada();  
  }



  exportIt() {
    //personalizamos el exportar
    let newDate = new Date();
    let nombre = "transaction-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
    const fileName = nombre+".xlsx";

    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading = [['Merchant', 'Merchant Id', 'Terminal Id', 'Date', 'Amount', 'Currency', 'Type', 'Status', 'Card', 'Pan', 'transacion Id', 'AuthCode']];
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "Transaction");

    XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data:Transaction[]): any[]  {
    let result : DataExport_Transaction[] = [] ;
    data.forEach(row => {
      let tempRow:DataExport_Transaction = new DataExport_Transaction(row);
      result.push(tempRow);
    });
    return result;
  }




  public calculateZoneOffset(returnOffset : boolean = true) : string {
    if (this.isEntornoGetnet) {
      return this.calculateZoneOffsetGetnet();
    } else {
      //get the timezone from the 
      //Default value browser defined
      let timezone = moment.tz.guess();
      if (localStorage.getItem("transactionsTimeZone")) {   //Transactions section defined time zone
        timezone = localStorage.getItem("transactionsTimeZone") || timezone;
      } else if (localStorage.getItem("timezone")){  //User defined time zone
        timezone = this.userTimeZone();
      }
      if (returnOffset) {
        return this.calculateZoneOffsetFromText(timezone)
      } else {
        return timezone;
      }
    }    
  }

  public calculateZoneOffsetFromText(zone:string) : string {
    if (zone === "UTC") {
      return zone;
    } else {
      return moment.tz(zone).format('Z');
    }
  }

  public userTimeZone() : string {
    let timezone = moment.tz.guess();
    if (localStorage.getItem("timezone")){  //User defined time zone
      timezone = localStorage.getItem("timezone") || timezone;
    }
    return timezone;
  }

  private calculateZoneOffsetGetnet() : string {
    var zoneOffset = '+0000'
    var offset = (new Date().getTimezoneOffset())/60;

    if(offset > 0){ // positive timezone 
      zoneOffset = '+'+Math.abs(offset).toString().padStart(4,'0');
    } else if(offset < 0) { // negative timezone 
      zoneOffset = '-'+Math.abs(offset).toString().padStart(4,'0');
    }

    return zoneOffset;
  }


  updateTransactionsByType() {
    // Transaction by type
    let filter = {...this.rf};
      filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "type", "count");
      this.servicios.sendAggrService(filter, this.graph1Data).then(
        value => this.graph1Data = {...value});
  }

  updateTransactionsByBrand() {
    // Transaction by type
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.cardType", "count");
    this.servicios.sendAggrService(filter, this.graph2Data).then(
      value => this.graph2Data = {...value});
  }

  updateTransactionsByTech() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "card.entry", "count");
    this.servicios.sendAggrService(filter, this.graph3Data).then(
      value => this.graph3Data = {...value});
  }

  
  updateTransactionsByCurrency() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "trx.currency", "count");
    this.servicios.sendAggrService(filter, this.graph4Data).then(
      value => this.graph4Data = {...value});
  }

  updateTransactionsByStatus() {
    let filter = {...this.rf};
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "status", "count");
    this.servicios.sendAggrService(filter, this.graph5Data).then(
      value => this.graph5Data = {...value});
  }
  
  async lanzarLLamada(){
    let value = await this.servicios.sendService(this.rf);
    console.log(JSON.stringify(value))
    if(value){
      console.log(JSON.stringify(value.content))
      this.dataSource = new MatTableDataSource<Transaction>(value.content);
      this.pageLength=value.totalElements;
    }
    this.updateTransactionsByType();
    this.updateTransactionsByBrand();
    this.updateTransactionsByTech();
    this.updateTransactionsByCurrency();
    this.updateTransactionsByStatus();    
}

  ngOnInit(): void {
      this.dataSource.paginator = this.paginator;
      this.dataSource.sort = this.sort;

      
  
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


      this.authorizationIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns.trx_authcode;
          console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();      
      });
      this.transactionIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
        const idColumn = Columns.trx_transactionId;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();   
      });       
      this.merchantFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.merchant_name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();   
      });   
      this.merchantIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.merchant_merchantId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();   
      });   
      this.terminalIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.terminal_terminalId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();   
      });   
      this.panFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.card_cardInfoObfuscated;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();   
      });   
      this.localDateFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
        const idColumn = Columns.date;    
        console.log(idColumn+"->start="+value.start+"   end="+value.end);        
        this.rf.filter = QueryFilterList.construirRangoFechaFilter(this.mapQuery, idColumn, value.start,value.end);        
        this.lanzarLLamada();   
      });

      this.currencyFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
        const idColumn = Columns.trx_currency;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();    
      });
      this.refFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {        
        const idColumn = Columns.trx_ref;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();    
      });

      this.serialFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {      
        const idColumn = "terminal.serialnumber";
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada(); 
      }); 
      this.modelFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {      
        const idColumn = "terminal.model";
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();    
     
      }); 

      
  this.cargarCache()


  }




  resultEntrySelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = "card.entry";
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }    
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();
  }

  resultCodeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.status;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }    
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();
  }
  cardBrandSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.card_cardType;
    console.log(idColumn+"="+value);    
    if(value && value==""){
      value=null
    }  
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();
  }
  transactionTypeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.type;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();
  }

	onAmountFilterChange(value: INumericRange): void {
    //el evento no va bien, funciona mejor el obBlur
		console.log('Changed value: ', value);
    
	}
  onAmountFilterBlur(): void {
      let value:INumericRange
      value=this.amountFilter.value
      const idColumn = Columns.trx_amount;    
      console.log(idColumn+"->start="+value.minimum+"   end="+value.maximum);        
      this.rf.filter = QueryFilterList.construirRangoFilter(this.mapQuery, idColumn, value.minimum,value.maximum);        
      this.lanzarLLamada();   
	}
	



  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLLamada();
  }
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirOrder(this.rf.filter , this.pageSize,this.sortId);     
    this.lanzarLLamada();
  }





  async viewTicket(trx:Transaction){

      let ti:Ticket = new Ticket();
      ti.id=trx.id
      ti.type="RECEIPT"

      let valueBlob = await this.servicios.getTiket(ti);

      if(valueBlob){

      /* DESCARGA EL PDF
       let link = document.createElement('a');
        link.download = 'hello.pdf';
        link.href = URL.createObjectURL(valueBlob);
        link.click();
        URL.revokeObjectURL(link.href);*/

        //ABRE UNA PESTAÑA CON EL PDF
        var fileURL = URL.createObjectURL(valueBlob);
        window.open(fileURL);
      }

    }


    refrescar() {
      this.lanzarLLamada();
    }

    configure() {

    }
    
  viewTransaction(row: any) {

    this.dialogRef = this.dialog.open(TransactionComponent, {
      width: '95%', height:'80%' ,panelClass: 'custom-modalbox',
      data: {
        split: row
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

  async viewReferenced(trx:Transaction, type:string){
     
    this.dialogInput = this.dialog.open(ReferencedDialogComponent, {
      width: '500px', panelClass: 'custom-modalbox',
      data: {
        trx : trx,
        type: type
      }
    });

    this.dialogInput.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        //await this.servicios.deleteService(trx.id);
        console.log('CONFIRM recived from dialog window');
        this.lanzarLLamada();

      } else{
        console.log('CANCEL recived from dialog window');
      }
    });

  }

  haveTransactions(trx:Transaction) : Boolean {
    let ret =  !this.isJustTransactions && 
                trx.trx?.splitPaymentId !==null &&
                trx.trx?.splitPaymentId !==undefined;    
    return ret;
  }

  haveTicket(trx:Transaction) : Boolean {
    let ret =  trx.receiptInfo?.hasReceipt!==undefined && 
               trx.receiptInfo?.hasReceipt!==undefined &&
               trx.receiptInfo?.hasReceipt;    
    return ret;
  }

  canBeRefunded(trx:Transaction) : Boolean {    
    let ret =  trx.status=='SUCCESS' && 
           ( trx.type=== "PAYMENT" || trx.type === "CONFIRM");    

    //In case of the amount refunded is higher or equals to the transaction amount, the refund can't be done
    if ( trx.trx?.amount!==undefined && trx.trx?.amount!==null && 
      trx.trx?.amountRefunded!==undefined && trx.trx?.amountRefunded!==null && 
      trx.trx?.amount <= trx.trx?.amountRefunded){
        ret = false;
      }
    return ret;
  }

  canBeConfirmed(trx:Transaction) : Boolean {    
    let ret =  trx.status=='SUCCESS' && 
           ( trx.type=== "PREAUTH");            
    return ret;
  }

  timeZoneChange(event:MatRadioChange){
      if (event.source.id != this.translate.instant("Custom")) {
          localStorage.setItem("transactionsTimeZone", event.value);
          this.timeZoneOffset= this.calculateZoneOffset();
          this.customTimezoneEnabled = false;
      } else {
        this.customTimezoneEnabled = true;
        event.source.checked = true;
        this.selectZones
      }
  }

  customTimeZoneChange(zone:string) {
    localStorage.setItem("transactionsTimeZone", zone);
    this.timeZoneOffset= this.calculateZoneOffset();
  }  

}






