<main>
   <div class="container">


     <section *ngIf="isDefault" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <h1>{{"Comming Soon" | translate}}</h1>
         <h2>Merchant Portal</h2>
         <h2>{{"The page you are looking for will come soon." | translate}}</h2>
         <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found">
      </section>


      <section *ngIf="isXiibero" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <h1>{{"Comming Soon" | translate}}</h1>
         <h2>Xiibero</h2>
         <h2>{{"The page you are looking for will come soon." | translate}}</h2>
         <img src="assets/img/not-found.svg" class="img-fluid py-5" alt="Page Not Found">
      </section>


      <section *ngIf="isGetnet" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
            <img src="assets/themes/getnet/img/logo.png" class="img-fluid" alt="Page Not Found">
            <img src="assets/themes/getnet/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isUnicaja" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/unicaja/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/unicaja/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isIbercaja" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/ibercaja/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/ibercaja/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isEuronet" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/euronet/img/logo.png" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/euronet/img/welcome.png" class="img-fluid" alt="Page Not Found">
      </section>

      <section *ngIf="isIngenico" class="section error-404 min-vh-90 d-flex flex-column align-items-center justify-content-center">
         <img src="assets/themes/ingenico/img/logo.svg" class="img-fluid" alt="Page Not Found">
         <br>
         <img src="assets/themes/ingenico/img/welcome.jpg" class="img-fluid" alt="Page Not Found">
      </section>

 
   </div>
</main>
