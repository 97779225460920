import { ObjHasMap } from "./objHasMap.model";

export class Organization {
  id!: string;  
  name!: string; 
  organizationId!: string;
  properties: OrganizationProperties | undefined;
  processors: OrganizationProcessors[] | undefined;
  template: OrganizationTemplate | undefined;
  domainRoot!: string;
  status!: string;
  createdBy!: string;
  updatedBy!: string;
  createdAt!: string;
  updatedAt!: string;
}


export class OrganizationProperties {
    routing: string | undefined;
    processorDirectRouting:string | undefined;
    partialAuthorization:boolean | undefined;
    defaultManufacturer:string | undefined;
}

export class OrganizationTemplate {	
        hasRegions: boolean | undefined;
        hasDelegations:boolean | undefined;
        hasMerchants:boolean | undefined;
        hasSubsidiary:boolean | undefined;
}


export class OrganizationProcessors {
  short: string | undefined;
  active:boolean = false;
  listaValores: ObjHasMap[]=[];  
}