
import { AfterViewInit, Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { FormCreateActions, TypeJerarquia } from 'src/app/util/constants';
import { MerchantService } from 'src/app/pages/views/merchants/merchant.service';
import { OrganizationsService } from 'src/app/pages/views/organizations/organizations.service';
import { Organization } from 'src/app/models/organization.model';
import { Merchants } from 'src/app/models/merchants.model';
import { Order, OrderTerminals } from 'src/app/models/order.model';
import { OrderService } from '../order.service';
import { TMSProfileService } from '../tmsprofile.service';
import { TMSProfile } from 'src/app/models/tmsProfile.model';
import { Region } from 'src/app/models/region.model';
import { Division } from 'src/app/models/division.model';
import { UtilService } from 'src/app/services/util.service';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';


@Component({
  selector: 'app-form-new-orders',
  templateUrl: './form-new-orders.component.html',
  styleUrls: ['./form-new-orders.component.css']
})
export class FormNewOrdersComponent implements OnInit,AfterViewInit {
  
  verRegion:boolean = false;  
  verDivision:boolean = false;  

  registerForm!: FormGroup;

  listaOrg:Organization[] = [];
  listaRegion:Region[] = [];
  listaDivision:Division[] = [];
  listaMerchant:Merchants[] = [];
  listaTMSProfiles: TMSProfile[] = [];
  listFabricantes = ['Verifone']  
  listModelos = ['M400']
  

  selectedManufacture="";
  selectedModel="";
  selectedOrg:Organization = new Organization();
  selectedRegion: Region = new Region();
  selectedDivision: Division = new Division();
  selectedMerch:Merchants = new Merchants();
  selectedTMSProfile:TMSProfile = new TMSProfile();
  showTmsTemplate:boolean = false;
  

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private popupDialogRef!: MatDialogRef<ConfirmDialogComponent>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,
    private services: OrderService,
    private orgService: OrganizationsService,
    private merchService: MerchantService,
    private commonService: UtilService,
    private tmsProfileService: TMSProfileService,
    public dialogRef: MatDialogRef<FormNewOrdersComponent>,
    public dialog: MatDialog) { 
     
  }


  async ngAfterViewInit():  Promise<void>  {
      //ponemos por defecto valores
      this.selectedManufacture = this.listFabricantes[0];
      this.selectedModel = this.listModelos[0];
      if (this.listaTMSProfiles && this.listaTMSProfiles.length>0){
        this.selectedTMSProfile = this.listaTMSProfiles[0];
        this.showTmsTemplate = true;
      }
     
  }



  async cargar(){
   //organizaciones
    let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
    let srOrg = await this.orgService.find(rfOrg,true);     
    if(srOrg){
      this.listaOrg= srOrg.content;    
      if(this.listaOrg.length==1){
        // Si solo hay 1 ponemos por defecto valor
        this.selectedOrg = this.listaOrg[0];
      }
    }

    //Solo si hay una organización seleccionada
    if (this.selectedOrg?.id){     
      let rfTms: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srTms = await this.tmsProfileService.find(rfTms);
      if(srTms){
        this.listaTMSProfiles= srTms.content;   
        this.showTmsTemplate = true;
        if(this.listaTMSProfiles.length==1){
          // Si solo hay 1 ponemos por defecto valor
          this.selectedTMSProfile = this.listaTMSProfiles[0];
        }   
      } else {
        this.showTmsTemplate = false;
      }
    }
  }


  async ngOnInit(): Promise<void> {
    
    this.registerForm = this.formBuilder.group({
      manufacture: ["", Validators.compose([Validators.required])]      ,
      model: ["", Validators.compose([Validators.required ])]      ,
      numTer: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[0-9\\s\\-.]*$"))
      ])]  ,   
     
      orgMarcado:[null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchMarcado:[null, Validators.compose([Validators.required])],
      tmsProfileMarcado:[null, null]
    });
    

    await this.cargar();

    this.registerForm = this.formBuilder.group({
      manufacture: ["", Validators.compose([Validators.required])]      ,
      model: ["", Validators.compose([Validators.required ])]      ,
      numTer: ["", Validators.compose([Validators.required, 
        Validators.maxLength(255),
        Validators.pattern(new RegExp("^[0-9\\s\\-.]*$"))
      ])]  ,   
     
      orgMarcado:[null, Validators.compose([Validators.required])],
      regionMarcado: [null],
      divisionMarcado: [null], 
      merchMarcado:[null, Validators.compose([Validators.required])],
      tmsProfileMarcado:[null, null]
    });

   


  }




  async organizationFilter(ob:MatSelectChange) {
    let org:Organization
    org=ob.value; 
    this.selectedOrg = org;

    let mostrarRegion=false
    let mostrarDivision=false

    if(org && org.template){

      let tieneRegion = this.commonService.checkJerarquia(org.template, TypeJerarquia.REGION)
      let tieneDivision = this.commonService.checkJerarquia(org.template, TypeJerarquia.DIVISION)
      

      if(tieneRegion){    mostrarRegion=true  }
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneRegion){
        //traemos las REGIONES
              let sr = await this.commonService.pedirRegiones(org.domainRoot);     
              if(sr){
                this.listaRegion= sr.content;
              }
      }else if(tieneDivision){
         //traemos las DIVISIONES
            let sr = await this.commonService.pedirDivision(org.domainRoot);   
            if(sr){
              this.listaDivision= sr.content;
            }
      } else{
          //traemos las  MECHARNT
            let sr = await this.commonService.pedirMerchant(org.domainRoot);   
            if(sr){
              this.listaMerchant= sr.content;
            }
      } 
    }// if(org.template){

    this.verDivision=mostrarDivision
    this.verRegion=mostrarRegion

  }

 

  async regionFilter(ob:MatSelectChange) {
    let reg:Region
    reg=ob.value; 

    let mostrarDivision=false

    if(reg && this.selectedOrg.template){

      let tieneDivision = this.commonService.checkJerarquia(this.selectedOrg.template, TypeJerarquia.DIVISION)
      if(tieneDivision){  mostrarDivision=true   }


      if(tieneDivision){         
          //traemos las DIVISIONES
          let srDiv = await this.commonService.pedirDivision(reg.domain);   
          if(srDiv){
            this.listaDivision= srDiv.content;
          }
      }else{
          //traemos las  MECHARNT
          let sr = await this.commonService.pedirMerchant(reg.domain);   
          if(sr){
            this.listaMerchant= sr.content;
          }
      }
    }

    this.verDivision=mostrarDivision
  }


  async divisionFilter(ob:MatSelectChange) {
    let reg:Division
    reg=ob.value; 

    if(reg){
         //traemos las  MECHARNT
         let sr = await this.commonService.pedirMerchant(reg.domain);   
         if(sr){
           this.listaMerchant= sr.content;
         }      
    }

  }


  onFormSubmit(): void {
    console.log(this.registerForm);
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);

    let objNew : Order = new Order();
    let orderTerminals : OrderTerminals = new OrderTerminals();

    orderTerminals.manufacturer=data['manufacture']  
    orderTerminals.model=data['model']   
    orderTerminals.numberOfTerminals=data['numTer']   
    
    let orgNew: Organization
    orgNew = data['orgMarcado']
    let domainRoot= orgNew.domainRoot
    
    let regionNew:Region
    regionNew=data['regionMarcado']
    if(regionNew){
      domainRoot=regionNew.domain
    }
    
    let divisionNew:Division
    divisionNew=data['divisionMarcado']
    if(divisionNew){
      domainRoot=divisionNew.domain
    }

    let merchantNew:Merchants
    merchantNew=data['merchMarcado']
    if(merchantNew){
      domainRoot=merchantNew.domain
    }

    objNew.domain=domainRoot

    objNew.terminals=orderTerminals    
    objNew.tmsProfileId = data['tmsProfileMarcado']?.id
    


    const dialogData = new ConfirmDialogData(); 
    dialogData.titleI18n = $localize `Do Order`;
    dialogData.textI18n = $localize `Are you sure you want to do this order?`;



    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '28%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe(async (result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action');
          let val:any;
          val = await this.services.doOrder(objNew)


          const popUpData = new ConfirmDialogData(); 
          popUpData.titleI18n = val.orderNumber; 
          popUpData.textI18n =  $localize `Order Number`;
          popUpData.visibleButtom=false
          this.popupDialogRef= this.dialog.open(ConfirmDialogComponent, {
            width: '300px', panelClass: 'custom-modalbox',
            data: popUpData
          });


          this.dialogRef.close(FormCreateActions.SAVED);

      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }



  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


}
