import { Injectable } from '@angular/core';
import { map, mergeMap, of, Subject } from 'rxjs';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { FileRequest, HasFilesResponse } from 'src/app/models/Logfiles.model';
import { RequestFilter } from 'src/app/models/requestFilter.model';
import { Terminals } from 'src/app/models/terminals.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class TerminalService {

  entorno = VIWER_ENTORNO;


  constructor(private merchantRest : RestService) {}
  
    
  async find(_body: RequestFilter){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'terminal/',true,true)   
      .catch(err => {
        return null
      })
    }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'terminal/query',true,true).catch(err => {
          return null
        })
      }
    return value;   
  }




  async sendAggrService(_body: RequestFilter, data : ChartPayload) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body,'terminal/aggregation',false,false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
               values.push(element.number);
          });
          var test = data;
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
    return retValue.toPromise();

  }

  async hasFiles(_body: FileRequest) {
    let terminalHasFiles = this.merchantRest.commonRestCallMerchantForResult(_body,'hasFiles',false,false).catch(err => {
      return new HasFilesResponse();
    });

    return terminalHasFiles;
  }


  async getTerminalFiles(_body: FileRequest) {

    let value = await this.merchantRest.commonRestCallMerchantBlob(_body,'files',true,true)   
    .catch(err => {
      return null
    })


    return value;   
  }



 


}