import { Hierarchy } from "./hierarchy.model";

export class Region {
  id!: string;
  name!: string;
  domain!: string;
  type!: string;
  locationName!: string;
  merchantId!: string;
  properties: RegionProperties | undefined;  
  info: RegionInfo | undefined;
  processors: RegionProcessors[] | undefined;
  hierarchy: Hierarchy | undefined;
}

export class RegionProperties {
  categoryCode:string | undefined;
}

export class RegionInfo {
  email:string | undefined;
  phone:string | undefined;
  websiteUrl:string | undefined;
  address: RegionAddress | undefined;
}

export class RegionProcessors {
  short:string | undefined;
  keyId:string | undefined;
  key:string | undefined;
  merchantId: string | undefined;
}


export class RegionAddress {
  addressLine1:string | undefined;
  addressLine2:string | undefined;
  city:string | undefined;
  postalOrZipCode:string | undefined;
  extendedZip:string | undefined;
  country:string | undefined;
  state:string | undefined;
  timeZone:string | undefined;
}


