
import { Merchants } from "../merchants.model";

export class DataExport_Merchant {

  organizationName: string | undefined; 
  categoryCode: string | undefined;
  name: string | undefined;
  email: string | undefined;
  location: string | undefined;

  constructor(values: Merchants = new Merchants) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.categoryCode= values.properties?.categoryCode
    this.name= values.name
    this.email= values.info?.email
    this.location= values.locationName
  }


}


