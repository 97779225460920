export class Transaction {
  id!: string;
  domain!: string;
  date!: string;
  type!: string;
  status!: string;

  terminal: TerminalInfo | undefined;
  merchant: MerchantInfo | undefined;
  card: CardInfo | undefined;
  trx: TrxInfo | undefined;
  cardholder: TrxCardholder | undefined;
  receiptInfo: ReceiptInfo | undefined;
}


export class ReceiptInfo {
  hasReceipt:boolean = false;
  hasCopy:boolean = false;
  hasDigitalSignature:boolean = false;
  requireSendReceipt:boolean = false;
  requireSendCopy:boolean = false;
}

export class TerminalInfo {
  serialnumber:string | undefined;
  model:string | undefined;
  terminalId:string | undefined;
}

export class MerchantInfo {
  merchantId:string | undefined;
  name:string | undefined;
}
export class TrxCardholder {
  authmode:string | undefined;
}

export class CardInfo {
  cardInfoObfuscated:string | undefined;
  cardType:string | undefined;
  entry:string | undefined;
  aid:string | undefined;
}

export class TrxInfo {
  ref:string | undefined;
  transactionId:string | undefined;
  amount:number | undefined;
  amountRefunded:number | undefined;
  tips:number | undefined;
  //amountRefunded: null,
  //amountConfirmed: null,
  //amountRequested: null,
  //partialAuthorization: null,
  currency:string | undefined;
  country:string | undefined;
  authcode:string | undefined; 
  splitPaymentId:string | undefined;
  sessionId:string | undefined;
  arc:string | undefined;
  dcc:Dcc | undefined;
  referenceData:string | undefined;
  processor:Processor | undefined;
}


export class Processor {
  shortName:string | undefined;
  gatewayResultCode:string | undefined;
}


export class Dcc {
  amount:number | undefined;
  currency:string | undefined;
  markUp:number | undefined;
  marginECB:number | undefined;
  commision:number | undefined;
  exchange:number | undefined;
}

