import { Users } from "./users.model";

export class Groups {
  id!: string ;
  name!: string ;
  description!: string ;
  roles!: string[]; 
  domains!: string[]; 
  status!: string ;   
}

