import { Injectable } from '@angular/core';
import {  Properties } from 'src/app/models/emailProperties.model';
import { Organization } from 'src/app/models/organization.model';
import {  RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class OrganizationsService {


  entorno = VIWER_ENTORNO;

  constructor(private merchantRest : RestService) {}
  
  async find(_body: RequestFilter, showLoading: boolean){
    let value = null;
    if(this.entorno==ENTORNO.XIIBERO){
      value = await this.merchantRest.commonRestCallXiibero(_body,'organization/',showLoading,true)   
      .catch(err => {
        return null
      })
      }else{       
        value = await this.merchantRest.commonRestCallMerchant(_body,'organization/query',showLoading,true)   
        .catch(err => {
          return null
        })
      }
    return value;   
  }

  

  async getProperties(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'organization/query',true,true)   
    .catch(err => {
      return null
    })
    return value?.content;   
  }
  
  async setProperties(properties: Properties) {
    let value = await this.merchantRest.commonRestCallMerchant(properties,'organization/properties/emailServer',true,true)   
    .catch(err => {
      return null
    })
    return value;  
  }







  async saveEditService(_body: Organization){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/save',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async updateEditService(_body: Organization) {
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/update',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
  
  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'organization/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

 
}