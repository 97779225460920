<main id="main" class="main">

    <div class="pagetitle">
        <h1 i18n>{{"Roles" | translate}}</h1>  
        <div>
          <button  *ngIf="commonService.checkAuthServerItemRights('role','W')" mat-mini-fab (click)="onNew()">
           <i class="bi bi-plus-lg"></i>
          </button>
        </div>
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">  
  
  
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Name" | translate}}</div>  
            <div>
              <mat-form-field>
                      <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
  
    <!-- descripcion Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Description" | translate}}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="descFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>

  
        <!-- createdBy Column --
        <ng-container matColumnDef="createdBy">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>Created</div>  
            <div>
              <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="createdByFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.createdBy}} </td>
        </ng-container>


        <!-- updatedBy Column 
        <ng-container matColumnDef="updatedBy">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>Updated</div>  
              <div>
                <mat-form-field>
                    <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                        <input matInput class="form-field" [formControl]="updatedByFilter" placeholder="">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.updatedBy}} </td>
          </ng-container>
        -->

   <!-- createdAt Column -->       
   <ng-container matColumnDef="createdAt">
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Created Date" | translate }}</div> 
      <div>
        <mat-form-field> 
            <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="createdAtFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
            <input matEndDate formControlName="end" placeholder="{{'End Date' | translate }}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'medium'}}</td>
  </ng-container>



   <!-- updatedAt Column --       
   <ng-container matColumnDef="updatedAt">
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header>updatedAt</div> 
      <div>
        <mat-form-field> 
            <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="updatedAtFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="Start">
            <input matEndDate formControlName="end" placeholder="End">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.updatedAt | date:'medium'}}</td>
  </ng-container>-->

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="button">   
      <div>
            <button *ngIf="commonService.checkAuthServerItemRights('role','R') && !commonService.checkAuthServerItemRights('role','W')" matTooltip="view"  (click)="onView(element)" mat-icon-button color="primary" >
              <i class="bi bi-eye"></i>
            </button>

            <button *ngIf="commonService.checkAuthServerItemRights('role','W')" matTooltip="{{'edit' | translate }}"  (click)="onEdit(element)" mat-icon-button color="primary" >
              <i class="bi bi-pencil"></i>
            </button>

            <button *ngIf="commonService.checkAuthServerItemRights('role','D')" matTooltip="{{'delete' | translate}}" (click)="onDelete(element)" mat-icon-button color="primary">
              <i class="bi bi-trash"></i>
            </button>
      </div>

    </td>
  </ng-container>



  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
          <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
          </mat-paginator>
    </div>
    </main>