import { AfterViewInit, ChangeDetectorRef, Component, Inject, OnInit } from '@angular/core';
import { MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ConfirmDialogActions } from '../../../../components/confirm-dialog/confirm-dialog.model';
import { Transaction } from 'src/app/models/transaction.model';
import { ETransactionType, ReferencedTransactionReq } from 'src/app/models/referencedTransactionReq.model';
import { TransactionsService } from '../transactions.service';
import { ServerResponse } from 'src/app/services/merchant/ServerResponse';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-referenced-dialog',
  templateUrl: './referenced-dialog.component.html',
  styleUrls: ['./referenced-dialog.component.css']
})
export class ReferencedDialogComponent implements OnInit, AfterViewInit {

  registerForm!: FormGroup;

  maxAmount: number = 0;

  response: string | undefined;
  
  trx: Transaction ;
  type: string;


  constructor(@Inject(MAT_DIALOG_DATA) public data: { trx: Transaction, type: string},
    private trxServices : TransactionsService,
    private formBuilder: FormBuilder,
    public dialogRef: MatDialogRef<ReferencedDialogComponent>,
    private cdr: ChangeDetectorRef,
    private translate: TranslateService) {
      this.trx = data.trx;
      this.type = data.type;
  }

  async cargar() {

    if(this.maxAmount){            
      this.registerForm = this.formBuilder.group({
        valor: [this.maxAmount.toFixed(2), Validators.compose([Validators.required,Validators.max(this.maxAmount), Validators.min(0),Validators.pattern(/^[0-9]+([,.][0-9]+)?$/)])]
      });

    }



  }


  async ngOnInit(): Promise<void> {
   
    this.registerForm = this.formBuilder.group({
      valor: ["", Validators.compose([Validators.required])]
    });
    
    if (this.trx.trx?.amount) this.maxAmount = this.trx.trx?.amount;
    if (this.trx.trx?.amountRefunded) this.maxAmount -= this.trx.trx.amountRefunded;
    
    await this.cargar();

    this.cdr.detectChanges();

  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }



  confirm(): void {
    
    if (this.registerForm.invalid) {
      return;
    }    
    const data = this.applyFormats(this.registerForm);
    
    let referencedData = {
      trxId : this.trx.id,
      type : this.type,
      amount: data['valor']
    };

    this.sendReferenced(referencedData)
    
  }

  private async sendReferenced(referenced: ReferencedTransactionReq){
    let errorTxt = "Error sending the request. Please try it later.";
    try{
      let ret = await this.trxServices.sendReferenced(referenced)
      if (ret) {
        this.response = "Transaction requested. Review your email account to complete the transaction.";
      } else {
        this.response = errorTxt
      }
    } catch (err) {
      this.response = errorTxt;
    }        
  }

  close(): void {
    this.dialogRef.close(ConfirmDialogActions.CONFIRM);
  }

  cancel(): void {
    this.dialogRef.close(ConfirmDialogActions.CANCEL);
  }  

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }

  public getTypeTranslated(toLowerCase:boolean) : string{
    let txt = this.type==="REFUND"? this.translate.instant("Refund") : this.translate.instant("Confirmate");
    if (toLowerCase) {
      return txt.toLowerCase();
    } else {
      return txt;
    }
  }

  public getEntireOrPartial() : string{
    return this.translate.instant('ENTIRE_OR_PARTIAL', {
      type: this.getTypeTranslated(true)
    });
  }  
}
