import { Injectable } from '@angular/core';
import {  Properties } from 'src/app/models/emailProperties.model';
import { Order } from 'src/app/models/order.model';
import { Organization } from 'src/app/models/organization.model';
import {  RequestFilter } from 'src/app/models/requestFilter.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { ENTORNO } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';


@Injectable({
  providedIn: 'root',
})
export class OrderService {


  entorno = VIWER_ENTORNO;

  constructor(private merchantRest : RestService) {}
  
  async find(_body: RequestFilter){
    let value = null;   
    value = await this.merchantRest.commonRestCallXiibero(_body,'terminal/findOrders',true,true)   
    .catch(err => {
      return null
    })     
    return value;   
  }

  

  async doOrder(_body: Order){
    let value = await this.merchantRest.commonRestCallXiibero(_body, 'terminal/doOrder',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

 
}