
import { AfterViewInit, Component, OnInit, Renderer2, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import {MatLegacyPaginator as MatPaginator, LegacyPageEvent as PageEvent} from '@angular/material/legacy-paginator';
import { MatLegacySelectChange as MatSelectChange } from '@angular/material/legacy-select';
import {MatSort, Sort} from '@angular/material/sort';
import {MatLegacyTable as MatTable, MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import { Terminals } from 'src/app/models/terminals.model';
import { VIWER_ENTORNO, environment } from 'src/environments/environment';
import {animate, state, style, transition, trigger} from '@angular/animations';
import { QueryAggregationList, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { TerminalService } from './terminal.service';
import { TransactionComponent } from '../transaction/transaction.component';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef } from '@angular/material/legacy-dialog';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { CommonService } from 'src/app/services/common.service';
import { HasFilesResponse, FileRequest } from 'src/app/models/Logfiles.model';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { Subscription, debounceTime, distinctUntilChanged } from 'rxjs';
import { FormNewOrdersComponent } from '../orders/form-new-orders/form-new-orders.component';
import * as XLSX from "xlsx";
import { DataExport_Terminals } from 'src/app/models/dataExport/dataExport_terminals.model';
import { ENTORNO } from 'src/app/util/constants';
import { Organization } from 'src/app/models/organization.model';
import { OrganizationsService } from '../organizations/organizations.service';
import { Merchants } from 'src/app/models/merchants.model';
import { MerchantService } from '../merchants/merchant.service';
import { DataExport_Terminals_Xiibero } from 'src/app/models/dataExport/dataExport_terminals_Xiibero.model';
import { UtilService } from 'src/app/services/util.service';
import { AuthService } from 'src/app/auth/auth.service';

enum Columns {
  terminalId = 'terminalId', 
  serialnumber = 'serialnumber', 
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  //organization_name = 'organization.name', 
  model = 'model', 
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  creationDate = 'createdAt',
  button='button',
  expand = 'expand'
}

enum ColumnsOrganization {
  terminalId = 'terminalId', 
  serialnumber = 'serialnumber', 
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  //organization_name = 'organization.name', 
  model = 'model', 
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  creationDate = 'createdAt',
  button='button',
  expand = 'expand'
}

enum ColumnsMerchant {
  terminalId = 'terminalId', 
  serialnumber = 'serialnumber', 
  merchant_name = 'merchant.name', 
  merchant_merchantId = 'merchant.merchantId', 
  //organization_name = 'organization.name', 
  model = 'model', 
  //manufacturer = 'manufacturer',
  status = 'status',
  properties_currency = 'properties.currency',
  properties_country = 'properties.country',
  creationDate = 'createdAt',
  button='button',
  expand = 'expand'
}


enum ColumnsXiibero {
  terminalId = 'terminalId', 
  serialnumber = 'serialnumber', 
  organization_xiibero = 'hierarchy.ORGANIZATION.name',
  merchant_xiibero = 'hierarchy.MERCHANT.name',
  //merchant_name = 'merchant.name', 
  //merchant_merchantId = 'merchant.merchantId', 
  manufacturer = 'manufacturer', 
  model = 'model', 
  status = 'status',
  button='button',
 // expand = 'expand'
}

@Component({
  selector: 'app-terminal',
  templateUrl: './terminal.component.html',
  styleUrls: ['./terminal.component.css'],
  animations: [
    trigger('detailExpand', [
      state('collapsed', style({height: '0px', minHeight: '0'})),
      state('expanded', style({height: '*'})),
      transition('expanded <=> collapsed', animate('225ms cubic-bezier(0.4, 0.0, 0.2, 1)')),
    ]),
  ],
})

export class TerminalComponent implements OnInit, AfterViewInit {

  
  graph1Data : ChartPayload = {
    labels :   [],
    values : []
  }
  graph2Data : ChartPayload = {
    labels :   [],
    values : []
  }
  
//  displayedColumns =  Object.keys(Columns).filter((v) => isNaN(Number(v)));
  displayedColumns =  Object.keys(Columns);
  expandedElement: Terminals | null = new Terminals;
  dataSource = new MatTableDataSource<Terminals>();
  
  pageLength=0;
  pageSize=environment.defaultPaginacion;
  pageSizeOptions=environment.paginacion;
  sortId="terminalId,desc";//idColumna + "," + direccion

  terminalCountryCodeSelect: string[] = ['',  'ES', 'EN'];
  terminalCurrencyCodeSelect: string[] = ['', 'EUR'];
  terminalStatusSelect: string[] = [''];

  manufacturerFilter = new FormControl( );
  modelFilter = new FormControl();
  serialnumberFilter = new FormControl();
  terminalIdFilter = new FormControl();
  merchantIdFilter = new FormControl();
  statusFilter = new FormControl();
  creationFilter = new FormControl();
  merchantFilter = new FormControl();
  organizationFilter = new FormControl();
  
  organizationXiiberoSelect!: Organization;
  organizationXiiberoList:Organization[] = [];
  merchantXiiberoSelect!: Merchants;
  merchantXiiberoList:Merchants[] = [];

  mapQuery = new Map();

  entorno = VIWER_ENTORNO;
  isEntornoXiibero: boolean = false;
  isEntornoGetnet: boolean = false;


  @ViewChild(MatPaginator) paginator!: MatPaginator;
  @ViewChild(MatSort) sort!: MatSort;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;
  private dialogRefTer!: MatDialogRef<FormNewOrdersComponent>;

  rf: RequestFilter= new RequestFilter(null,0,this.pageSize,this.sortId);

  jerarquiaSearchSbscription!: Subscription;

  private dialogRef!: MatDialogRef<TransactionComponent>;

  constructor(public dialog: MatDialog,
    private servicios : TerminalService,
    private orgService: OrganizationsService, 
    private merchService: MerchantService,
    public authServer: AuthService,
    public utilService: UtilService,
    public commonService : CommonService) {  
     
      
      this.terminalStatusSelect = [$localize `Active`,  $localize `Inactive`,  $localize `Deleted`]
  
  }


  exportIt() {
    let newDate = new Date();
    let nombre = "terminal-"+newDate.toISOString().slice(0, 10)+"-"+newDate.getTime();
    const fileName = nombre+".xlsx";
    
    const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.procesarDataExport(this.dataSource.data));
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    let Heading
    if(this.entorno==ENTORNO.XIIBERO){
      Heading = [['Organization', 'Merchant', 'Terminal Id','S/N', 'Manufacture', 'Model', 'Status']];
    }else{  
      Heading = [['Terminal Id','S/N','Merchant', 'Merchant Id', 'Manufacture', 'Model', 'Status', 'Currency', 'Country']];
    }
    XLSX.utils.sheet_add_aoa(ws, Heading);
    XLSX.utils.book_append_sheet(wb, ws, "terminal");

    XLSX.writeFile(wb, fileName);
  }

  private procesarDataExport(data:Terminals[]): any[]  {
    
    if(this.entorno==ENTORNO.XIIBERO){
      
      let result : DataExport_Terminals_Xiibero[] = [] ;
      data.forEach(row => {
        let tempRow:DataExport_Terminals_Xiibero = new DataExport_Terminals_Xiibero(row);
        result.push(tempRow);
      });
      return result;

    }else{        
        let result : DataExport_Terminals[] = [] ;
        data.forEach(row => {
          let tempRow:DataExport_Terminals = new DataExport_Terminals(row);
          result.push(tempRow);
        });

        return result;
    }

  }


  async cargar(){   
    //organizaciones
      let rfOrg: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
      let srOrg = await this.orgService.find(rfOrg,true);     
      if(srOrg){
        this.organizationXiiberoList= srOrg.content;       
      }

          //merchants
          let rfMerch: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
          let srMerch = await this.merchService.find(rfMerch);     
          if(srMerch){
            this.merchantXiiberoList= srMerch.content;       
          }

    }



  updateModels() {
    var filter: RequestFilter = new RequestFilter(null,0, 500, 'model,desc');   
    filter.filter = this.rf.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "model", "count");

    this.servicios.sendAggrService(filter, this.graph1Data).then(
        value => this.graph1Data = {...value});
  }

  updateCurrencies() {
    var filter: RequestFilter = new RequestFilter(null,0, 500, 'model,desc');   
    filter.filter = this.rf.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "properties.currency", "count");

    this.servicios.sendAggrService(filter, this.graph2Data).then(
        value => {
          this.graph2Data = {...value};
          this.terminalCurrencyCodeSelect = [''];
          value.labels.forEach(element => {
            this.terminalCurrencyCodeSelect.push(element);  
          });
          
        });
  }

  updateCountries() {
    var filter: RequestFilter = new RequestFilter(null,0, 500, 'model,desc');   
    filter.filter = this.rf.filter;
    filter.aggregation = QueryAggregationList.construirAggregation(filter.aggregation, "properties.country", "count");

    this.servicios.sendAggrService(filter, this.graph2Data).then(
        value => {
          this.terminalCountryCodeSelect = [''];
          value.labels.forEach(element => {
            this.terminalCountryCodeSelect.push(element);  
          });
          
        });
  }

  async lanzarLLamada(){
      let value = await this.servicios.find(this.rf);
      console.log(JSON.stringify(value))
      if(value){
        console.log(JSON.stringify(value.content))
        this.dataSource = new MatTableDataSource<Terminals>(value.content);
        this.pageLength=value.totalElements;
      }
      this.updateModels();
      this.updateCurrencies();
      this.updateCountries();
    }
    


cargarCache() {

  let value = this.utilService.cargarCacheDomain();

  if(value && value!="undefined"){
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", value);        
  }else{
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, "domain", undefined);            
  }


  this.lanzarLLamada();  
}



  async ngOnInit() {

    
  this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());


    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;

    
    
    if(this.entorno==ENTORNO.XIIBERO){
      
      await this.cargar();

      this.displayedColumns =  Object.keys(ColumnsXiibero);
      this.isEntornoXiibero= true;

    }else{      
      this.isEntornoGetnet= true;
          if (this.commonService.isOrganizationlevel()) {
            this.displayedColumns =  Object.keys(ColumnsOrganization);
          } else if (this.commonService.isMerchantlevel()) {
            this.displayedColumns =  Object.keys(ColumnsMerchant);
          } else {
            this.displayedColumns =  Object.keys(Columns);
          }
    }

    /*this.manufacturerFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      console.log("manufacturerFilter="+value);   
      const idColumn = Columns.manufacturer;       
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();
    });*/
    this.modelFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe((value) => {
      console.log("modelFilter="+value);   
      const idColumn = Columns.model;       
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });
    this.serialnumberFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("serialnumberFilter="+value);
      const idColumn = Columns.serialnumber;       
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });   
    this.terminalIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("terminalIdFilter="+value);
      const idColumn = Columns.terminalId;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });  

    this.statusFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("statusFilter="+value);
      const idColumn = Columns.status;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });  

    this.creationFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("creationFilter="+value);
      const idColumn = Columns.creationDate;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });  

    /*this.merchantFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      console.log("statusFilter="+value);
      const idColumn = Columns.status;
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();  
    });  */
    this.merchantFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
        const idColumn = Columns.merchant_name;
        console.log(idColumn+"="+value);     
        this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
        this.lanzarLLamada();   
    });

    this.merchantIdFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.merchant_merchantId;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();   
  }); 
   /* this.organizationFilter.valueChanges.pipe(debounceTime(700),distinctUntilChanged()).subscribe(value => {
      const idColumn = Columns.organization_name;
      console.log(idColumn+"="+value);     
      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada();   
  }); */

  this.cargarCache()

  }

  terminalStatusFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.status;
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();  
  }
  
  terminalCountryCodeFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.properties_country;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();  
  }

  terminalCurrencyCodeSelectFilter(ob:MatSelectChange) {
    let value=ob.value; 
    const idColumn = Columns.properties_currency;
    console.log(idColumn+"="+value);  
    if(value && value==""){
      value=null
    }
    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();  
  }


  async organizationXiiberoFilter(ob:MatSelectChange) {
      let org:Organization
      org=ob.value; 
      this.organizationXiiberoSelect=org;

      let value:string | undefined
      
      const idColumn = "domain";
      if(org){
        value=org.domainRoot+"."
      }

      this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
      this.lanzarLLamada(); 


      //traemos las  MECHARNT
      let sr
      if(value){
        sr = await this.utilService.pedirMerchant(org.domainRoot);   
      }else{
        let rfMerch: RequestFilter= new RequestFilter(null,0, 100,"name,desc");       
        sr = await this.merchService.find(rfMerch); 
      }

      if(sr){
        this.merchantXiiberoList= sr.content;
      }



  }


  merchantXiiberoFilter(ob:MatSelectChange) {
    let org:Merchants
    org=ob.value; 

    let value:string | undefined
    
    const idColumn = "domain";
    if(org){
      value=org.domain
    }else if(this.organizationXiiberoSelect){
      value=this.organizationXiiberoSelect.domainRoot
    }

    this.rf.filter = QueryFilterList.construirFilter(this.mapQuery, idColumn, value);        
    this.lanzarLLamada();  
  }


  ngAfterViewInit() {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

  pageEvent(pag:PageEvent){
    console.log("pageEvent="+JSON.stringify(pag)) 
    this.rf = RequestFilter.construirPage(this.rf.filter , pag.pageIndex,pag.pageSize,this.sortId);
    this.lanzarLLamada();
  }
 
  announceSortChange(sortState: Sort) {
    console.log("ordenar="+JSON.stringify(sortState)) 
    let active = sortState.active.replace("_",".")
    let direccion="";
    if(sortState.direction){
      direccion=","+sortState.direction
    }
    this.sortId=active+direccion;
    this.rf = RequestFilter.construirOrder(this.rf.filter , this.pageSize,this.sortId);     
    this.lanzarLLamada();
  }





  viewTransaction(row: any) {

    this.dialogRef = this.dialog.open(TransactionComponent, {
      width: '95%', panelClass: 'custom-modalbox',
      data: {
        obj: row
      }
    });

    this.dialogRef.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
  }


  async hasFiles(t: Terminals) {
    console.log("hasFiles call")
    let value = false;

    if(t.id !== undefined){
      value = await this.servicios.hasFiles(new FileRequest(t.id)).then(val=>{
        let tieneFiles = val as HasFilesResponse;
        return tieneFiles.result;
      }).catch(err => {
        return false;
      });
    }
    t.hasFiles = value;

    return t.hasFiles;
  }

  async downloadFiles(row: any) {
    
    let requestFile: FileRequest = new FileRequest(row.id);

    let valueBlob = await this.servicios.getTerminalFiles(requestFile);

    if(valueBlob){

    /* DESCARGA EL PDF
     let link = document.createElement('a');
      link.download = 'hello.pdf';
      link.href = URL.createObjectURL(valueBlob);
      link.click();
      URL.revokeObjectURL(link.href);
      */

      //ABRE UNA PESTAÑA CON EL PDF
      var fileURL = URL.createObjectURL(valueBlob);
      window.open(fileURL);
    }
  }


  refrescar() {
    this.lanzarLLamada();
  }









  
  onNewOrder(){
    this.dialogRefTer = this.dialog.open(FormNewOrdersComponent, {
      width: '70%', panelClass: 'custom-modalbox-big',
      data: {//vacio al ser nuevo
      }
    });

    this.dialogRefTer.afterClosed().subscribe((result) => {
      if (result === 1) {        
        console.log('CONFIRM recived from dialog window');
        //this.lanzarLLamada();
      } else if (result === 0) {
        console.log('CANCEL recived from dialog window');
      }
    });
   }

   


}
/*
function terminalStatusFilter(ob: any, MatSelectChange: typeof MatSelectChange) {
  throw new Error('Function not implemented.');
} 
*/