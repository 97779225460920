<main id="main" class="main">
   <div class="pagetitle">
      <h1 i18n>{{"Dashboard" | translate }}</h1>
      <h5 class="dateTittle">{{salesSelectedRange}}</h5>
      <div class="filter">
         <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-search"></i></a>
         <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
            <li class="dropdown-header text-start">
               <h3 i18n>{{ "Date range" | translate }}</h3>
            </li>
            <li class="filterCard">
               <mat-form-field ngDefaultControl>
                   <mat-label>Date</mat-label>
                   <mat-date-range-input [formGroup]="localDateFilter" [rangePicker]="picker">
                       <input matStartDate formControlName="start" placeholder="Start" readonly (click)="picker.open()">
                       <input matEndDate formControlName="end" placeholder="End" readonly (click)="picker.open()">
                   </mat-date-range-input>
                   <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                   <mat-date-range-picker #picker (closed)="onPickerClosed()"></mat-date-range-picker>
               </mat-form-field>
            </li>
         </ul>
         <div *ngIf="dashboardCurrencies.length > 1">
            <a class="icon" href="#" data-bs-toggle="dropdown"><i class="bi bi-currency-exchange"></i></a>
            <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow">
               <li class="dropdown-header text-start">
                  <h3 i18n>{{"Currency" | translate }}</h3>
               </li>
               <li *ngFor="let item of dashboardCurrencies" (click)="selectChangeCurrency($event, item   )"
                  class="dropdown-item" href="#">
                  <img height="40px" width="40px" *ngIf="item.isoname!=='default'" [src]="'assets/flags/' + item.isoname + '.svg' | lowercase">
                  <img height="40px" width="40px" *ngIf="item.isoname==='default'" [src]="'assets/flags/default.jpg' | lowercase" alt ="item.name"> 
                  &nbsp; 
                  {{ item.name }}
               </li>
            </ul>
         </div>
      </div>
   </div>

   <section class="section nodashboarddata" *ngIf="!this.hasAccess('transaction') && !this.hasAccess('terminal')">
      <div class="row">
         <div class="col-lg-12">
            <div class="row">
                  <div class="card info-card sales-card">
                     <div class="card-body d-flex justify-content-center">
                        <h2 class="card-title">{{"no data to display" | translate }}</h2>
                     </div>
                  </div>
            </div>
         </div>
      </div>
   </section>

   <section class="section dashboard" *ngIf="this.hasAccess('transaction') || this.hasAccess('terminal')">
      <div class="row">
         <div class="col-lg-12">
            <div class="row">
               <div class="col-xxl-4 col-md-6">
                  <div class="card info-card sales-card"  *ngIf="this.hasAccess('transaction')">
                     <div class="card-body" *ngIf="this.hasAccess('transaction')">
                        <h5 class="card-title">{{ "Payments" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-cash-coin"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.payments | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.paymentOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardTipsIndicators.tipsOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations with tips" | translate }}</span>
                              <span> {{dashboardTipsIndicators.tips | currency : dashboardCurrency.isoname }}</span>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardSplitIndicators.splitOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"split operations" | translate }}</span>
                              <span> {{dashboardSplitIndicators.split | currency : dashboardCurrency.isoname }}</span>

                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-md-6" *ngIf="this.hasAccess('transaction')">
                  <div class="card info-card revenue-card">
                     <div class="card-body">
                        <h5 class="card-title">{{"Voids" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-hand-thumbs-down"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.voids | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.voidOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                              <br>
                              <br>
                              <br>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-md-6" *ngIf="this.hasAccess('transaction')">
                  <div class="card info-card customers-card">
                     <div class="card-body">
                        <h5 class="card-title">{{"Refunds" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-arrow-return-left"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.refunds | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.refundOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                              <br>
                              <br>
                              <br>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-12" *ngIf="this.hasAccess('transaction')">
                  <div class="card info-card customers-card">
                     <div class="card-body">
                        <h5 class="card-title" i18n>{{"Preauthoritations" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-bag-plus"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.preauth | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.preauthOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-12" *ngIf="this.hasAccess('transaction')">
                  <div class="card info-card customers-card">
                     <div class="card-body">
                        <h5 class="card-title" i18n>{{"Modify of preauthoritations" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-bag-x"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.modauth | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.modauthOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>
               <div class="col-xxl-4 col-xl-12" *ngIf="this.hasAccess('transaction')">
                  <div class="card info-card customers-card">
                     <div class="card-body">
                        <h5 class="card-title" i18n>{{"Confirmations" | translate }} </h5>
                        <div class="d-flex align-items-center">
                           <div class="card-icon rounded-circle d-flex align-items-center justify-content-center"> <i
                                 class="bi bi-bag-check-fill"></i></div>
                           <div class="ps-3">
                              <h6> {{dashboardIndicators.confirm | currency : dashboardCurrency.isoname }}</h6>
                              <br>
                              <span class="text-success small pt-1 fw-bold">{{dashboardIndicators.confirmOps}}
                                 &nbsp;</span>
                              <span class="text-success small pt-1 fw-bold" i18n>{{"operations" | translate }}</span>
                           </div>
                        </div>
                     </div>
                  </div>
               </div>


               <div class="col-12" *ngIf="this.hasAccess('transaction') || this.hasAccess('terminal')">
                  <div class="card">
                     <div class="card-body">
                        <h5 class="card-title">{{"Reports" | translate }}</h5>
                        <mat-accordion  *ngIf="this.hasAccess('transaction')">
                           <mat-expansion-panel #matexpansionpanel1 [expanded]="true" (opened)="openGroup1()">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <i class="bi bi-bar-chart-line"></i>&nbsp; {{"Accumulations" | translate }}
                                 </mat-panel-title>
                                 <mat-panel-description>

                                 </mat-panel-description>
                              </mat-expansion-panel-header>


                              <div class="row" *ngIf="salesSelectedIndex > 0">
                                 <div id="aggTxByHour" class="Accumulations">
                                    <div  class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Transactions grouped by hour" | translate }} 
                                             <button id="aggTxByHour_angle-expand"    (click)="expand('aggTxByHour','Accumulations')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggTxByHour_angle-contract" (click)="contrand('aggTxByHour','Accumulations')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-heatmap chartTitle="" [monday]="aggTxByHour.monday"
                                             [tuesday]="aggTxByHour.tuesday" [wednesday]="aggTxByHour.wednesday"
                                             [thursday]="aggTxByHour.thursday" [friday]="aggTxByHour.friday"
                                             [saturday]="aggTxByHour.saturday" [sunday]="aggTxByHour.sunday">
                                          </app-chart-tx-heatmap>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="aggTxDaily" class=" Accumulations" >
                                    <div  class="card" >
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Daily Transactions" | translate }}
                                             <button id="aggTxDaily_angle-expand"    (click)="expand('aggTxDaily','Accumulations')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggTxDaily_angle-contract" (click)="contrand('aggTxDaily','Accumulations')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-daily chartTitle="" [dates]="aggTxDaily.dates"
                                             [values]="aggTxDaily.values">
                                          </app-chart-tx-daily>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="aggTxDailyAmount" class="Accumulations">
                                    <div  class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Daily amount transactions" | translate }}
                                             <button id="aggTxDailyAmount_angle-expand"    (click)="expand('aggTxDailyAmount','Accumulations')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggTxDailyAmount_angle-contract" (click)="contrand('aggTxDailyAmount','Accumulations')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-daily-amount chartTitle="Amount by currency"
                                             [dates]="aggTxDailyAmount.dates" [values]="aggTxDailyAmount.values">
                                          </app-chart-tx-daily-amount>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="aggSplitTxDailyAmount" class="Accumulations">
                                    <div  class="card">
                                       <div class="card-body">
                                          <h5 class="card-title" i18n>{{"Daily amount of splitted transactions" | translate }}
                                             <button id="aggSplitTxDailyAmount_angle-expand"    (click)="expand('aggSplitTxDailyAmount','Accumulations')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggSplitTxDailyAmount_angle-contract" (click)="contrand('aggSplitTxDailyAmount','Accumulations')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-daily-amount chartTitle="Splitted transaction amount by currency"
                                             [dates]="aggSplitTxDailyAmount.dates" [values]="aggSplitTxDailyAmount.values">
                                          </app-chart-tx-daily-amount>
                                       </div>
                                    </div>
                                 </div>

                              </div>
                           </mat-expansion-panel>
                        </mat-accordion>
                        
                        <br><br>

                        <mat-accordion *ngIf="this.hasAccess('transaction') && salesSelectedIndex > 0">
                           <mat-expansion-panel #matexpansionpanel4 [expanded]="false" (opened)="openGroup4()">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <i class="bi bi-bar-chart-line"></i>&nbsp; {{"Comparatives" | translate }}
                                 </mat-panel-title>
                                 <mat-panel-description>

                                 </mat-panel-description>
                              </mat-expansion-panel-header>


                              <div class="row" *ngIf="salesSelectedIndex > 0">

                                 <div id="aggCmpTxDaily" class="Comparatives" >
                                    <div class="card" style="height: 95%;">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Compare daily Transactions" | translate }}
                                             <button id="aggCmpTxDaily_angle-expand"    (click)="expand('aggCmpTxDaily','Comparatives')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggCmpTxDaily_angle-contract" (click)="contrand('aggCmpTxDaily','Comparatives')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-compare sequence="1" chartTitle="" 
                                             [graphCurrencies]="shownCurrencies"
                                             [dates]="aggCmpTxDaily.dates"
                                             [values]="aggCmpTxDaily.values"
                                             [values2]="aggCmpTxDaily.prevValues">
                                          </app-chart-tx-compare>
                                       </div>
                                    </div>
                                 </div>
                                 
                                 <div id="aggCmpTxDailyAmount" class="Comparatives" >
                                    <div class="card" style="height: 95%;">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Compare daily amount transactions" | translate }}
                                             <button id="aggCmpTxDailyAmount_angle-expand"    (click)="expand('aggCmpTxDailyAmount','Comparatives')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggCmpTxDailyAmount_angle-contract" (click)="contrand('aggCmpTxDailyAmount','Comparatives')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-compare sequence="2" chartTitle=""
                                             [graphCurrencies]="shownCurrencies"
                                             [dates]="aggCmpTxDailyAmount.dates" 
                                             [values]="aggCmpTxDailyAmount.values"
                                             [values2]="aggCmpTxDailyAmount.prevValues">
                                          </app-chart-tx-compare>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="aggCmpTypeDaily" class="Comparatives" >
                                    <div class="card" style="height: 95%;">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Compare transactions by type" | translate }}
                                             <button id="aggCmpTypeDaily_angle-expand"    (click)="expand('aggCmpTypeDaily','Comparatives')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="aggCmpTypeDaily_angle-contract" (click)="contrand('aggCmpTypeDaily','Comparatives')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-compare-values  chartTitle=""
                                             [labels]="aggCmpTypeDaily.labels" 
                                             [values]="aggCmpTypeDaily.values"
                                             [values2]="aggCmpTypeDaily.prevValues">
                                          </app-chart-compare-values>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-expansion-panel>
                        </mat-accordion>
                        
                        <br><br>

                        <mat-accordion  *ngIf="this.hasAccess('transaction')">
                           <mat-expansion-panel #matexpansionpanel2 [expanded]="false" (opened)="openGroup2()">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <i class="bi bi-bar-chart-line"></i>&nbsp; {{"Transaction breakdowns" | translate }}
                                 </mat-panel-title>
                                 <mat-panel-description>

                                 </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div class="row">

                                 <div id="graph1Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Type of transactions" | translate }}
                                             <button id="graph1Data_angle-expand"    (click)="expand('graph1Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph1Data_angle-contract" (click)="contrand('graph1Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut #chart1 chartTitle="" [chartDataset]="graph1Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="graph2Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Card brands" | translate }}
                                             <button id="graph2Data_angle-expand"    (click)="expand('graph2Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph2Data_angle-contract" (click)="contrand('graph2Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut #chart2 chartTitle="" [chartDataset]="graph2Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="graph3Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Card technologies" | translate }}
                                             <button id="graph3Data_angle-expand"    (click)="expand('graph3Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph3Data_angle-contract" (click)="contrand('graph3Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut #chart3 chartTitle="" [chartDataset]="graph3Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="graph4Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Transaction by currency" | translate }}
                                             <button id="graph4Data_angle-expand"    (click)="expand('graph4Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph4Data_angle-contract" (click)="contrand('graph4Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut chartTitle="" [chartDataset]="graph4Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="graph5Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Results of transactions" | translate }}
                                             <button id="graph5Data_angle-expand"    (click)="expand('graph5Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph5Data_angle-contract" (click)="contrand('graph5Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut chartTitle="" [chartDataset]="graph5Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                                 <div id="graph8Data" class="breakdowns" >
                                    <div class="card">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Authorization Mode" | translate }}
                                             <button id="graph8Data_angle-expand"    (click)="expand('graph8Data','breakdowns')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                              </button>
                                              <button id="graph8Data_angle-contract" (click)="contrand('graph8Data','breakdowns')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                              </button>
                                          </h5>
                                          <app-chart-tx-donut chartTitle="" [chartDataset]="graph8Data">
                                          </app-chart-tx-donut>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-expansion-panel>
                        </mat-accordion>
                        <br><br>

                        <mat-accordion *ngIf="this.hasAccess('terminal')">
                           <mat-expansion-panel #matexpansionpanel3 [expanded]="false" (opened)="openGroup3()">
                              <mat-expansion-panel-header>
                                 <mat-panel-title>
                                    <i class="bi bi-bar-chart-line"></i>&nbsp; {{"Other charts" | translate }}
                                 </mat-panel-title>
                                 <mat-panel-description>
                                 </mat-panel-description>
                              </mat-expansion-panel-header>

                              <div class="row" *ngIf="this.hasAccess('terminal')">
                                 
                                 <div id="graph6Data" class="Other" >
                                    <div class="card" style="height: 95%;">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Device models" | translate }}
                                             <button id="graph6Data_angle-expand"    (click)="expand('graph6Data','Other')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                             </button>
                                             <button id="graph6Data_angle-contract" (click)="contrand('graph6Data','Other')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                             </button>
                                          </h5>
                                          <app-chart-model chartTitle="" [chartDataset]="graph6Data"></app-chart-model>
                                       </div>
                                    </div>
                                 </div>

                                 <div id="graph7Data" class="Other" *ngIf="isOrganization">
                                    <div class="card" style="height: 95%;">
                                       <div class="card-body">
                                          <h5 class="card-title">{{"Top 5 Merchants" | translate }}
                                             <button id="graph7Data_angle-expand"    (click)="expand('graph7Data','Other')" mat-icon-button >
                                                <i class="bi bi-arrows-angle-expand"></i>
                                             </button>
                                             <button id="graph7Data_angle-contract" (click)="contrand('graph7Data','Other')" style="display:none" mat-icon-button >
                                                <i class="bi bi-arrows-angle-contract"></i>
                                             </button>
                                          </h5>
                                          <app-chart-top5-merchant chartTitle="{{'Amount' | translate }}" [chartDataset]="graph7Data">
                                          </app-chart-top5-merchant>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </mat-expansion-panel>
                        </mat-accordion>
                        <br><br>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </div>
   </section>
</main>