import { Component, OnInit, Inject } from '@angular/core';
import { DOCUMENT } from '@angular/common'
import { authSpaConfig } from '../../../auth/auth-spa.config';
import { ActivatedRoute, Router, RouterLink } from '@angular/router';
import { AuthService } from 'src/app/auth/auth.service';


@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.css']
})
export class TopbarComponent implements OnInit {
  userProfile: object | undefined;


  constructor(
    @Inject(DOCUMENT) private document: Document,
    private authService: AuthService) {

  }

  ngOnInit(): void {
  }

  login() {
    this.authService.login();
  }

}
