<main>
    <div class="container">
       <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
             <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                   <div class="card mb-3">
                      <div class="card-body">
                         <div class="pt-4 pb-2">
                            <div class="d-flex justify-content-center py-4"> 
                              <div  class="logo d-flex align-items-center w-auto"> 
                                 <img src="assets/img/logo.png" alt=""> 
                                 <span class="d-none d-lg-block" i18n>merchantPortal</span> 
                              </div>
                           </div>                           
                         </div>
                         <form class="row g-3 needs-validation" novalidate>
                            <div class="col-12">
                               <label for="yourUsername" class="form-label">Username</label>
                               <div class="input-group has-validation">
                                  <span class="input-group-text" id="inputGroupPrepend">@</span> <input type="text" name="username" class="form-control" id="yourUsername" required>
                                  <div class="invalid-feedback">Please enter your username.</div>
                               </div>
                            </div>
                            <div class="col-12">
                               <label for="yourPassword" class="form-label">Password</label> <input type="password" name="password" class="form-control" id="yourPassword" required>
                               <div class="invalid-feedback">Please enter your password!</div>
                            </div>
                            <div class="col-12">
                               <div class="form-check"> <input class="form-check-input" type="checkbox" name="remember" value="true" id="rememberMe"> <label class="form-check-label" for="rememberMe">Remember me</label></div>
                            </div>
                            <div class="col-12"> <button class="btn btn-primary w-100" type="submit">Login</button></div>
                            <div class="col-12">
                               <p class="small mb-0">Don't have account? <a routerLink="/pages-register">Create an account</a></p>
                            </div>
                         </form>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
    </div>
 </main>