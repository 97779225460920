import { JsonPipe } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatLegacyDialog as MatDialog, MatLegacyDialogRef as MatDialogRef, MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA } from '@angular/material/legacy-dialog';
import { ConfirmDialogComponent } from 'src/app/components/confirm-dialog/confirm-dialog.component';
import { ConfirmDialogActions, ConfirmDialogData } from 'src/app/components/confirm-dialog/confirm-dialog.model';
import { AuthserverPermisos, MerchantApiPermisos, Permisos, Rol, XiiberoPermisos,  } from 'src/app/models/rol.model';
import { ENTORNO, FormCreateActions } from 'src/app/util/constants';
import { RolsService } from '../rol.service';
import { VIWER_ENTORNO } from 'src/environments/environment';

@Component({
  selector: 'app-form-rol',
  templateUrl: './form-rol.component.html',
  styleUrls: ['./form-rol.component.css']
})
export class FormRolComponent implements OnInit {
  registerForm!: FormGroup;
  //desactivado:boolean = false;

  private confirmDialogRef!: MatDialogRef<ConfirmDialogComponent>;


  entorno = VIWER_ENTORNO;
  soloLecura: boolean = false;
  isEntornoXiibero: boolean = false;
  isEntornoGetnet: boolean = false;


  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
    private formBuilder: FormBuilder,public services:RolsService,
    public dialogRef: MatDialogRef<FormRolComponent>,
    public dialog: MatDialog) { 

      if(this.entorno==ENTORNO.XIIBERO){
        this.isEntornoXiibero= true;
      }else{
        this.isEntornoGetnet = true;
      }


  }

  ngOnInit(): void {

    let val_merchant_R,val_merchant_W,val_merchant_D=false
    let val_org_R,val_org_W,val_org_D=false
    let val_term_R,val_term_W,val_term_D=false
    let val_user_R,val_user_W,val_user_D=false
    let val_rol_R,val_rol_W,val_rol_D=false
    let val_group_R,val_group_W,val_group_D=false
    let val_tran_R,val_tran_W,val_tran_D=false
    let val_alert_R,val_alert_W,val_alert_D=false
    let name, description, id

    if(this.data && this.data.obj){
      //si entra es EDICION o VISTA
      if(!this.data.edit){
          this.soloLecura=true
      }
      
      //this.desactivado=true;
      
      let rol:Rol
      rol = this.data.obj;

      name= rol.name
      description= rol.description
      id=rol.id

      if(rol.permissions){
        let permisos: Permisos;
        permisos = rol.permissions;
        if(permisos.authserver){
            if(permisos.authserver.user){
              val_user_R=permisos.authserver.user.includes("R")
              val_user_W=permisos.authserver.user.includes("W")
              val_user_D=permisos.authserver.user.includes("D")
            } 
            if(permisos.authserver.role){
              val_rol_R=permisos.authserver.role.includes("R")
              val_rol_W=permisos.authserver.role.includes("W")
              val_rol_D=permisos.authserver.role.includes("D")
            }
            if(permisos.authserver.group){
              val_group_R=permisos.authserver.group.includes("R")
              val_group_W=permisos.authserver.group.includes("W")
              val_group_D=permisos.authserver.group.includes("D")
            }
        }

        if(this.entorno==ENTORNO.GETNET && permisos.merchantsApi){
            if(permisos.merchantsApi.merchant){
              val_merchant_R=permisos.merchantsApi.merchant.includes("R")
              val_merchant_W=permisos.merchantsApi.merchant.includes("W")
              val_merchant_D=permisos.merchantsApi.merchant.includes("D")
            }
            if(permisos.merchantsApi.organization){
              val_org_R=permisos.merchantsApi.organization.includes("R")
              val_org_W=permisos.merchantsApi.organization.includes("W")
              val_org_D=permisos.merchantsApi.organization.includes("D")
            }
            if(permisos.merchantsApi.terminal){
              val_term_R=permisos.merchantsApi.terminal.includes("R")
              val_term_W=permisos.merchantsApi.terminal.includes("W")
              val_term_D=permisos.merchantsApi.terminal.includes("D")
            }
            if(permisos.merchantsApi.transaction){
              val_tran_R=permisos.merchantsApi.transaction.includes("R")
              val_tran_W=permisos.merchantsApi.transaction.includes("W")
              val_tran_D=permisos.merchantsApi.transaction.includes("D")
            }
            if(permisos.merchantsApi.notification){
              val_alert_R=permisos.merchantsApi.notification.includes("R")
              val_alert_W=permisos.merchantsApi.notification.includes("W")
              val_alert_D=permisos.merchantsApi.notification.includes("D")
            }
        }
        
        if(this.entorno==ENTORNO.XIIBERO){
          if(permisos.adminXiibero){
              if(permisos.adminXiibero.merchant){
                val_merchant_R=permisos.adminXiibero.merchant.includes("R")
                val_merchant_W=permisos.adminXiibero.merchant.includes("W")
                val_merchant_D=permisos.adminXiibero.merchant.includes("D")
              }
              if(permisos.adminXiibero.organization){
                val_org_R=permisos.adminXiibero.organization.includes("R")
                val_org_W=permisos.adminXiibero.organization.includes("W")
                val_org_D=permisos.adminXiibero.organization.includes("D")
              }
              if(permisos.adminXiibero.terminal){
                val_term_R=permisos.adminXiibero.terminal.includes("R")
                val_term_W=permisos.adminXiibero.terminal.includes("W")
                val_term_D=permisos.adminXiibero.terminal.includes("D")
              }
          }
          if(permisos.merchantsApi){
              if(permisos.merchantsApi.transaction){
                val_tran_R=permisos.merchantsApi.transaction.includes("R")
                val_tran_W=permisos.merchantsApi.transaction.includes("W")
                val_tran_D=permisos.merchantsApi.transaction.includes("D")
              }
              if(permisos.merchantsApi.notification){
                val_alert_R=permisos.merchantsApi.notification.includes("R")
                val_alert_W=permisos.merchantsApi.notification.includes("W")
                val_alert_D=permisos.merchantsApi.notification.includes("D")
              }
          }
      }
       
      }
  
    }

    this.registerForm = this.formBuilder.group({
          id:[id],
          name: [name, Validators.compose([Validators.required, 
            Validators.minLength(4),
            Validators.maxLength(50),
            Validators.pattern("^(?=[a-zA-Z0-9._-]{4,50}$)(?!.*[_.-]{2})[^_.-].*[^_.-]$")
          ])],
          description: [description, Validators.compose([ Validators.maxLength(255)])],
          rol_R: [val_rol_R], rol_W: [val_rol_W], rol_D: [val_rol_D],
          merchant_R: [val_merchant_R], merchant_W: [val_merchant_W], merchant_D: [val_merchant_D],
          org_R: [val_org_R], org_W: [val_org_W], org_D: [val_org_D],
          term_R: [val_term_R], term_W: [val_term_W], term_D: [val_term_D],
          user_R: [val_user_R], user_W: [val_user_W], user_D: [val_user_D],
          group_R: [val_group_R], group_W: [val_group_W], group_D: [val_group_D],
          alert_R: [val_alert_R], alert_W: [val_alert_W], alert_D: [val_alert_D],
          tran_R: [val_tran_R], tran_W: [val_tran_W], tran_D: [val_tran_D],
    });
  }

  private applyFormats(formGroup: FormGroup): any {
    const values = formGroup.value;
    const body:any = {};
    // tslint:disable-next-line: forin
    for (const key in values) {
      console.debug(`Form filter field --- ${key}:${values[key]}`);
      const value = values[key];
      if (value !== 'undefined' && value != null && value !== '') {
        body[key] = value;
      }
    }
    return body;
  }


  onFormSubmit(): void {

    console.log(this.registerForm);

    if (this.registerForm.invalid) {
      return;
    }


    const data = this.applyFormats(this.registerForm);
    console.log(data);
    let rolTemp : Rol = new Rol();
    let permisosTemp : Permisos = new Permisos();
    let permisosmerchantsApiPermisosTemp : MerchantApiPermisos = new MerchantApiPermisos();
    let permisosAuthserverPermisosTemp : AuthserverPermisos = new AuthserverPermisos();
    let permisosAdminXiiberoPermisosTemp: XiiberoPermisos = new XiiberoPermisos();

   
    let permisosRol="", permisosMerchant="",permisosOrg="",permisosTerm="",permisosUser="",permisosGroup="",permisosAlert="",permisosTran=""


    if(data['rol_R']){permisosRol=permisosRol.concat("R")}
    if(data['rol_W']){permisosRol=permisosRol.concat("W")}
    if(data['rol_D']){permisosRol=permisosRol.concat("D")}

    if(data['merchant_R']){permisosMerchant=permisosMerchant.concat("R")}
    if(data['merchant_W']){permisosMerchant=permisosMerchant.concat("W")}
    if(data['merchant_D']){permisosMerchant=permisosMerchant.concat("D")}

    if(data['term_R']){permisosTerm=permisosTerm.concat("R")}
    if(data['term_W']){permisosTerm=permisosTerm.concat("W")}
    if(data['term_D']){permisosTerm=permisosTerm.concat("D")}

    if(data['org_R']){permisosOrg=permisosOrg.concat("R")}
    if(data['org_W']){permisosOrg=permisosOrg.concat("W")}
    if(data['org_D']){permisosOrg=permisosOrg.concat("D")}

    if(data['user_R']){permisosUser=permisosUser.concat("R")}
    if(data['user_W']){permisosUser=permisosUser.concat("W")}
    if(data['user_D']){permisosUser=permisosUser.concat("D")}

    if(data['group_R']){permisosGroup=permisosGroup.concat("R")}
    if(data['group_W']){permisosGroup=permisosGroup.concat("W")}
    if(data['group_D']){permisosGroup=permisosGroup.concat("D")}
    
    if(data['alert_R']){permisosAlert=permisosAlert.concat("R")}
    if(data['alert_W']){permisosAlert=permisosAlert.concat("W")}
    if(data['alert_D']){permisosAlert=permisosAlert.concat("D")}

    if(data['tran_R']){permisosTran=permisosTran.concat("R")}
    if(data['tran_W']){permisosTran=permisosTran.concat("W")}
    if(data['tran_D']){permisosTran=permisosTran.concat("D")}

    
//permisos Auth
    permisosAuthserverPermisosTemp.role = permisosRol
    permisosAuthserverPermisosTemp.user = permisosUser
    permisosAuthserverPermisosTemp.group = permisosGroup
//permisos Merchant 
  if(this.entorno==ENTORNO.GETNET){
      permisosmerchantsApiPermisosTemp.merchant = permisosMerchant
      permisosmerchantsApiPermisosTemp.organization = permisosOrg  
      permisosmerchantsApiPermisosTemp.terminal = permisosTerm  
  }
    permisosmerchantsApiPermisosTemp.transaction = permisosTran  
    permisosmerchantsApiPermisosTemp.notification = permisosAlert  
//Permisos Xiibero
    if(this.entorno==ENTORNO.XIIBERO){
        permisosAdminXiiberoPermisosTemp.merchant = permisosMerchant
        permisosAdminXiiberoPermisosTemp.organization = permisosOrg  
        permisosAdminXiiberoPermisosTemp.terminal = permisosTerm
    }
//////////////////////////


    permisosTemp.authserver=permisosAuthserverPermisosTemp
    permisosTemp.adminXiibero=permisosAdminXiiberoPermisosTemp
    permisosTemp.merchantsApi=permisosmerchantsApiPermisosTemp
    

    rolTemp.name=data['name']
    rolTemp.description=data['description']
    rolTemp.permissions=permisosTemp
    rolTemp.id=data['id']



    const dialogData = new ConfirmDialogData();
    dialogData.titleI18n = $localize `Create Role`;
    dialogData.textI18n = $localize `Are you sure you want to create it?`;

    this.confirmDialogRef = this.dialog.open(ConfirmDialogComponent, {
      width: '25%', panelClass: 'custom-modalbox',
      data: dialogData
    });

    this.confirmDialogRef.afterClosed().subscribe((result: ConfirmDialogActions) => {
      if (result === ConfirmDialogActions.CONFIRM) {
        
          console.log('Form SAVE action->'+JSON.stringify(rolTemp));
          this.services.saveEditService(rolTemp)
          this.dialogRef.close(FormCreateActions.SAVED);
      } else if (result === ConfirmDialogActions.CANCEL) {
        
      }
    });

  }


  formExit(): void {
    console.log('Form exit action');
    this.dialogRef.close(FormCreateActions.EXIT);
  }

}
