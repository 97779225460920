<main id="main" class="main">

    <div class="pagetitle">
      <h1 i18n>{{"Transactions" | translate }}</h1>  

      <div class="botonesCabecera">
        <div class="selectButtom_navigation">
          <div class="selectButtom">
            <button id="icoVerTabla" class="botonIcoArriba botonMarcado" (click)="verTabla()" *ngIf="!isJustTransactions">
              <i class="bi bi-list-ul"></i>
              <span i18n>{{"List" | translate }}</span>
            </button>
            <button id="icoVerGrafica" class="botonIcoArriba" (click)="verGrafica()" *ngIf="!isJustTransactions" >
              <i class="bi bi-bar-chart-line"></i>
              <span i18n>{{"Graph" | translate }}</span>
            </button>
          </div>
        </div>
        <div class="dropdown">
          <button  mat-mini-fab *ngIf="isEntornoXiibero" class="button-bar" id="configurationMenu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false" data-bs-toggle="dropdown" data-bs-auto-close="outside">
            <i class="bi bi-gear"></i>
          </button>     
          <div class="dropdown-menu">
            <form class="px-4 py-3">
              <mat-radio-group
                aria-labelledby="tz-radio-group-label"
                class="tz-radio-group">              
                <mat-radio-button class="tz-radio-button" *ngFor="let timezone of timezones" [value]="timezone.value" [checked]="timezone.checked" (change)="timeZoneChange($event)" id="{{timezone.name}}">
                  {{timezone.name}}
                </mat-radio-button>                
              </mat-radio-group>
              <select #selectZones class="form-control" *ngIf="customTimezoneEnabled" (change)="customTimeZoneChange(selectZones.value)">
                <option *ngFor="let zone of allTimeZones" [value]="zone" [selected]="zone===calculateZoneOffset(false)">{{ zone }}</option>
              </select>
            </form>            
          </div>
        </div>
        <button  mat-mini-fab (click)="refrescar()" class="button-bar" *ngIf="!isJustTransactions">
          <i class="bi bi-arrow-clockwise"></i>
        </button>    
      </div>
   </div>
  
  



   <mat-accordion>
    <mat-expansion-panel #matexpansionpanel>
      <mat-expansion-panel-header>
        <mat-panel-title>
          <i class="bi bi-search"></i>
        </mat-panel-title>
        <mat-panel-description>
        </mat-panel-description>
      </mat-expansion-panel-header>
     
      <div class="filterCustos">    

      
          <div class="filterCard" *ngIf="isEntornoGetnet || isEntornoXiibero">
              <mat-form-field ngDefaultControl>
                  <mat-label i18n>{{"Serial Number" | translate }}</mat-label>
                  <input matInput class="form-field" [formControl]="serialFilter" placeholder="">
              </mat-form-field>
          </div>
          <div class="filterCard" *ngIf="isEntornoGetnet || isEntornoXiibero">
            <mat-form-field ngDefaultControl>
                <mat-label i18n>{{"Model" | translate }}</mat-label>
                <input matInput class="form-field" [formControl]="modelFilter" placeholder="">
            </mat-form-field>
          </div>
        
      </div> 
    
    </mat-expansion-panel>
  </mat-accordion>
  

    
<div *ngIf="!isJustTransactions && graficaTransaction">
     <div class="row mt-2">
      <div class="col-lg-4">
        <div class="card">
           <div class="card-body">
              <h5 class="card-title">{{"Type of transactions" | translate }}</h5>
              <app-chart-tx-tipo  #chart1 chartTitle="" [chartDataset]="graph1Data"></app-chart-tx-tipo>
           </div>
        </div>
     </div>
     <div class="col-lg-4">
        <div class="card">
           <div class="card-body">
              <h5 class="card-title">{{"Card brands" | translate }}</h5>
              <app-chart-tx-brand #chart2 chartTitle="" [chartDataset]="graph2Data"></app-chart-tx-brand>
           </div>
        </div>
     </div>
     <div class="col-lg-4">
        <div class="card">
           <div class="card-body">
              <h5 class="card-title">{{"Card technologies" | translate }}</h5>
              <app-chart-tx-tech #chart3 chartTitle="" [chartDataset]="graph3Data"></app-chart-tx-tech>
           </div>
        </div>
     </div>
     <div class="col-lg-4">
        <div class="card">
           <div class="card-body">
              <h5 class="card-title">{{"Transaction by currency" | translate }}</h5>
              <app-chart-tx-currency  chartTitle="" [chartDataset]="graph4Data"></app-chart-tx-currency>
           </div>
        </div>
     </div>
     <div class="col-lg-4">
        <div class="card">
           <div class="card-body">
              <h5 class="card-title">{{"Results of transactions" | translate }}</h5>
              <app-chart-tx-status  chartTitle="" [chartDataset]="graph5Data"></app-chart-tx-status>
           </div>
        </div>
     </div>
  </div>
  
</div>



<div *ngIf="tablaTransaction" style="overflow: auto; margin-top: 15px;">
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" multiTemplateDataRows  matSort (matSortChange)="announceSortChange($event)"
        matTableExporter  #exporter="matTableExporter">

  
        <!-- merchant_name Column -->
        <ng-container matColumnDef="merchant_name" >
          <th mat-header-cell *matHeaderCellDef class="tamano_columna_max"><div mat-sort-header i18n>{{"Merchant" | translate }}</div>  
            <div>
              <mat-form-field>
                      <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                      <input matInput class="form-field" [formControl]="merchantFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"  class="tamano_columna_max" title="{{element.merchant?.name}}"> {{element.merchant?.name}} </td>
        </ng-container>
    <!-- merchantId Column -->
    <ng-container matColumnDef="merchant_merchantId">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Merchant Id" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <input matInput class="form-field" [formControl]="merchantIdFilter" placeholder="" maxlength="30">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.merchant?.merchantId}} </td>
    </ng-container>
     <!-- merchantId Column -->
     <ng-container matColumnDef="terminal_terminalId">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Terminal Id" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <input matInput class="form-field" [formControl]="terminalIdFilter" placeholder=""  maxlength="30">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.terminal?.terminalId}} </td>
    </ng-container>
  
        <!-- amount Column -->
        <ng-container matColumnDef="trx_amount">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Amount" | translate }}</div>  
            <div>
                   
                <app-ngx-numeric-range-form-field
                        [formControl]="amountFilter"
                        label=""
                        [resettable]="false"
                        appearance='standard'
                        (blurred)="onAmountFilterBlur()"
                        (numericRangeChanged)="onAmountFilterChange($event)"
                ></app-ngx-numeric-range-form-field>

            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.trx?.amount  | currency : element.trx?.currency }} </td>
        </ng-container>
  
    
          <!-- currency Column -->
          <ng-container matColumnDef="trx_currency">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Currency" | translate }}</div>  
              <div>
                <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                        <input matInput class="form-field" [formControl]="currencyFilter" placeholder="" maxlength="3">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.trx?.currency}} </td>
          </ng-container>



   <!-- localDate Column -->        
   <ng-container matColumnDef="date" >
    <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Date" | translate }}</div> 
      <div>
        <mat-form-field> 
          <mat-label></mat-label>
          <mat-date-range-input [formGroup]="localDateFilter" [rangePicker]="picker">
            <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
            <input matEndDate formControlName="end" placeholder="{{'End Date' | translate }}">
          </mat-date-range-input>
          <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
          <mat-date-range-picker #picker></mat-date-range-picker>
        </mat-form-field>
      </div>
    </th>
    <td mat-cell *matCellDef="let element"> {{element.date | date:'dd/MM/yyyy, HH:mm:ss' : timeZoneOffset}}</td>
  </ng-container>



        <!-- pan Column -->
        <ng-container matColumnDef="card_cardInfoObfuscated">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"PAN" | translate }}</div>  
              <div>
                <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="panFilter" placeholder="">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.card?.cardInfoObfuscated}} </td>
        </ng-container>

        <!-- transactionId Column -->
        <ng-container matColumnDef="trx_transactionId">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"TransactionId" | translate }}</div>  
              <div>
                <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="transactionIdFilter" placeholder="">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.trx?.transactionId}} </td>
        </ng-container>


        <!-- transactionId Column -->
        <ng-container matColumnDef="trx_ref">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Reference" | translate }}</div>  
            <div>
              <mat-form-field>
                  <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                  <input matInput class="form-field" [formControl]="refFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.trx?.ref}} </td>
        </ng-container>

        <!-- authorizationId Column -->
        <ng-container matColumnDef="trx_authcode">
            <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Authcode" | translate }}</div>  
              <div>
                <mat-form-field>
                    <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                    <input matInput class="form-field" [formControl]="authorizationIdFilter" placeholder="">
                </mat-form-field>
              </div>
            </th>       
            <td mat-cell *matCellDef="let element"> {{element.trx?.authcode}} </td>
        </ng-container>
  
        <!-- transactionType Column -->
        <ng-container matColumnDef="type">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Type" | translate }}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-label></mat-label>
                <mat-select  (selectionChange)="transactionTypeSelectFilter($event)">
                  <mat-option *ngFor="let op of transactionTypeSelect" [value]="op">
                    {{utilService.translate(op)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{utilService.translate(element.type)}} </td>
        </ng-container>


        <!-- cardBrand Column -->
        <ng-container matColumnDef="card_cardType">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header  i18n>{{"Card brands" | translate }}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-label></mat-label>
                <mat-select  (selectionChange)="cardBrandSelectFilter($event)">
                  <mat-option *ngFor="let op of cardBrandSelect" [value]="op">
                    {{utilService.translate(op)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{utilService.translate(element.card?.cardType)}} </td>
        </ng-container>

        
    <!-- tecnologia Column -->
    <ng-container matColumnDef="card_entry">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Entry" | translate }}</div>  
        <div>
          <mat-form-field>
            <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
            <mat-label></mat-label>
            <mat-select  (selectionChange)="resultEntrySelectFilter($event)">
              <mat-option *ngFor="let op of resultEntrySelect" [value]="op">
                {{utilService.translate(op)}}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"  > {{utilService.translate(element.card?.entry)}} </td>
    </ng-container>



        <!-- resultCode Column -->
        <ng-container matColumnDef="status">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Status" | translate }}</div>  
            <div>
              <mat-form-field>
                <mat-icon matPrefix class="my-icon"><i class="bi bi-filter"></i> </mat-icon>
                <mat-label></mat-label>
                <mat-select  (selectionChange)="resultCodeSelectFilter($event)">
                  <mat-option *ngFor="let op of resultCodeSelect" [value]="op">
                    {{utilService.translate(op)}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"  class="{{element.status}}" > {{utilService.translate(element.status)}} </td>
        </ng-container>


        <ng-container matColumnDef="button">
          <th mat-header-cell *matHeaderCellDef></th>
          <td mat-cell *matCellDef="let element" class="button">


            <!--BOTONES XIBERO-->
            <div *ngIf="isEntornoXiibero && (haveTransactions(element) || haveTicket(element) || canBeRefunded(element) || canBeConfirmed(element))">
                        
              <button mat-button [matMenuTriggerFor]="menu"><i class="bi bi-three-dots"></i></button>
              <mat-menu #menu="matMenu">
                <button  mat-menu-item *ngIf="haveTransactions(element)" matTooltip="{{'Transactions' | translate }}" (click)="viewTransaction(element)"  >
                  <i class="bi bi-credit-card"></i>
                  {{"Transactions" | translate }}
                </button>
                <button  mat-menu-item *ngIf="haveTicket(element)"   matTooltip="{{'Ticket' | translate }}" (click)="viewTicket(element)">
                  <i class="bi bi-file-earmark-pdf"></i>
                  {{"Ticket" | translate }}
                </button>
                <button  mat-menu-item  *ngIf="canBeRefunded(element)"  matTooltip="{{'Refund' | translate }}" (click)="viewReferenced(element, 'REFUND')">
                  <i class="bi bi-bootstrap-reboot"></i>
                  {{"Refund" | translate }}
                </button>
                <button  mat-menu-item  *ngIf="canBeConfirmed(element)"  matTooltip="{{'Confirm' | translate }}" (click)="viewReferenced(element, 'CONFIRM')">
                  <i class="bi bi-check-circle"></i>
                  {{"Confirm" | translate }}
                </button>
              </mat-menu>
            </div>
             <!--BOTONES GETNET-->
            <div *ngIf="isEntornoGetnet">
                <button  mat-icon-button *ngIf="!isJustTransactions && element.trx?.splitPaymentId" matTooltip="{{'Transactions' | translate }}" (click)="viewTransaction(element)"  >
                  <i class="bi bi-credit-card"></i>
                </button>
                <button  mat-icon-button *ngIf="element.receiptInfo?.hasReceipt"   matTooltip="{{'Ticket' | translate }}" (click)="viewTicket(element)">
                  <i class="bi bi-file-earmark-pdf"></i>
                </button>               
            </div>

          </td>
        </ng-container>
      

      <!--  Column Expand -->
      <ng-container matColumnDef="expand" style="padding: 0 !important;">
        <th mat-header-cell *matHeaderCellDef >&nbsp;</th>
        <td mat-cell *matCellDef="let element" style="padding: 0 !important;">
          <button mat-icon-button matTooltip="{{'View' | translate }}" (click)="(expandedElement = expandedElement === element ? null : element); $event.stopPropagation()">
            <mat-icon  *ngIf="expandedElement !== element">keyboard_arrow_down</mat-icon>
            <mat-icon  *ngIf="expandedElement === element">keyboard_arrow_up</mat-icon>
          </button>
        </td>
      </ng-container>
      <!-- Expanded Content Column - The detail row is made up of this one column that spans across all columns -->
      <ng-container matColumnDef="expandedDetail">
        <td mat-cell *matCellDef="let element" [attr.colspan]="displayedColumns.length">
          <div class="example-element-detail"  [@detailExpand]="element === expandedElement ? 'expanded' : 'collapsed'">
              <div class="element-header">
                  <span i18n>{{"Additional Information" | translate }}</span>
              </div>
              <div class="element-body">
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.terminal?.serialnumber}}" placeholder="{{'serialnumber' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.terminal?.model}}" placeholder="{{'Model' | translate }}"></mat-form-field>

                  <!--<mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{utilService.translate(element.card?.entry)}}" placeholder="{{'entry' | translate }}"></mat-form-field>-->
                  <mat-form-field *ngIf="isEntornoGetnet                    "><input matInput class="form-field" disabled value="{{element.card?.aid}}" placeholder="{{'aid' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{utilService.translate(element.card?.cardType)}}" placeholder="{{'cardType' | translate }}"></mat-form-field>

                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{utilService.translate(element.cardholder?.authmode)}}" placeholder="{{'authmode' | translate }}"></mat-form-field>

                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.tips | currency : element.trx?.currency}}" placeholder="{{'tips' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="                   isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.country}}" placeholder="{{'Country' | translate }}"></mat-form-field>

                  <!--<mat-form-field><input matInput class="form-field" disabled value="{{element.trx?.ref}}" placeholder="ref"></mat-form-field>-->

                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.splitPaymentId}}" placeholder="{{'splitPaymentId' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.sessionId}}" placeholder="{{'sessionId' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.arc}}" placeholder="{{'arc' | translate }}"></mat-form-field>

                  <mat-form-field *ngIf="                   isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.referenceData?.transactionId}}" placeholder="{{'Reference' | translate }}"></mat-form-field>
                  
                  <!--<mat-form-field><input matInput class="form-field" disabled value="{{element.trx?.dcc?.amount}}" placeholder="dcc amount"></mat-form-field>
                  <mat-form-field><input matInput class="form-field" disabled value="{{element.trx?.dcc?.currency}}" placeholder="dcc currency"></mat-form-field>
                  <mat-form-field><input matInput class="form-field" disabled value="{{element.trx?.dcc?.markUp}}" placeholder="dcc markUp"></mat-form-field>
                  <mat-form-field><input matInput class="form-field" disabled value="{{element.trx?.dcc?.marginECB}}" placeholder="dcc marginECB"></mat-form-field>-->
                  
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.processor?.shortName}}" placeholder="{{'Processor' | translate }}"></mat-form-field>
                  <mat-form-field *ngIf="isEntornoGetnet || isEntornoXiibero"><input matInput class="form-field" disabled value="{{element.trx?.processor?.mid}}" placeholder="{{'MID' | translate }}"></mat-form-field>
                  <mat-checkbox   *ngIf="isEntornoGetnet                    " [ngModel]="element.receiptInfo?.requireSendReceipt" [disabled]="true">{{"Digital receipt" | translate }}</mat-checkbox>
              </div>
          </div>
        </td>
      </ng-container>


      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>     
      <tr mat-row *matRowDef="let row; columns: ['expandedDetail']" class="example-detail-row"></tr>


      </table>

      <button id="export" class="icoDownload" mat-icon-button (click)="exportIt() "><i class="bi bi-file-earmark-arrow-down"></i> </button>
       
          <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
          </mat-paginator>
    </div>
</div>

      <div class="row botonera"   *ngIf="verBotones"> 
        <div class="col-lg-12">
                <button  class="btn btn-cancelar" (click)="formExit()" i18n>{{"Cancel" | translate }}</button>
        </div>
      </div>
    </main>

