import { Component, OnInit, ElementRef, Input, ViewChild, AfterViewInit, OnChanges } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Chart } from 'chart.js';
import { CHART_COLORS } from '../../util/chartsutils';

import { DEFAULT_LOCALE, LOCALES } from '../../util/chartsLocales';
 
import {
  ApexAxisChartSeries,
  ApexChart,
  ChartComponent,
  ApexDataLabels,
  ApexXAxis,
  ApexPlotOptions,
  ApexStroke,
  ApexTitleSubtitle,
  ApexYAxis,
  ApexTooltip,
  ApexFill,
  ApexLegend
} from "ng-apexcharts";

export type ChartOptions = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-chart-tx-daily',
  templateUrl: './chart-tx-number.component.html',
  styleUrls: ['./chart-tx-number.component.css']
})
export class ChartTxDailyNumberComponent implements OnInit, OnChanges, AfterViewInit  {
  @Input() chartTitle !: string;
  @Input() dates!: Date[];
  @Input() values!: Map<string, number[]>;
  @Input() values2!: Map<string, number[]>;
  
  public chartOptions!: Partial<ChartOptions>;

  constructor(private translate: TranslateService) { }

  ngAfterViewInit(): void {
  }

  @ViewChild('chartDN', { static: true }) chart!: ChartComponent;

  ngOnInit(): void {
    this.createChart();
  }

  ngOnChanges() {
    
    if (!(this.chart == undefined) && !(this.chartOptions == undefined) && !(this.dates == undefined)) {
      this.chartOptions.series!.length = 0;
      if (this.dates.length > 0) {
        for (let entry of this.values.entries()) {
          var v : number[][] = new Array<Array<number>>();
          for (let i=0; i<this.dates.length; i++) {
            var item : number[] = new Array<number>();
            item.push(new Date(this.dates[i]).getTime());
            item.push(entry[1][i]);
            v.push(item);
          }
          this.chartOptions.series!.push({ name : entry[0], data : v});
        }
        if ((this.values2 != undefined) && (this.values2!.size > 0)) {
          for (let entry of this.values2.entries()) {
            var v : number[][] = new Array<Array<number>>();
            for (let i=0; i<this.dates.length; i++) {
              var item : number[] = new Array<number>();
              item.push(new Date(this.dates[i]).getTime());
              item.push(entry[1][i]);
              v.push(item);
            }
            this.chartOptions.series!.push({ name : entry[0], data : v});
          }
        }
        this.chart.updateSeries(this.chartOptions.series!);        
        this.chart.setLocale(this.translate.currentLang);
      }
    }
  }

  data = [
    [0,0]
  ];
  
  createChart(){
    this.chartOptions = {      
      series: [
        {
          data: this.data
        }
      ],
      chart: {
        defaultLocale : DEFAULT_LOCALE,
        locales: LOCALES,
        type: "bar",
        //height: 350,
        stacked: true
      },
      plotOptions: {
        bar: {
          horizontal: false
        }
      },
      stroke: {
        width: 1,
        colors: ["#fff"]
      },
      title: {
        text: this.chartTitle
      },
      xaxis: {
        type: "datetime",
      },
      yaxis: {
        title: {
          text: undefined
        }
      },
      tooltip: {
        x: {
          format: "dd MMM yyyy"
        }
      },
      fill: {
        opacity: 1
      },
      legend: {
        position: "bottom",
        horizontalAlign: "center",
        offsetX: 0
      }
    };

  }
}
