import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {  Subject } from 'rxjs';
import { ChartPayload } from 'src/app/shared/interfaces/chart.model';
import { ReferencedTransactionReq } from 'src/app/models/referencedTransactionReq.model';
import { RequestFilter } from 'src/app/models/requestFilter.model';
import { Ticket } from 'src/app/models/ticket.model';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { MerchantRestUrl } from 'src/app/util/constants';


@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  constructor(private merchantRest : RestService) {}
  


  async getTiket(_body: Ticket){


    let value = await this.merchantRest.commonRestCallMerchantBlob(_body,'receipt',true,true)   
    .catch(err => {
      return null
    })


    return value;   
  }


  async sendService(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallMerchant(_body,'transactions/query',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async sendAggrService(_body: RequestFilter, data : ChartPayload) :  Promise<ChartPayload> {
    var retValue = new Subject<any>();

      this.merchantRest.commonRestCallMerchant(_body, 'transactions/aggregation',false, false).then(
        value => {
          var keys : Array<string> = new Array<string>();
          var values : Array<number> = new Array<number>();
          value!.buckets.forEach((element: { key: string; number: number; }) => {
            keys.push(element.key);
               values.push(element.number);
          });
          var test = data;
          test.labels = keys;
          test.values = values;
          retValue.next(test);
          retValue.complete();
      })
      .catch(
        err => { console.log(err); 
        }
      );              
    return retValue.toPromise();
  }

  async sendReferenced(_body: ReferencedTransactionReq) {
    let value = await this.merchantRest.commonRestCallXiibero(_body,'transactions/referenced',true,true)   
    .catch(err => {
      return null;
    })
    return value;   
  }

}