import { Component, OnInit } from '@angular/core';

import { faGears } from '@fortawesome/free-solid-svg-icons';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/auth/auth.service';
import { Organization } from 'src/app/models/organization.model';
import { CommonService } from 'src/app/services/common.service';
import { UtilService } from 'src/app/services/util.service';
import { ENTORNO, TypeJerarquia } from 'src/app/util/constants';
import { VIWER_ENTORNO } from 'src/environments/environment';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent implements OnInit {

  entorno = VIWER_ENTORNO;
  isEntornoXiibero: boolean = false;
  isJerarquiaRegion: boolean = false;
  isJerarquiaDivision: boolean = false;
  isJerarquiaSubsidiary: boolean = false;


  
  jerarquiaSearchSbscription!: Subscription;

  constructor(
    private authServer: AuthService,
    public utilServer: UtilService,
    public commonService : CommonService
  ) { 

    if(this.entorno==ENTORNO.XIIBERO){
      this.isEntornoXiibero= true;     
      this.isJerarquiaRegion = true;
      this.isJerarquiaDivision = true;
      this.isJerarquiaSubsidiary = true;
    }
  }


  async ngOnInit(): Promise<void>  {
    this.jerarquiaSearchSbscription = this.authServer.getJerarquiaSearchClicked().subscribe(() => this.cargarCache());    
    //await this.cargarCache();
  }


   
  async cargarCache() {     
    let precargaOrg = await this.utilServer.cargarCacheOrganization();
    if(precargaOrg){     
      let obj: Organization     
        obj = JSON.parse(precargaOrg) 
        if( obj && obj.template){  
            this.isJerarquiaRegion =  this.commonService.checkJerarquia(obj.template, TypeJerarquia.REGION);
            this.isJerarquiaDivision  = this.commonService.checkJerarquia(obj.template, TypeJerarquia.DIVISION);
            this.isJerarquiaSubsidiary  =  this.commonService.checkJerarquia(obj.template, TypeJerarquia.SUBSIDIARY);
        }
    }
  }

  marcarMenu(id_Li:any){

    const testElements = document.getElementsByClassName('toc');
    const testDivs = Array.prototype.filter.call(testElements,
      (testElement) => testElement.checked = false,
    );

    var slectToc = document.getElementById(id_Li) as HTMLInputElement;    
    if(slectToc){
      slectToc.checked = true;
    }   
  }

  public checkMenuAccessShown(): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        var tk = this.authServer.getUserAccessToken();
        var permissions = tk!.auth!.permissions!['merchantsApi'];
        var permission : string = permissions['merchant'];
        permission += permissions['notification'];
        permission += permissions['terminal'];
        permission += permissions['transaction'];
        permission += permissions['organization'];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }


  public checkMenuAccess(item:string): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        var tk = this.authServer.getUserAccessToken();
        var permissions = tk!.auth!.permissions!['merchantsApi'];
        var permission : string = permissions[item];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkUserAccessShown(): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        var tk = this.authServer.getUserAccessToken();
        var permissions = tk!.auth!.permissions!.authserver;
        var permission : string = permissions['user'];
        permission += permissions['role'];
        permission += permissions['group'];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

  public checkUserAccess(item:string): boolean  {
    if (this.authServer.hasValidAccessToken() && this.authServer.hasValidIdToken()) {
      try {
        var tk = this.authServer.getUserAccessToken();
        var permissions = tk!.auth!.permissions!.authserver;
        var permission : string = permissions[item];
        return ((permission != undefined) && (permission.length > 0));
      } catch(err) {
        return false;
      }
    } else {
      return false;
    }
  }

}
