import { Inject, Injectable } from '@angular/core';
import { IndividualConfig, ToastrService } from 'ngx-toastr';
import { AUTH_URL } from 'src/app/auth/app.oauth-tokens';
import { Filter, QueryFilterList, RequestFilter } from 'src/app/models/requestFilter.model';
import { Rol } from 'src/app/models/rol.model';
import { Users } from 'src/app/models/users.model';
import { CommonService } from 'src/app/services/common.service';
import { RestService } from 'src/app/services/merchant/Rest.service';
import { AuthRestUrl } from 'src/app/util/constants';


@Injectable({
  providedIn: 'root',
})
export class UsersService {
  
  constructor(
    private merchantRest : RestService,
    public commonService: CommonService) {

    }

  async findService(_body: RequestFilter){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'user/',true,true)
    .catch(err => {      
      return null
    })
    return value;   
  }

  async invitationService(_body: Users){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'invitation',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }

  async deleteService(_id: string){
    let _body={"id":_id} 
    let value = await this.merchantRest.commonRestCallAuth(_body, 'user/delete',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async resetPasswordService(username: string){
    let _body={"username":username} 
    let value = await this.merchantRest.commonRestCallAuth(_body, 'password/recover/request',true,true)   
    .catch(err => {
      return null
    })
    return value;   
  }

  async reActivateQuarantinedService(_body: Users){
    let value = await this.merchantRest.commonRestCallAuth(_body, 'user/changeQuarantineStatus',true,true)   
    .catch(err => {
      return null
    })

    return value;   
  }
}