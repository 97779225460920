<main id="main" class="main">

    <div class="pagetitle">
        <h1  i18n>{{"Groups" | translate}}</h1>  
        <div>
          <button  *ngIf="commonService.checkAuthServerItemRights('group','W')" mat-mini-fab (click)="onNew()">
           <i class="bi bi-plus-lg"></i>
          </button>
        </div>
   </div>
  
  
    
    <div class="mat-elevation-z8 tablaPersonal">
      <table mat-table [dataSource]="dataSource" matSort (matSortChange)="announceSortChange($event)">  
  
  
        <!-- name Column -->
        <ng-container matColumnDef="name">
          <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Name" | translate }}</div>  
            <div>
              <mat-form-field>
                      <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                      <input matInput class="form-field" [formControl]="nameFilter" placeholder="">
              </mat-form-field>
            </div>
          </th>       
          <td mat-cell *matCellDef="let element"> {{element.name}} </td>
        </ng-container>
  

    <!-- descripcion Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef><div mat-sort-header i18n>{{"Description" | translate }}</div>  
        <div>
          <mat-form-field>
                  <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
                  <input matInput class="form-field" [formControl]="descFilter" placeholder="">
          </mat-form-field>
        </div>
      </th>       
      <td mat-cell *matCellDef="let element"> {{element.description}} </td>
    </ng-container>


      <!-- Create Column -->        
      <ng-container matColumnDef="createdAt">
        <th mat-header-cell *matHeaderCellDef > <div mat-sort-header i18n>{{"Create Date" | translate }}</div> 
          <div>
            <mat-form-field> 
                <mat-icon matPrefix class="my-icon">filter_list </mat-icon>
              <mat-label></mat-label>
              <mat-date-range-input [formGroup]="dateCreateFilter" [rangePicker]="picker">
                <input matStartDate formControlName="start" placeholder="{{'Start Date' | translate }}">
                <input matEndDate formControlName="end" placeholder="{{'End Date' | translate}}">
              </mat-date-range-input>
              <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
              <mat-date-range-picker #picker></mat-date-range-picker>
            </mat-form-field>
          </div>
        </th>
        <td mat-cell *matCellDef="let element"> {{element.createdAt | date:'medium'}}</td>
      </ng-container>

  <ng-container matColumnDef="view">
    <th mat-header-cell *matHeaderCellDef></th>
    <td mat-cell *matCellDef="let element" class="button">   
      <div>
        <button *ngIf="commonService.checkAuthServerItemRights('group','W')" matTooltip="{{'edit' | translate}}"  (click)="onEdit(element)" mat-icon-button color="primary" >
          <i class="bi bi-pencil"></i>
        </button>

        <button *ngIf="commonService.checkAuthServerItemRights('group','D')" matTooltip="{{'delete' | translate}}" (click)="onDelete(element)" mat-icon-button color="primary">
          <i class="bi bi-trash"></i>
        </button>
</div>

    </td>
  </ng-container>



  
  
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>
    
          <mat-paginator [length]="pageLength"
              [pageSize]="pageSize"
              [pageSizeOptions]="pageSizeOptions"
              (page)="pageEvent($event)"
              showFirstLastButtons 
              aria-label="Select page of periodic elements">
          </mat-paginator>
    </div>
    </main>