import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { Observable, tap } from 'rxjs';

import { AuthService } from './auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  //private router: Router, 
  constructor(
    
    private authService: AuthService,
    private router: Router,
    ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.authService.hasValidAccessToken()
            &&  this.authService.hasValidIdToken()) {
    
      let check = route.data;
      if ((check != undefined) && (check["authorg"] != undefined) ) {
        var authorg : string = check["authorg"] as string;
        var authkey : string = check["authkey"] as string;
        var tk = this.authService.getUserAccessToken();
        var permissions = tk!.auth!.permissions;
        var permission = permissions![authorg]![authkey];
        return ((permission != undefined) && (permission.length > 0));
      }
      console.log(tk);
      return true;
    } else {
      this.router.navigate(['/welcome']);//, { login: true }
      return false;
    }
  }
  
}
