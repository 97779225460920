<main>
    <div class="container">
       <section class="section register min-vh-100 d-flex flex-column align-items-center justify-content-center py-4">
          <div class="container">
             <div class="row justify-content-center">
                <div class="col-lg-4 col-md-6 d-flex flex-column align-items-center justify-content-center">
                   <div class="d-flex justify-content-center py-4 d-none">
                     <a routerLink="/" class="logo d-flex align-items-center w-auto">
                       <img src="assets/img/logo.png" alt="" >
                       <span>Admin</span>
                     </a>
                   </div>
                   <div class="card mb-3">
                      <div class="card-body">
                         <div class="pt-4 pb-2">
                            <h5 class="card-title text-center pb-0 fs-4" i18n>Create an Account</h5>
                            <p class="text-center small" i18n>Enter the following details to create your account</p>
                         </div>
                         <form class="row g-3 needs-validation" novalidate [formGroup]="registerForm" >
                            <div class="col-12" ngDefaultControl>
                               <label for="yourEmail" class="form-label">Your Email</label> 
                               <input formControlName="yourEmail" type="email" name="email" class="form-control" id="yourEmail" readonly required>
                               <div class="invalid-feedback" i18n>Please enter a valid Email adddress!</div>
                            </div>
                            <div class="col-12" ngDefaultControl>
                               <label for="yourUsername" class="form-label" i18n>Username</label>
                               <div class="input-group has-validation">
                                  <span class="input-group-text" id="inputGroupPrepend">@</span> 
                                  <input  formControlName="yourUsername" type="text" name="username" class="form-control" id="yourUsername" readonly required>
                                  <div class="invalid-feedback" i18n>Please choose a username.</div>
                               </div>
                            </div>
                            <div class="col-12" ngDefaultControl>
                               <label for="yourName" class="form-label" i18n>First Name</label> 
                               <input formControlName="yourName" type="text" name="name" class="form-control" id="yourName" >
                               <div  class="invalid-feedback" i18n>Please, enter your name!</div>

                               <mat-error *ngIf="registerForm.get('yourName')?.hasError('pattern')" i18n>Invalid input format</mat-error>
                               <mat-error *ngIf="registerForm.get('yourName')?.hasError('maxlength')" i18n>Maximum length exceeded</mat-error>
                            </div>
                            <div class="col-12" ngDefaultControl>
                              <label for="yourLastName" class="form-label" i18n>Last Name</label> 
                              <input  formControlName="yourLastName" type="text" name="name" class="form-control" id="yourLastName" required>
                              <div class="invalid-feedback" i18n>Please, enter your last name!</div>
                              <mat-error *ngIf="registerForm.get('yourLastName')?.hasError('pattern')" i18n>Invalid input format</mat-error>
                              <mat-error *ngIf="registerForm.get('yourLastName')?.hasError('maxlength')" i18n>Maximum length exceeded</mat-error>
                           </div>
                            <div class="col-12" ngDefaultControl>
                               <label for="yourPassword" class="form-label" i18n>Password</label> 
                               <input  
                                 formControlName="yourPassword" 
                                 type="password" 
                                 name="password" 
                                 class="form-control"
                                 id="yourPassword" required>
                               <div id="yourPasswordFeedback" class="invalid-feedback" i18n>Please enter your password!</div>
                               <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('minlength')" i18n>Minimum length not reached</mat-error>
                               <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('maxlength')" i18n>Maximum length exceeded</mat-error>
                               <mat-error *ngIf="registerForm.get('yourPassword')?.hasError('pattern')" i18n>Password should have at least 8 characters : one lowercase, one uppercase , special characters and one digit.</mat-error>
                            </div>
                            <div class="col-12" ngDefaultControl>
                               <div class="form-check">
                                  <input formControlName="acceptedTermsAndConditions"  class="form-check-input" name="terms" type="checkbox" value="" id="acceptTerms" required>
                                  <label class="form-check-label" for="acceptTerms" i18n>I agree and accept the <a href="/terms-conditions" target="_blank">terms and conditions</a></label>
                                  <div class="invalid-feedback" i18n>You must agree before submitting.</div>
                               </div>
                            </div>
                            <div class="col-12"> <button class="btn btn-primary w-100" type="submit" (click)="onFormSubmit()" i18n>Create Account</button></div>                           
                         </form>
                      </div>
                   </div>
                </div>
             </div>
          </div>
       </section>
    </div>
 </main>