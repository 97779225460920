
import { Order } from "../order.model";

export class DataExport_Order_Xiibero {

  organizationName: string | undefined; 
  regionName: string | undefined; 
  divisionName: string | undefined; 
  merchantName: string | undefined;
  orderId: string | undefined;
  createdAt: string | undefined;
  status: string | undefined;
  manufacturer: string | undefined;
  model: string | undefined;
  numberOfTerminals: string | undefined;


  constructor(values: Order = new Order) {
    this.organizationName= values.hierarchy?.ORGANIZATION?.name
    this.regionName= values.hierarchy?.REGION?.name
    this.divisionName= values.hierarchy?.DIVISION?.name
    this.merchantName= values.hierarchy?.MERCHANT?.name
    this.orderId= values.orderId
    this.createdAt= values.createdAt
    this.status= values.status
    this.manufacturer= values.terminals?.manufacturer
    this.model= values.terminals?.model
    this.numberOfTerminals= values.terminals?.numberOfTerminals
  }


}


