import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pages-faq',
  templateUrl: './pages-faq.component.html',
  styleUrls: ['./pages-faq.component.css']
})
export class PagesFaqComponent implements OnInit {

  //constructor() { }

  ngOnInit(): void {
  }


  loadCSS(i:any) {
    let fileRef;
    fileRef = document.createElement('link');
    fileRef.setAttribute('rel', 'stylesheet');
    fileRef.setAttribute('type', 'text/css');


    if(i==2){
      fileRef.setAttribute('href', '../../assets/themes/xiibero/styles.css');
    }else if(i==3){
      fileRef.setAttribute('href', '../../assets/themes/getnet/styles.css');
    }

    if (typeof fileRef !== 'undefined') {
        document.getElementsByTagName('head')[0].appendChild(fileRef);
    }
  }
  

}
